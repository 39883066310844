import React from "react"

import {hasProperty, formatText} from '../utils/utils';

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';
import {SanitizeHTML} from '../utils/utils';


class ViewResource extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.data = {};
        this.data.Resource = null;

        if (this.props.Resource){
            this.data.Resource = this.props.Resource; 
        }


        this.filters = {};

        this.columns = {
        };

        this.data.id = null;
        if (this.props.id){
            this.data.id = this.props.id; 
        }


    }

    componentDidMount() {

        if (this.data.Resource){
            this.setState({"isLoaded":true});
            return;
        }

    };

    view(json){

        if (json){

            let modelResource = null;
            if ("type" in json){
                if (json.type in this.dsd.model.resources){
                    modelResource = this.dsd.model.resources[json.type];
                }
            }


            return(
                <PD.Table className="panel">
                    <PD.Tbody>                                

                        {hasProperty(json,'name') &&
                            <PD.Tr>                                
                                <PD.Th>Name</PD.Th><PD.Td><PD.PdNavLink to={"/resource/" + json.id} > {json.name}</PD.PdNavLink></PD.Td>
                            </PD.Tr>
                        }

                        {hasProperty(json,'description') &&
                            <PD.Tr>
                                <PD.Th>Description</PD.Th><PD.Td><SanitizeHTML html={json.description}/></PD.Td>
                            </PD.Tr>
                        }

                        {hasProperty(json,'proposals') &&
                            <PD.Tr>
                                <PD.Th>Proposals</PD.Th><PD.Td><SanitizeHTML html={json.proposals}/></PD.Td>
                            </PD.Tr>
                        }


                        {modelResource &&
                            <PD.Tr>
                                <PD.Th>Type</PD.Th><PD.Td>{modelResource.label}</PD.Td>
                            </PD.Tr>
                        }


                        {hasProperty(json,'URL') &&
                            <PD.Tr>
                                <PD.Th>URL</PD.Th><PD.Td>{formatText(json.URL)}</PD.Td>
                            </PD.Tr>
                        }


                        {hasProperty(json,'status') &&
                            <PD.Tr>                                
                                <PD.Th>Status</PD.Th><PD.Td>{json.status}</PD.Td>
                            </PD.Tr>
                        }

                    </PD.Tbody>
                </PD.Table>
            )
        };
        return null;
    };

    render() {

        if (this.state.isLoaded) {

            return (
                <div>
                    {this.view(this.data.Resource)}
                </div>
            )
        }
        else {
            return <div />;
        }

    };    
};
        
export default ViewResource;