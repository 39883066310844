import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual } from "lodash";

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils//utils';


import dsd from "../classes/clsDSD";

import { Plus, Trash } from 'react-bootstrap-icons';

class StepFlowRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
            isFailed: false
        };


        this.data = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.updateParent = this.updateParent.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `.`;


    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.Flow){
                StateChange.FormData = cloneObject(this.state.Flow);

                StateChange.FormData = {};
                if ("idStep" in this.state.Flow){
                    StateChange.FormData.idStepOption = this.state.Flow.idStep;
                    if ("idOption" in this.state.Flow){
                        StateChange.FormData.idStepOption += `_${this.state.Flow.idOption}`;
                    }   
                }

                StateChange.isLoaded = true;
            }
    
            this.setState(StateChange);

        }

    }
    


    setup(){

        let StateChange = {

            isLoaded:false,
            isFailed:false,

            FormData: {},


            Process:null,
            idStep:null,

            Flow: null,
            seq: null,

            parent:null,

        }

        StateChange.parent = getParam('parent', this.props);
        StateChange.mode = getParam('mode', this.props);

        StateChange.selectSteps = getParam('selectSteps', this.props);
        if (isNull(StateChange.selectSteps)){
            StateChange.isFailed = true;
        }

        StateChange.idStep = getParam('idStep', this.props);


        StateChange.Flow = getParam('Flow', this.props);
        if (isNull(StateChange.Flow)){
            StateChange.Flow = {};
        }
        StateChange.seq = getParam('seq', this.props);


        if (StateChange.mode == null){
            StateChange.mode = 'edit';
        }

        this.setState(StateChange);
        
    }

    handleButton(event) {
    
        let StateChange = {};

        switch (event.currentTarget.name){
            case 'btnAdd':
                StateChange.mode = 'edit';
                this.state.parent.addFlow();
                break;
            case 'btnDelete':
                this.state.parent.removeFlow(this.state.seq);
                break;
    
        }
    
        this.setState(StateChange);
    
        event.preventDefault();    

    };


    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let FormData = this.state.FormData;
        FormData[FormFieldName] = FormFieldValue;

        this.setState({"FormData": FormData});



    };
    


    updateParent(){

        let Flow = {}
        const partsIdStepOption = this.state.FormData.idStepOption.split('_');
        if (partsIdStepOption.length > 0){
            Flow.idStep = partsIdStepOption[0];
        }
        if (partsIdStepOption.length > 1){
            Flow.idOption = partsIdStepOption[1];
        }

        this.state.parent.updateFlow(this.state.seq, Flow);
        return;
    }



    render() {

        if (this.state.isFailed) {
            return <PD.ErrorBox>ERROR - Failed to Load</PD.ErrorBox>
        }


        if (this.state.isLoaded) {

            switch (this.state.mode){
                case 'new':
                case 'edit':
                    return (
                        <PD.Tr>
                            <PD.Td>
                                {(() => {
                                    switch (this.state.mode) {
                                        case "new": 
                                            return <button name="btnAdd" onClick={this.handleButton}><Plus/></button>;
                                        case "edit": 
                                            return <button name="btnDelete" onClick={this.handleButton}><Trash/></button>;
                                    }
                                })()}
                            </PD.Td>
                            {this.state.mode == 'edit' &&
                                <PD.Td>

                                    <PD.Select name='idStepOption'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.idStepOption}>
                                        {
                                            this.state.selectSteps.map(
                                                (optStep, posStep) => 
                                                    {
                                                        if (isNull(optStep)){
                                                            return null;
                                                        }
                                                        if (!optStep.name){
                                                            return null;
                                                        }

                                                        switch(optStep.type){
                                                            case "stop":
                                                                return null;
                                                                break;
                                                        }
                                                        let optIdStep = optStep._id;

                                                        if (optIdStep == (this.state.idStep)){
                                                            // can't pick the step that this is a flow for
                                                            return null;
                                                        }
                                                        let optModelStep = null;
                                                        if (optStep.type in this.dsd.model.steps){
                                                            optModelStep = this.dsd.model.steps[optStep.type];
                                                        }

                                                        if (optModelStep){

                                                            switch (optModelStep.type){
                                                                case "bpmn:exclusiveor":
                                                                case "bpmn:inclusiveor":

                                                                    if (!optStep.Options){
                                                                        return null;
                                                                    }
                                                                    return(
                                                                        <React.Fragment>
                                                                            {optStep.Options.map(
                                                                                (optOption, posOption) => 
                                                                                    {
                                                                                        if (isNull(optOption)){
                                                                                            return null;
                                                                                        }
                                                                                        if (!optOption.label){
                                                                                            return null;
                                                                                        }
                                                                                        let optIdOption = optOption._id;
                                                                                        return(
                                                                                            <option key={`${optIdStep}_${optIdOption}`} value={`${optIdStep}_${optIdOption}`}>{`${optStep.name} > ${optOption.label}`}</option>
                                                                                        )
                                                                                    }
                                                                            )}
                                                                        </React.Fragment>
                                                                    )
                                                                    break;
                                                                default:
                                                                    return(
                                                                        <option key={optIdStep} value={optIdStep}>{optStep.name}</option>
                                                                    )
                                                                    break;
                                                            }
                                                        }
                                                    }
                                            )
                                        }
                                    </PD.Select>

                                </PD.Td>
                            }
                        </PD.Tr>
                    );
                    break;
            };
        };

        return null;
    };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <StepFlowRow {...props} navigation={navigation} location={location} params={params} />;
  }
