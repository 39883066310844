import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual } from "lodash";

import sanitizeHtml from 'sanitize-html';


import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';

import dsd from "../classes/clsDSD";

import { Trash } from 'react-bootstrap-icons';

class Revision extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isFailed: false
        };


        this.data = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleButton = this.handleButton.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `.`;


    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.loadRevision){
                this.loadRevision();
            }
    
            this.setState(StateChange);

        }

    }
    
    setup(){

        let StateChange = {

            loadRevision: false,
            isLoaded:false,

            FormData: {
                report:true
            },

            Revision: null,
            idRevision: null,

            idAbout: null,

            mode: null

        }

        StateChange.idAbout = getParam('idAbout', this.props);
        if (StateChange.idAbout){
            StateChange.mode = 'new';
            StateChange.isLoaded = true;
        }

        StateChange.idRevision = getParam('idRevision', this.props);
        if (StateChange.idRevision){
            StateChange.mode = 'edit';
        }


        if (StateChange.idRevision){
            StateChange.loadRevision = true;
        }

        this.setState(StateChange);
        
    }


    loadRevision(){

        let StateChange = {};
        StateChange.loadRevision = false;
        StateChange.isLoaded = false;
        StateChange.Revision = null;

        if (this.state.idRevision){
            StateChange.renderState = "loading";
            this.dsd.getRevision(this.state.idRevision);
        }

        this.setState(StateChange);

        return;
        
    };

    handleButton(event) {
    
        let StateChange = {};

        switch (event.currentTarget.name){
            case 'btnDelete':
                this.dsd.removeRevision(this.state.idRevision);
                break;
    
        }
    
        this.setState(StateChange);
    
        event.preventDefault();    

    };


    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let FormData = this.state.FormData;
        FormData[FormFieldName] = FormFieldValue;

        this.setState({"FormData": FormData});

    };

    update(){    

        if (!this.state.isLoaded){
            return false;
        }


        if ('description' in this.state.FormData){
            this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
        }

        switch (this.state.mode){
            case 'new':
                this.dsd.addRevision(this.state.idAbout,this.state.FormData);
                break;
            case 'edit':
                this.dsd.editRevision(this.state.idRevision,this.state.FormData);
                break;
            default:
                return false;
        };

        return true;

    }



    render() {

        if (this.state.isFailed) {
            return <PD.ErrorBox>ERROR - Failed to Load</PD.ErrorBox>
        }


        if (this.state.isLoaded) {

            if (this.state.Revision){
                this.state.FormData = this.state.Revision;
            }

            switch (this.state.mode){
                case 'new':
                case 'edit':
                    return (
                        <fieldset>
                            <legend>Revision:</legend>
                            <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>
                            <PD.Checkbox label="Report?" name="report" value={this.state.FormData.report} onChange={this.handleChange}/>
                        </fieldset>
                    );
                    break;
            };
        };

        return null;
    };
};

// Wrap and export
// with forwardref so that the update() method can be accessed.


export default React.forwardRef((props, ref) => {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Revision ref={ref} {...props} navigation={navigation} location={location} params={params} />;
  }
);