import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {HtmlToText, getParam} from '../utils/utils';
import {formatText, truncate} from '../utils/utils';

import Buffering from "../components/Buffering"; 

import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";
import ViewStructure from "../components/ViewStructure";
import ViewDataDictionary from "../components/ViewDataDictionary";
import ViewMetadata from "../components/ViewMetadata";

import RowAttribute from "../components/RowAttribute"

import Revision from "../components/Revision";
import ListRevisions from "../components/ListRevisions";


import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

class Structure extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            renderState: null
        };


        this.data = {};
        this.viz = null;
        this.vizEngine = "dot";


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleVizClick = this.handleVizClick.bind(this);

        this.refRevision = React.createRef();
        this.loadedRevisions = this.loadedRevisions.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/`;

    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.loadDataDictionary){
                this.loadDataDictionary();
            }

            if (this.state.loadStructure){
                this.loadStructure();
            }

            if (this.state.renderState == "loading"){
                if (this.state.isFailed){
                    StateChange.renderState = "failed";
                }
                else{
                    switch (this.state.mode){
                        case "new":
                            if (this.state.isLoadedDataDictionary){
                                StateChange.renderState = "loaded";
                            }
                        default:
                            if (this.state.isLoadedStructure){
                                StateChange.DataDictionary = this.state.Structure.DataDictionary;
                                StateChange.renderState = "loaded";
                            }
                            break;                        
                    }
                }
            }

            if (this.state.renderState == "updating"){
                if (this.state.isError){
                    StateChange.renderState = "error";
                    StateChange.isError = false;
                }
                else{
                    if (this.state.isUpdated){
                        StateChange.isUpdated = false;
                        StateChange.renderState = "updated";
                    }
                }
            }

            if (this.state.renderState == "updated"){
                this.props.navigation(`/structure/${this.state.idStructure}`);
            }

            if (this.state.renderState == "cancelled"){
                switch (this.state.mode){
                    case "edit":
                        this.props.navigation(`/structure/${this.state.idStructure}`);
                        break;
                    case "new":
                        this.props.navigation(this.FallBackNavigation);
                        break;
                }
            }

            if (this.state.renderState == "loaded"){

                StateChange.OfferEdit = false;
                if (this.state.DataDictionary){
                    if (this.dsd.loggedon){
                        if (this.dsd.user.id == this.state.DataDictionary.idUser){
                            StateChange.OfferEdit = true;
                        }
                    }
                }

                if (!StateChange.OfferEdit){
                    switch (this.state.mode){
                        case "new":
                        case "edit":
                        case "remove":
                            if (!this.state.OfferEdit){
                                StateChange.renderState = "denied"
                                StateChange.errorMessage = "You cannot add or edit Structures to this Data Dictionary"
                            }
                            break;
                    }
                }

            }
            this.setState(StateChange);

        }


    }
    
    loadDataDictionary(){

        let StateChange = {};
        StateChange.loadDataDictionary = false;
        StateChange.isLoadedDataDictionary = false;
        StateChange.DataDictionary = null;

        if (this.state.idDataDictionary){
            StateChange.renderState = "loading";
            this.dsd.getDataDictionary(this.state.idDataDictionary,"DataDictionary","isLoadedDataDictionary");
        }

        this.setState(StateChange);

        return;
        
    };

    loadStructure(){

        let StateChange = {};
        StateChange.loadStructure = false;
        StateChange.isLoadedStructure = false;
        StateChange.Structure = null;

        if (this.state.idStructure){
            StateChange.renderState = "loading";
            this.dsd.getStructure(this.state.idStructure,"Structure","isLoadedStructure");
        }

        this.setState(StateChange);

        return;
        
    };



    setup(){

        let StateChange = {

            mode: "view",

            idStructure: null,
            idDataDictionary: null,

            DataDictionary: null,
            loadDataDictionary: false,
            isLoadedDataDictionary: false,

            Structure: null,
            loadStructure: false,
            isLoadedStructure: false,

            isUpdated: false,

            isFailed: false,
            isError: false,
            errorMessage: null,

            renderState: null,


            FormData: {},
            OfferEdit:false,

            Controls: {
                vizFullSize :true
            },

            countRevisions: 0,

            vizNodeId: null

        }

        if (getParam('mode', this.props)){
            StateChange.mode = getParam('mode', this.props);
        }
        StateChange.idStructure = getParam('id', this.props);
        StateChange.idDataDictionary = getParam('idDataDictionary', this.props);

        switch (StateChange.mode){
            case 'new':
                if (!StateChange.idDataDictionary){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Data Dictionary not specified when adding a Structure"
                }
                break;
            default:
                if (StateChange.idDataDictionary){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Data Dictionary should not be specified for an existing Structure"
                }
                if (!StateChange.idStructure){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Structure not be specified for an existing Structure"
                }
                break;
        }

        if (StateChange.mode == "new"){
            if (StateChange.idDataDictionary){
                this.FallBackNavigation = `/datadictionary/${StateChange.idDataDictionary}`;
            }        
        }



        if (StateChange.idDataDictionary){
            StateChange.loadDataDictionary = true;
        }

        if (StateChange.idStructure){
            StateChange.loadStructure = true;
        }


        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        switch (FormFieldName){
            case 'vizFullSize':
                let Controls = this.state.Controls;
                Controls[FormFieldName] = FormFieldValue;        
                this.setState({"Controls": Controls});
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;        
                this.setState({"FormData": FormData});
                break;                
        }
        return;

    };
    
    handleSubmit(event) {

        switch (event.target.name){
            
            case 'btnCancel':

                this.setState({
                    renderState: "cancelled"
                })

                break;
            case 'btnSave':
            default:

                this.setState({
                    isUpdated: false,
                    isFailed: false,
                    renderState: "updating"
                })
        

                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addStructureToModel(this.state.idDataDictionary,this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editStructure(this.state.idStructure,this.state.FormData);
                        this.refRevision.current.update();
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };
    
        };

        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
        }

        this.setState(StateChange);

        event.preventDefault();
    };


    addAttribute(){
        let FormData = this.state.FormData;

        if (!('Attributes' in FormData)){
            FormData['Attributes'] = [];
        }
        if (!FormData.Attributes){
            FormData['Attributes'] = [];
        }

        FormData.Attributes.push({});
        this.setState({"FormData": FormData});
    }

    removeAttribute(seq){

        let FormData = this.state.FormData;

        if (!('Attributes' in FormData)){
            FormData['Attributes'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Attributes.length;pos++){
            let item = FormData.Attributes[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Attributes = arrNew;
        this.setState({"FormData": FormData});

    }


    updateAttribute(seq, Attribute){


        if ('description' in Attribute){
            Attribute.description = sanitizeHtml(Attribute.description);
        }


        let FormData = this.state.FormData;

        if (!('Attributes' in FormData)){
            FormData['Attributes'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Attributes.length;pos++){
            let item = FormData.Attributes[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Attribute;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Attribute);
        }

        FormData.Attributes = arrNew;
        this.setState({"FormData": FormData});

    }


    handleVizClick(event) {

        let StateChange = {};

        let idNode = null;
        if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }
        

        StateChange.vizNodeId = idNode;

        this.setState(StateChange);

        event.preventDefault();

    };

    loadedRevisions(count) {

        let StateChange = {};

        StateChange.countRevisions = 0;
        if (count){
            StateChange.countRevisions = count;
        }

        this.setState(StateChange);

    };


    visualise(){
    
        if (!this.state.Structure){
            return null;
        }


        const styles = {
            scrollableDiv: {
                    backgroundColor: "#f1f1f1",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    height: "800px",
                    width: "800px",
                    overflow: "auto",
                    margin: "20px",
                    textAlign: "justify",
                    padding: "20px"
            }
          };


        let vizoptions = {}
        vizoptions.engine = this.vizEngine;
        vizoptions.fit = false;
        vizoptions.width = null;
        vizoptions.height = null;
        vizoptions.zoom = false;
        vizoptions.scale = 1;

        let styleDiv = styles.scrollableDiv;
        if (this.state.Controls.hasOwnProperty('vizFullSize')){
            if (this.state.Controls.vizFullSize){
                styleDiv = null;
            }
        }

        try {

            return(
                <div>
                    <table>
                        <tr>
                            <td>
                            <PD.Checkbox label="Full Screen" name="vizFullSize" value={this.state.Controls.vizFullSize} onChange={this.handleChange}/>                    
                            </td>
                        </tr>
                    </table>

                    <div name="divViz" style={styleDiv} onClick={this.handleVizClick}>
                        <Graphviz dot={this.viz.dot} options={vizoptions}/>
                    </div>

                </div>
            );
          } catch (error) {
            return <div/>
          }
    };


    render() {

        switch (this.state.renderState){
            case "invalid":
            case "failed":
            case "denied":
                let Message = "An error has occured";
                if (this.state.errorMessage){
                    Message = this.state.errorMessage;
                }
                return <PD.ErrorBox>{Message}</PD.ErrorBox>
                break;
            case "loading":
                return <Buffering/>
                break;
            case "cancelled":
                return <p>Cancelled</p>
                break;
    
        }

        switch (this.state.mode){
            case 'new':
            case 'edit':

                if (this.state.Structure){
                    this.state.FormData = this.state.Structure;
                }

                switch (this.state.renderState){
                    case "loaded":
                    case "updating":
                    case "error":
                        return (
                            <div>

                                <PD.Heading>{this.state.mode} Structure</PD.Heading>

                                {this.state.renderState == "error" &&
                                    <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                                }

                                <form> 
                                    <PD.Input label="Name" width="60" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>

                                    <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>
                                    <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>

                                    <PD.Select label="Status" name='status'  onChange={this.handleChange} value={this.state.FormData.status}>
                                        {
                                            this.dsd.config.StructureStatuses.map(
                                                (status, posStatus) => 
                                                    {
                                                        return(
                                                            <option key={posStatus} value={status}>{status}</option>
                                                        )
                                                    }
                                            )
                                        }
                                    </PD.Select>

                                    {this.FormAttributes()}

                                    <Revision ref={this.refRevision}  idAbout={this.state.idStructure}/>
                                    
                                    {(() => {
                                        switch (this.state.renderState) {
                                            case "updating":
                                                return (
                                                    <div>
                                                        <PD.Button disabled={true} type="submit" name="btnSave">
                                                            <Buffering/>
                                                        </PD.Button>
                                                    </div>
                                                )
                                            default:
                                                return (
                                                    <div>
                                                        <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave" value="Save" />    
                                                        <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" /> 
                                                    </div>
                                                )
                                        }
                                    })()}

                                </form>

                            </div>    
                        );
                }
                break;
            case 'remove':
                return (
                    <div>
                        <PD.Heading>{this.state.mode} Structure</PD.Heading>

                        {this.state.isFailed &&
                            <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                        }

                        <PD.Button onClick={this.handleSubmit} type="warning" value="confirm remove?" />
                        <ViewStructure Structure={this.state.Structure}/>
                    </div>
                )
            case 'view':
            default:
                
                switch (this.state.renderState){
                    case "loaded":

                        this.viz = this.dsd.vizStructure({Structure:this.state.Structure}, this.state.Controls, this.vizEngine );

                        return (
                            <div>
                                <div>
                                    <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                    <PD.Heading>Structure:{this.state.Structure.name}</PD.Heading>
                                </div>

                                {this.state.OfferEdit &&
                                    <span style={{paddingRight:10}}>
                                            <PD.Button onClick={this.handleMode} type="submit" value="edit" />                        
                                    </span>
                                }

                                <ViewStructure Structure={this.state.Structure}/>

                                <br/>

                                <PD.Tabs>

                                <div label='Visualise'>
                                    <table>
                                        <tr>
                                            <td style={{verticalAlign: 'top', width: 'auto'}}>
                                                {this.visualise()}
                                            </td>
                                        </tr>
                                    </table>
                                </div>


                                <div label='Attributes'>

                                    {isArray(this.state.Structure.Attributes) &&
                                        <PD.Table className="list">
                                            <PD.Thead>
                                                <PD.Tr>
                                                    <PD.Th>Name</PD.Th>
                                                    <PD.Th>Description</PD.Th>
                                                    <PD.Th>Data Type</PD.Th>
                                                    <PD.Th>Structure</PD.Th>
                                                    <PD.Th>Occurs</PD.Th>
                                                    <PD.Th>Required</PD.Th>
                                                </PD.Tr>
                                            </PD.Thead>

                                            <PD.Tbody>
                                                {
                                                    this.state.Structure.Attributes.map(
                                                        (Attribute, pos) => 
                                                        {
                                                            return (
                                                                <PD.Tr key={pos}>
                                                                    <PD.Td>
                                                                        {Attribute.name}
                                                                    </PD.Td>
                                                                   
                                                                    <PD.Td>
                                                                        {formatText(truncate(HtmlToText(Attribute.description), 4000))}
                                                                    </PD.Td>

                                                                    {(() => {
                                                                        switch (Attribute.type) {
                                                                            case "structure":
                                                                                if (Attribute.Structure){
                                                                                    return (
                                                                                        <>
                                                                                            <PD.Td/>
                                                                                            <PD.Td>
                                                                                                <PD.PdNavLink to={"/structure/" + Attribute.Structure._id} > {Attribute.Structure.name}</PD.PdNavLink>
                                                                                            </PD.Td>
                                                                                        </>
                                                                                    );
                                                                                }                                                                                
                                                                                return (
                                                                                    <>
                                                                                        <PD.Td/>
                                                                                        <PD.Td/>
                                                                                    </>    
                                                                                )
                                                                                break;
                                                                            default:
                                                                                return(
                                                                                    <>
                                                                                        <PD.Td>
                                                                                            {Attribute.dataType}
                                                                                        </PD.Td>
                                                                                        <PD.Td/>
                                                                                    </>
                                                                                )
                                                                        }
                                                                    })()}


                                                                    <PD.Td>
                                                                        {Attribute.occurs}
                                                                    </PD.Td>

                                                                    <PD.Td>
                                                                        {Attribute.required}
                                                                    </PD.Td>


                                                                </PD.Tr>

                                                            )
                                                        }
                                                    )
                                                }
                                            </PD.Tbody>
                                        </PD.Table>
                                    }

                                </div>

                                    <div label='Data Dictionary'>
                                        <ViewDataDictionary DataDictionary={this.state.DataDictionary} />
                                    </div>

                                    <div label='Metadata'>
                                        <ViewMetadata Data={this.state.Structure} />
                                    </div>

                                    <div label={`Revisions(${this.state.countRevisions})`} forceRender={true}>
                                        <ListRevisions OfferEdit={this.state.OfferEdit} filters={{idAbout:this.state.idStructure}} onLoad={this.loadedRevisions}/>
                                    </div>

                                    <div label="used in ...">
                                    </div>


                                </PD.Tabs>


                            </div>
                        )
                }
        };

        return <div />;
    };


    FormAttributes(){

        let seqNext = 1;
        if (this.state.Structure){
            if (this.state.Structure.Attributes){
                seqNext = this.state.FormData.Attributes.length + 1;
            }
        }
        return (
            <fieldset>
                <legend>Attributes</legend>
                <PD.Table type="list">
                    {this.state.FormData.Attributes &&
                        this.state.FormData.Attributes.map(
                            (Attribute, posAttribute) => 
                            {
                                let seq = posAttribute+1;
                                return(
                                    this.FormAttribute('edit', seq, Attribute)
                                )
                            }
                        )
                    }
                    {this.FormAttribute('new', seqNext)}
                </PD.Table>
            </fieldset>
        );

    }

    FormAttribute(mode, seq, Attribute = {}){
        return(
            <RowAttribute key={seq.toString()} mode={mode} parent={this} Attribute={Attribute} seq={seq}/>
        )
    }


};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Structure {...props} navigation={navigation} location={location} params={params} />;
  }
