import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';


import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';


import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";
import ViewResource from "../components/ViewResource";
import ViewMetadata from "../components/ViewMetadata";
import ListProcesses from "../components/ListProcesses";


class Resource extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
            reload:false
        };


        this.data = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/resources`;


    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        let StateChange = {};

        if (!isEqual(this.props, prevProps)){
            this.setup();
            if (this.state.idResource == prevState.idResource){
                if (this.state.idResource){
                    StateChange.reload = true;
                    this.setState(StateChange);
                }

            }
        }


        if (!isEqual(this.state, prevState)){

            if (this.state.mode === 'new'){
                StateChange.isLoaded = true;
            }

            if (this.state.reload != prevState.reload){
                if (this.state.reload){
                    this.loadData(this.state.idResource);
                    StateChange.updateToggle = Number(!this.state.updateToggle)
                    StateChange.reload = false;
                }
            }
            if (this.state.isUpdated){
                StateChange.isUpdated = false;
                StateChange.mode = 'view';
                this.loadData(this.state.idResource);
            }
            else{
                if (this.state.idResource != prevState.idResource){
                    if (this.state.idResource){
                        this.onUpdateNavigation = `/resource/${this.state.idResource}`;
                        this.loadData(this.state.idResource);
                    }
                }
            }

            if (this.state.idResource){
                for (var i in window.Breadcrumb){
                    if (window.Breadcrumb[i][0] === 'resource'){
                        window.Breadcrumb = window.Breadcrumb.slice(0,i);
                        break;
                    }
                }
                this.Breadcrumb = JSON.parse(JSON.stringify(window.Breadcrumb));
    
                window.Breadcrumb.push(['resource',`/resource/${this.state.idResource}`]);

                this.FallBackNavigation = `/resources`;
                this.onUpdateNavigation = this.FallBackNavigation;
    
            };

            this.setState(StateChange);

        }

    }
    
    loadData(id){

        if (id !== undefined){
            this.dsd.getResource(id);
        }
        
        return;
        
    };

    setup(){


        let StateChange = {
            id: null,
            isLoaded: false,
            isUpdated: false,
            isFailed: false,
            reload: false,
            FormData: {},

            idResource: null,
            Resource: null,

            action: null,

            updateToggle: 0

        }

        StateChange.mode = getParam('mode', this.props);
        StateChange.idResource = getParam('id', this.props);

        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let FormData = this.state.FormData;
        FormData[FormFieldName] = FormFieldValue;        
        this.setState({"FormData": FormData});

    };
    
    handleSubmit(event) {

        switch (event.target.name){
            case 'btnCancel':
                switch (this.state.mode){
                    case 'new':
                        this.props.navigation(this.FallBackNavigation);
                        break;
                    case 'edit':
                        this.loadData(this.state.idResource);
                        this.setState({mode: 'view'});
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };

                break;
            case 'btnSave':
            default:

                this.setState({
                    isUpdated: false,
                    isFailed: false
                })
        
                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addResource(this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editResource(this.state.idResource,this.state.FormData);
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };
    
        };


        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

/*        
        switch (event.target.name){
            case 'btnAddStep':

                StateChange.action = 'addStep';
                break;
        }
*/
        this.setState(StateChange);

        event.preventDefault();
    };


    render() {

        if (this.state.isUpdated){
            switch (this.state.mode){
                case 'remove':
                    this.props.navigation(this.FallBackNavigation);
                    return null;
                default:
                    break;
            };
        };

        if (this.state.isLoaded) {

            let boolOfferEdit = false;

            if (this.state.Resource){
                this.state.FormData = this.state.Resource;
                if (this.dsd.user){
                    if (this.dsd.user.id == this.state.Resource.idUser){
                        boolOfferEdit = true;
                    }
                }        
            }

            switch (this.state.mode){
                case 'new':
                case 'edit':
                    return (
                        <div>
                        <PD.Heading>{this.state.mode} Resource</PD.Heading>
    
                        {this.state.isFailed &&
                            <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                        }
    
                        <form> 
                            <PD.Input label="Name" width="30" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>
                            <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>
                            <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>

                            <PD.Select label="Type" name='type'  onChange={this.handleChange} value={this.state.FormData.type}>
                                {
                                    Object.keys(this.dsd.model.resources).map(
                                        (modelKey, posModelResource) => 
                                            {
                                                let modelResource = this.dsd.model.resources[modelKey];
                                                return(
                                                    <option key={posModelResource} value={modelResource.name}>{modelResource.label}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>


                            <PD.Input label="URL" width="30" name="URL" value={this.state.FormData.URL} onChange={this.handleChange}/>


                            <PD.Select label="Status" name='status'  onChange={this.handleChange} value={this.state.FormData.status}>
                                {
                                    this.dsd.config.ResourceStatuses.map(
                                        (status, posStatus) => 
                                            {
                                                return(
                                                    <option key={posStatus} value={status}>{status}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>

                            <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave"value="Save" />    
                            <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" />    
                        </form>
    
                    </div>    
                    );
                case 'remove':
                    return (
                        <div>
                            <PD.Heading>{this.state.mode} Resource</PD.Heading>

                            {this.state.isFailed &&
                                <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                            }

                            <PD.Button onClick={this.handleSubmit} type="warning" value="confirm remove?" />
                            <ViewResource Resource={this.state.Resource}/>
                        </div>
                    )
                case 'view':
                default:

                    if (!this.state.Resource){
                        return <div/>
                    }

//                    const countItems = this.state.countItems == null ? "Loading..." : this.state.countItems;


                    return (
                        <div>
                            <div>
                                <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                <PD.Heading>Resource:{this.state.Resource.name}</PD.Heading>
                            </div>

                            {boolOfferEdit &&
                                <span style={{paddingRight:10}}>
                                        <PD.Button onClick={this.handleMode} type="submit" value="edit" />                        
                                </span>
                            }

                            <ViewResource Resource={this.state.Resource}/>

                            <br/>


                            <PD.Tabs>


                                <div label='Metadata'>
                                    <ViewMetadata Data={this.state.Resource} />
                                </div>

                                <div label="used in ...">
                                    <ListProcesses filters={{idResource:this.state.idResource}} columns={{"owner":true}}/>
                                </div>


                            </PD.Tabs>

                        </div>
                    )
            };
        };

        return <div />;
    };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Resource {...props} navigation={navigation} location={location} params={params} />;
  }
