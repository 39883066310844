import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';


import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';


import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";
import ViewProcess from "../components/ViewProcess";
import ViewMetadata from "../components/ViewMetadata";

import ListProcesses from "../components/ListProcesses";
import ListSteps from "../components/ListSteps";

import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';
import Buffering from "../components/Buffering";



class Process extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
            loadData:false
        };


        this.data = {};
        this.viz = null;
        this.vizEngine = "neato";


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleClick = this.handleClick.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/processes`;


    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.vizNodeId){
                let partsVizNodeId = this.state.vizNodeId.split('_');
                switch(partsVizNodeId[0]){
                    case 'step':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/step/${partsVizNodeId[1]}`);
                        }
                        break;
                    case 'artefact':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/artefact/${partsVizNodeId[1]}`);
                        }
                        break;
    
                }
            }


            if (this.state.mode === 'new'){
                StateChange.isLoaded = true;
            }

            if (this.state.loadData){
                this.loadData(this.state.idProcess);
                StateChange.updateToggle = Number(!this.state.updateToggle)
                StateChange.loadData = false;
            }

            if (this.state.isUpdated){
                StateChange.isUpdated = false;
                StateChange.mode = 'view';
                StateChange.loadData = true;
            }
            else{
                if (this.state.idProcess != prevState.idProcess){
                    if (this.state.idProcess){
                        this.onUpdateNavigation = `/process/${this.state.idProcess}`;
                        StateChange.loadData = true;
                    }
                }
            }

            if (this.state.idProcess){
                for (var i in window.Breadcrumb){
                    if (window.Breadcrumb[i][0] === 'process'){
                        window.Breadcrumb = window.Breadcrumb.slice(0,i);
                        break;
                    }
                }
                this.Breadcrumb = JSON.parse(JSON.stringify(window.Breadcrumb));
    
                window.Breadcrumb.push(['process',`/process/${this.state.idProcess}`]);

                this.FallBackNavigation = `../processes`;
                this.onUpdateNavigation = this.FallBackNavigation;
    
            };

            this.setState(StateChange);

        }

    }
    
    loadData(id){

        if (id !== undefined){
            this.dsd.getProcess(id);
        }
        
        return;
        
    };

    setup(){


        let StateChange = {
            id: null,
            isLoaded: false,
            isUpdated: false,
            isFailed: false,

            loadData: false,

            FormData: {},
            Controls: {
                vizFullSize :true
            },

            idProcess : null,
            countSteps : null,

            Process: null,

            action: null,

            updateToggle: 0,

            vizNodeId: null

        }

        StateChange.mode = getParam('mode', this.props);
        StateChange.idProcess = getParam('id', this.props);
        if (StateChange.idProcess){
            StateChange.loadData = true;
        }

        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        switch (FormFieldName){
            case 'vizFullSize':
            case 'vizArtefacts':
            case 'vizSwimlanes':
                let Controls = this.state.Controls;
                Controls[FormFieldName] = FormFieldValue;        
                this.setState({"Controls": Controls});
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;        
                this.setState({"FormData": FormData});
                break;                
        }
        return;

    };
    
    handleSubmit(event) {

        switch (event.target.name){
            case 'btnCancel':
                switch (this.state.mode){
                    case 'new':
                        this.props.navigation(this.FallBackNavigation);
                        break;
                    case 'edit':
                        this.loadData(this.state.idProcess);
                        this.setState({mode: 'view'});
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };

                break;
            case 'btnSave':
            default:

                this.setState({
                    isUpdated: false,
                    isFailed: false
                })
        

                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addProcess(this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editProcess(this.state.idProcess,this.state.FormData);
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };
    
        };


        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
            case 'btnAddStep':
                this.props.navigation(`/process/${this.state.idProcess}/step?mode=new`);
                break;
        }

        this.setState(StateChange);

        event.preventDefault();
    };

    handleClick(event) {

        let StateChange = {};

        let idNode = null;
        if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }
        

        StateChange.vizNodeId = idNode;

        this.setState(StateChange);

        event.preventDefault();

    };



    visualise(){
    
        if (!this.state.Process){
            return null;
        }

        const styles = {
            scrollableDiv: {
                    backgroundColor: "#f1f1f1",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    height: "800px",
                    width: "800px",
                    overflow: "auto",
                    margin: "20px",
                    textAlign: "justify",
                    padding: "20px"
            }
          };


//        let engine = "neato";


        let vizoptions = {}
        vizoptions.engine = this.vizEngine;
        vizoptions.fit = false;
        vizoptions.width = null;
        vizoptions.height = null;
        vizoptions.zoom = false;
        vizoptions.scale = 1;

        let styleDiv = styles.scrollableDiv;
        if (this.state.Controls.hasOwnProperty('vizFullSize')){
            if (this.state.Controls.vizFullSize){
                styleDiv = null;
            }
        }

        try {

            return(
                <div>
                    <table>
                        <tr>
                            <td>
                            <PD.Checkbox label="Full Screen" name="vizFullSize" value={this.state.Controls.vizFullSize} onChange={this.handleChange}/>                    
                            </td>
                            <td>
                            <PD.Checkbox label="Swimlanes" name="vizSwimlanes" value={this.state.Controls.vizSwimlanes} onChange={this.handleChange}/>                                
                            </td>
                            <td>
                            <PD.Checkbox label="Artefacts" name="vizArtefacts" value={this.state.Controls.vizArtefacts} onChange={this.handleChange}/>                                
                            </td>
                        </tr>
                    </table>

                    <div name="divProcessMap" style={styleDiv} onClick={this.handleClick}>
                        <Graphviz dot={this.viz.dot} options={vizoptions}/>
                    </div>

                </div>
            );
          } catch (error) {
            return <div/>
          }
    };




    setLayoutContent(){

        if (this.context.hasLayout){
            
            let Content = {Index:{}};

            if (this.state.Process){
                Content.Index.title = this.state.Process.name;
                Content.Index.link = `/process/${this.state.Process._id}`

                Content.Index.Sections = [];

                if (this.viz){
                    if (this.viz.nodes){

                        let Section = {title:"Steps"}
                        Section.Items = [];
    

                        Object.keys(this.viz.nodes).map((idNode,posNode)=>{
                            let dotNode = this.viz.nodes[idNode];

                            let Item = {}
                            Item.label = dotNode.label;
                            if (dotNode.Step){
                                Item.link = `/step/${dotNode.Step._id}`
                            }
                            Section.Items.push(Item);
                        })

                        Content.Index.Sections.push(Section);

                    }
                }

            }

            this.context.Layout.setLayoutContent(Content)

            localStorage.setItem("dsdContent",JSON.stringify(Content));
            
        }

        return;

    }


    render() {


        if (this.state.isUpdated){
            switch (this.state.mode){
                case 'remove':
                    this.props.navigation(this.FallBackNavigation);
                    return null;
                default:
                    break;
            };
        };

        if (this.state.isLoaded) {

            this.setLayoutContent();

            let boolOfferEdit = false;

            if (this.state.Process){
                this.state.FormData = this.state.Process;
                if (this.dsd.loggedon){
                    if (this.dsd.user.id == this.state.Process.idUser){
                        boolOfferEdit = true;
                    }
                }        
            }

            switch (this.state.mode){
                case 'new':
                case 'edit':

                    return (
                    <div>

                        <PD.Heading>{this.state.mode} Process</PD.Heading>
    
                        {this.state.isFailed &&
                            <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                        }
    
                        <form> 
                            <PD.Input label="Name" width="30" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>

                            <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>
                            <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>

                            <PD.Select label="Status" name='status'  onChange={this.handleChange} value={this.state.FormData.status}>
                                {
                                    this.dsd.config.ProcessStatuses.map(
                                        (status, posStatus) => 
                                            {
                                                return(
                                                    <option key={posStatus} value={status}>{status}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>

                            <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave"value="Save" />    
                            <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" />    
                        </form>
    
                    </div>    
                    );
                case 'remove':
                    return (
                        <div>
                            <PD.Heading>{this.state.mode} Process</PD.Heading>

                            {this.state.isFailed &&
                                <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                            }

                            <PD.Button onClick={this.handleSubmit} type="warning" value="confirm remove?" />
                            <ViewProcess json={this.state.Process}/>
                        </div>
                    )
                case 'view':
                default:

                    if (!this.state.Process){
                        return <div/>
                    }
                    
//                    const countItems = this.state.countItems == null ? "Loading..." : this.state.countItems;

                    this.viz = this.dsd.vizProcess(this.state.Process, this.vizEngine, this.state.Controls.vizSwimlanes, this.state.Controls.vizArtefacts);

                    return (
                        <div>
                            <div>
                                <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                <PD.Heading>Process:{this.state.Process.name}</PD.Heading>
                            </div>

                            {boolOfferEdit &&
                                <span style={{paddingRight:10}}>
                                        <PD.Button onClick={this.handleMode} type="submit" value="edit" />                        
                                </span>
                            }

                            <ViewProcess Process={this.state.Process}/>

                            <br/>

                            <PD.Tabs>

                                <div label='Visualise'>
                                    <table>
                                        <tr>
                                            <td style={{verticalAlign: 'top', width: 'auto'}}>
                                                {this.visualise()}
                                            </td>
                                        </tr>
                                    </table>
                                </div>



                                <div label='Steps'>

                                    {!this.state.action && boolOfferEdit &&                           
                                        <PD.Button name='btnAddStep' value='add a new Step' onClick={this.handleButton}/>
                                    }
                                    
                                    <ListSteps filters={{idProcess:this.state.idProcess}} parent={this} />
                                
                                </div>


                                <div label="used in ...">
                                    <ListProcesses filters={{idSubProcess:this.state.idProcess}} columns={{"owner":true}}/>
                                </div>

                                {this.state.idProcess &&
                                    <div label='Publication'>
                                        <PD.PdNavLink to={`/viewer/process/${this.state.idProcess}`} >Default Publication View</PD.PdNavLink>
                                    </div>
                                }


                                <div label='Developer'>
                                    <table>
                                        <tr>
                                            <th style={{verticalAlign:"top"}}>dot</th>
                                            <td><pre><code>{this.viz.dot}</code></pre></td>
                                        </tr>
                                        <tr>
                                            <th style={{verticalAlign:"top"}}>layout</th>
                                            <td>
                                                <PD.Table>
                                                    <PD.Tr>
                                                        <PD.Th>id</PD.Th>
                                                        <PD.Th>type</PD.Th>
                                                        <PD.Th>label</PD.Th>
                                                        <PD.Th>rank</PD.Th>
                                                        <PD.Th>parallel</PD.Th>
                                                        <PD.Th>lane</PD.Th>
                                                        <PD.Th>pos x</PD.Th>
                                                        <PD.Th>pos y</PD.Th>
                                                    </PD.Tr>

                                                    {

                                                        Object.keys(this.viz.nodes).map((idNode,posNode)=>{
                                                            let dotNode = this.viz.nodes[idNode];
                                                                return(
                                                                    <PD.Tr key={posNode}>
                                                                        <PD.Td>{dotNode.idNode}</PD.Td>
                                                                        <PD.Td>{dotNode.type}</PD.Td>
                                                                        <PD.Td style={{maxWidth:"250px"}}>{dotNode.label}</PD.Td>
                                                                        <PD.Td style={{textAlign:'right'}}>{dotNode.Rank}</PD.Td>
                                                                        <PD.Td style={{textAlign:'right'}}>{dotNode.Parallel}</PD.Td>
                                                                        <PD.Td style={{textAlign:'right'}}>{dotNode.Lane}</PD.Td>
                                                                        <PD.Td style={{textAlign:'right'}}>{dotNode.posX.toFixed(2)}</PD.Td>
                                                                        <PD.Td style={{textAlign:'right'}}>{dotNode.posY.toFixed(2)}</PD.Td>
                                                                    </PD.Tr>
                                                                )
                                                            }
                                                        )
                                                    }


                                                </PD.Table>



                                            </td>
                                        </tr>

                                    </table>

                                </div>


                                <div label='Metadata'>
                                    <ViewMetadata Data={this.state.Process} />
                                </div>

                            </PD.Tabs>


                        </div>
                    )
            };
        };

        return <div />;
    };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Process {...props} navigation={navigation} location={location} params={params} />;
  }
