import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';

import dsd from "../classes/clsDSD";
import {HowToText, getQueryStringParams} from '../utils/utils';

import ListArtefacts from '../components/ListArtefacts';

import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';


class Artefacts extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };

        this.handleButton = this.handleButton.bind(this);

        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.data = {}

        this.setup();


    }

    componentDidMount(){

    
    }


    componentDidUpdate(prevProps, prevState){

        if (prevProps != this.props){
            this.setup();
        }

    }
    
    setup(){

        this.queryparams = getQueryStringParams(this.props.location.search);

        for (var i in window.Breadcrumb){
            if (window.Breadcrumb[i][0] === 'artefacts'){
                window.Breadcrumb = window.Breadcrumb.slice(0,i);
                break;
            }
        }
        this.Breadcrumb = JSON.parse(JSON.stringify(window.Breadcrumb));

    }

    handleButton(event) {

        switch (event.target.name){
            case 'btnAdd':
                this.props.navigation(`/artefact?mode=new`);
                break;
            default:
                break;          
        };

        event.preventDefault();
      }


      about(){

        return(
                <div>                    

                    <div>
                        <div><strong>Artefacts</strong></div>
                        <br/>
                    </div>

                </div>
            )

        return <div/>

    }

    setLayoutContent(){

        if (this.context.hasLayout){
            
            let Content = {Index:{}};

            this.context.Layout.setLayoutContent(Content)

            localStorage.setItem("dsdContent",JSON.stringify(Content));
            
        }

        return;

    }


    render() {
        return (

            <div>
                <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                <PD.Heading>Artefacts</PD.Heading>

                <PD.Tabs>

                    <div label="About">                    
                        {this.about()}
                    </div>

                    {this.dsd.user &&
                        <div label="My Artefacts">
                            <PD.Button value='add a new Artefact' name='btnAdd' onClick={this.handleButton}/>

                            <ListArtefacts filters={{}}/>

                        </div>
                    }
                    <div label="Published Artefacts">
                        <ListArtefacts filters={{status:'published'}} columns={{"owner":true}}/>
                    </div>
                    {this.dsd.user &&
                        <div label="My Deprecated Artefacts">
                            <ListArtefacts filters={{status:'deprecated'}}/>
                        </div>
                    }
                </PD.Tabs>

            </div>
            )
        };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();

    return <Artefacts {...props} navigation={navigation} location={location} />;
  }
