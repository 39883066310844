import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual } from "lodash";

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';


import dsd from "../classes/clsDSD";

import { Plus, Trash } from 'react-bootstrap-icons';

class StepArtefactRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
            isFailed: false
        };


        this.data = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.updateParent = this.updateParent.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `.`;


    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.Artefact){
                StateChange.FormData = cloneObject(this.state.Artefact);
            }

            if (this.state.Artefact && this.state.optArtefacts){
                StateChange.isLoaded = true;
            }
    
            this.setState(StateChange);

        }


    }
    
    setup(){

        let StateChange = {

            isLoaded:false,

            FormData: {},

            Artefact: null,
            seq: null,

            parent:null,

            optArtefacts:null,
        }

        StateChange.parent = getParam('parent', this.props);
        StateChange.mode = getParam('mode', this.props);

        StateChange.Artefact = getParam('Artefact', this.props);
        if (isNull(StateChange.Artefact)){
            StateChange.Artefact = {};
        }
        StateChange.seq = getParam('seq', this.props);


        if (StateChange.mode == null){
            StateChange.mode = 'edit';
        }

        this.loadArtefacts();

        this.setState(StateChange);
        
    }

    loadArtefacts(){

        this.dsd.listArtefacts({},"optArtefacts");

    }

    handleButton(event) {
    
        let StateChange = {};

        switch (event.currentTarget.name){
            case 'btnAdd':
                StateChange.mode = 'edit';
                this.state.parent.addArtefact();
                break;
            case 'btnDelete':
                this.state.parent.removeArtefact(this.state.seq);
                break;
    
        }
    
        this.setState(StateChange);
    
        event.preventDefault();    

    };


    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let FormData = this.state.FormData;
        FormData[FormFieldName] = FormFieldValue;

        this.setState({"FormData": FormData});

    };

    updateParent(){    
        this.state.parent.updateArtefact(this.state.seq, this.state.FormData);
    }


    render() {

        if (this.state.isFailed) {
            return <PD.ErrorBox>ERROR - Failed to Load</PD.ErrorBox>
        }


        if (this.state.isLoaded) {

            switch (this.state.mode){
                case 'new':
                case 'edit':
                    return (
                        <PD.Tr>
                            <PD.Td>
                                {(() => {
                                    switch (this.state.mode) {
                                        case "new": 
                                            return <button name="btnAdd" onClick={this.handleButton}><Plus/></button>;
                                        case "edit": 
                                            return <button name="btnDelete" onClick={this.handleButton}><Trash/></button>;
                                    }
                                })()}
                            </PD.Td>
                            {this.state.mode == 'edit' &&
                                <React.Fragment>
                                    <PD.Td>

                                        <PD.Select label='Artefact' name='idArtefact'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.idArtefact}>
                                            {
                                                this.state.optArtefacts.map(
                                                (optArtefact, posOptArtefact) => 
                                                    {
                                                        let optModelArtefact = null;
                                                        if (optArtefact.type in this.dsd.model.artefacts){
                                                            optModelArtefact = this.dsd.model.artefacts[optArtefact.type];
                                                        }
                                                        return (
                                                            <option key={posOptArtefact} value={optArtefact.id}>{optArtefact.name}</option>
                                                        )
                                                    }
                                                )
                                            }
                                        </PD.Select>

                                        <PD.Select label='use' name='use'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.use}>
                                            {
                                                this.dsd.config.ArtefactUses.map(
                                                (optUse, posUse) => 
                                                    {
                                                        return (
                                                            <option key={posUse} value={optUse}>{optUse}</option>
                                                        )
                                                    }
                                                )
                                            }
                                        </PD.Select>


                                    </PD.Td>
                                </React.Fragment>
                            }
                        </PD.Tr>
                    );
                    break;
            };
        };

        return null;
    };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <StepArtefactRow {...props} navigation={navigation} location={location} params={params} />;
  }
