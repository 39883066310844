import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import {cloneObject, getParam} from '../utils/utils';

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';

import Buffering from "../components/Buffering"; 



class SelectLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            renderState: null
        };

        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.handleChange = this.handleChange.bind(this);
    

    }

    componentDidMount(){

        this.setup();
 
     }
 
     componentDidUpdate(prevProps, prevState){
 
        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){


            if (this.state.idSelected != prevState.idSelected){
                if (this.state.idSelected){
                    this.state.loadSelectedLink = true;
                }
            }

            if (this.state.isLoadedSelectedLink){
                if (this.state.SelectedLink.ConceptModel){
                    StateChange.idConceptModel = this.state.SelectedLink.ConceptModel._id;
                }

            }

            if (this.state.idConceptModel != prevState.idConceptModel){
                if (this.state.idConceptModel){
                    StateChange.loadLinks = true;
                }
            }

            if (this.state.loadConceptModels){
                this.loadConceptModels();
            }

            if (this.state.loadLinks){
                this.loadLinks();
            }

            if (this.state.loadSelectedLink){
                this.loadSelectedLink(this.state.idSelected);
                if (this.state.onBlur){
                    this.state.onBlur();
                }
            }


            if (this.state.isFailed){
                StateChange.renderState = "failed";
            }

            if (this.state.renderState == "loading"){
                if (this.state.isLoadedConceptModels){
                    StateChange.renderState = "loaded";
                }

                if (this.state.isLoadedLinks){
                    StateChange.renderState = "loaded";
                }
            }
             
            this.setState(StateChange);

        }
        

    
    }
 

    setup(){

        let StateChange = {
            renderState: null,

            loadConceptModels: false,
            loadLinks: false,
            loadSelectedLink: false,

            selectConceptModel: false,

            label: "Link",
            idSelected: "",
            idConceptModel: null,

            onChange: null,
            onBlur: null

        }

        StateChange.name = getParam('name', this.props);

        if (getParam('label',this.props)){
            StateChange.label = getParam('label',this.props);
        }

        if (getParam('selectConceptModel',this.props)){
            StateChange.selectConceptModel = getParam('selectConceptModel',this.props);
        }


        StateChange.onChange = getParam('onChange', this.props);
        StateChange.onBlur = getParam('onBlur', this.props);

        StateChange.idConceptModel = getParam('idConceptModel', this.props);

        StateChange.idSelected = getParam('value', this.props);


        if (StateChange.selectConceptModel){
            StateChange.loadConceptModels = true;
        }
        else
        {
            StateChange.loadLinks = true;
        }

        this.setState(StateChange);

    }

    loadSelectedLink(idLink){

        let StateChange = {};
        StateChange.loadSelectedLink = false;
        StateChange.isLoadedSelectedLink = false;
        StateChange.SelectedLink = null;

        StateChange.renderState = "loading";
        this.dsd.getLink(idLink,"SelectedLink","isLoadedSelectedLink")
        
        this.setState(StateChange);

        return;
        
    };



    loadLinks(){

        let StateChange = {};
        StateChange.loadLinks = false;
        StateChange.isLoadedLinks = false;
        StateChange.Links = null;

        StateChange.renderState = "loading";
        this.dsd.listLinks({idConceptModel:this.state.idConceptModel},"Links","isLoadedLinks")
        
        this.setState(StateChange);

        return;
        
    };


    loadConceptModels(){

        let StateChange = {};
        StateChange.loadConceptModels = false;
        StateChange.isLoadedConceptModels = false;
        StateChange.ConceptModels = null;

        StateChange.renderState = "loading";
        this.dsd.listConceptModels({},"ConceptModels","isLoadedConceptModels");
        
        this.setState(StateChange);

        return;
        
    };



    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        switch (FormFieldName){
            case "idConceptModel":
                this.setState({"idConceptModel":FormFieldValue})
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;
                this.setState({"FormData": FormData});
                break;
        };

    };


    render() {

        switch (this.state.renderState){
            case "invalid":
            case "failed":
            case "denied":
                let Message = "An error has occured";
                if (this.state.errorMessage){
                    Message = this.state.errorMessage;
                }
                return <PD.ErrorBox>{Message}</PD.ErrorBox>
                break;
            case "loading":
                return <Buffering/>
                break;
            case "cancelled":
                return <p>Cancelled</p>
                break;
            case "loaded":

                return(
                    <>                    

                        {this.state.selectConceptModel && this.state.isLoadedConceptModels &&
                            <PD.Select label='Select a Concept Model' name='idConceptModel' onChange={this.handleChange} value={this.state.idConceptModel}>
                                {
                                    this.state.ConceptModels.map(
                                        (ConceptModel, pos) => 
                                            {
                                                return(
                                                    <option key={pos} value={ConceptModel._id}>{ConceptModel.name}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>
                        }


                        {this.state.isLoadedLinks &&

                            <PD.Select label={this.state.label} name={this.state.name} onChange={this.state.onChange} onBlur={this.state.onBlur} value={this.state.idSelected}>
                                {
                                    this.state.Links.map(
                                        (Link, pos) => 
                                            {
                                                return(
                                                    <option key={pos} value={Link._id}>{Link.name}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>
                        }

                    </>
                )
        }

    }
}


export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <SelectLink {...props} navigation={navigation} location={location} params={params} />;
  }
