import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';


import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';


import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";

import PublishContainer from "../layout/PublishLayout";

import PublishProcess from "../components/PublishProcess";


class Viewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
            reload:false
        };


        this.data = {};

        this.handleButton = this.handleButton.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `.`;


    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            this.setState(StateChange);

        }

    }
    

    setup(){


        let StateChange = {
            isLoaded: false,
            reload: false,

            FormData: {},

            action: null,

            updateToggle: 0,

            vizNodeId: null,

            idProcess: null,
            idTopProcess: null


        }

        StateChange.idTopProcess = getParam('idTopProcess', this.props);
        StateChange.idProcess = getParam('idProcess', this.props);

        this.setState(StateChange);
        
    }



    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
//            case 'btnAddStep':
//                StateChange.action = 'addStep';
//                break;
//            case 'btnEditStep':
//                StateChange.seqStep = parseInt(event.currentTarget.getAttribute("data-seq"));
//                StateChange.action = 'editStep';
//                break;    
        }

        this.setState(StateChange);

        event.preventDefault();
    };


    render() {


        if (!this.state.idTopProcess){
            return <div/>
        }


        return (
            <div>
{/*                <PublishContainer>
                    <PublishProcess idProcess={this.state.idProcess} />
                </PublishContainer>
        */}
                <PublishProcess idProcess={this.state.idProcess} />
            </div>
        )
    };

};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Viewer {...props} navigation={navigation} location={location} params={params} />;
  }
