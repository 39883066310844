import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {HtmlToText, getParam} from '../utils/utils';
import {formatText, truncate} from '../utils/utils';

import Buffering from "../components/Buffering"; 

import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";
import ViewAssociation from "../components/ViewAssociation";
import ViewPackage from "../components/ViewPackage";
import ViewMetadata from "../components/ViewMetadata";

import SelectUseCase from "../components/SelectUseCase";
import SelectActor from "../components/SelectActor";


import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

class Association extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            renderState: null
        };


        this.data = {};
        this.viz = null;
        this.vizEngine = "dot";


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleVizClick = this.handleVizClick.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/`;

    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.vizNodeId){
                let partsVizNodeId = this.state.vizNodeId.split('_');
                switch(partsVizNodeId[0]){
                    case 'association':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/association/${partsVizNodeId[1]}`);
                        }
                        break;        
                }

            }


            if (this.state.loadPackage){
                this.loadPackage();
            }

            if (this.state.loadAssociation){
                this.loadAssociation();
            }

            if (this.state.renderState == "loading"){
                if (this.state.isFailed){
                    StateChange.renderState = "failed";
                }
                else{
                    switch (this.state.mode){
                        case "new":
                            if (this.state.isLoadedPackage){
                                StateChange.renderState = "loaded";
                            }
                        default:
                            if (this.state.isLoadedAssociation){
                                StateChange.Package = this.state.Association.Package;
                                StateChange.renderState = "loaded";
                            }
                            break;                        
                    }
                }
            }

            if (this.state.renderState == "updating"){
                if (this.state.isError){
                    StateChange.renderState = "error";
                    StateChange.isError = false;
                }
                else{
                    if (this.state.isUpdated){
                        StateChange.isUpdated = false;
                        StateChange.renderState = "updated";
                    }
                }
            }

            if (this.state.renderState == "updated"){
                this.props.navigation(`/association/${this.state.idAssociation}`);
            }

            if (this.state.renderState == "cancelled"){
                switch (this.state.mode){
                    case "edit":
                        this.props.navigation(`/association/${this.state.idAssociation}`);
                        break;
                    case "new":
                        this.props.navigation(this.FallBackNavigation);
                        break;
                }
            }

            if (this.state.renderState == "loaded"){

                StateChange.OfferEdit = false;
                if (this.state.Package){
                    if (this.dsd.loggedon){
                        if (this.dsd.user.id == this.state.Package.idUser){
                            StateChange.OfferEdit = true;
                        }
                    }
                }

                if (!StateChange.OfferEdit){
                    switch (this.state.mode){
                        case "new":
                        case "edit":
                        case "remove":
                            if (!this.state.OfferEdit){
                                StateChange.renderState = "denied"
                                StateChange.errorMessage = "You cannot add or edit Associations to this Package"
                            }
                            break;
                    }
                }

            }
            this.setState(StateChange);

        }


    }
    
    loadPackage(){

        let StateChange = {};
        StateChange.loadPackage = false;
        StateChange.isLoadedPackage = false;
        StateChange.Package = null;

        if (this.state.idPackage){
            StateChange.renderState = "loading";
            this.dsd.getPackage(this.state.idPackage,"Package","isLoadedPackage");
        }

        this.setState(StateChange);

        return;
        
    };

    loadAssociation(){

        let StateChange = {};
        StateChange.loadAssociation = false;
        StateChange.isLoadedAssociation = false;
        StateChange.Association = null;

        StateChange.isLoadedAssociationLinks = false;
        StateChange.AssociationLinks = null;


        if (this.state.idAssociation){
            StateChange.renderState = "loading";
            this.dsd.getAssociation(this.state.idAssociation,"Association","isLoadedAssociation");
        }

        this.setState(StateChange);

        return;
        
    };


    setup(){

        let StateChange = {

            mode: "view",

            idAssociation: null,
            idPackage: null,

            Package: null,
            loadPackage: false,
            isLoadedPackage: false,

            Association: null,
            loadAssociation: false,
            isLoadedAssociation: false,

            isUpdated: false,

            isFailed: false,
            isError: false,
            errorMessage: null,

            renderState: null,


            FormData: {},
            OfferEdit:false,

            Controls: {
                vizFullSize :true
            },

            vizNodeId: null

        }

        if (getParam('mode', this.props)){
            StateChange.mode = getParam('mode', this.props);
        }
        StateChange.idAssociation = getParam('id', this.props);
        StateChange.idPackage = getParam('idPackage', this.props);

        switch (StateChange.mode){
            case 'new':
                if (!StateChange.idPackage){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Package not specified when adding a Association"
                }
                break;
            default:
                if (StateChange.idPackage){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Package should not be specified for an existing Association"
                }
                if (!StateChange.idAssociation){
                    StateChange.renderState = "invalid";
                    StateChange.errorMessage = "Association not be specified for an existing Association"
                }
                break;
        }

        if (StateChange.mode == "new"){
            if (StateChange.idPackage){
                this.FallBackNavigation = `/package/${StateChange.idPackage}`;
            }        
        }



        if (StateChange.idPackage){
            StateChange.loadPackage = true;
        }

        if (StateChange.idAssociation){
            StateChange.loadAssociation = true;
        }


        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        switch (FormFieldName){
            case 'vizFullSize':
                let Controls = this.state.Controls;
                Controls[FormFieldName] = FormFieldValue;        
                this.setState({"Controls": Controls});
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;        

                if (FormFieldName == 'type'){
                    if (FormFieldValue){
                        if (!FormData.name){
                            FormData.name = `<<${FormFieldValue}>>`;
// note that the iput in render has a key property to force it to rerender when the type changes.
                        }
                    }
                }
    

                this.setState({"FormData": FormData});
                break;                
        }
        return;

    };
    
    handleSubmit(event) {

        switch (event.target.name){
            
            case 'btnCancel':

                this.setState({
                    renderState: "cancelled"
                })

                break;
            case 'btnSave':
            default:

                this.setState({
                    isUpdated: false,
                    isFailed: false,
                    invalidFields: null,
                    renderState: "updating"
                })
        

                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addAssociationToPackage(this.state.idPackage,this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editAssociation(this.state.idAssociation,this.state.FormData);
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFassociation(this.data.idProject,this.type,this.data.idFassociation);
        //                break;  
                    default:
                        break; 
                };
    
        };

        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
        }

        this.setState(StateChange);

        event.preventDefault();
    };


    handleVizClick(event) {

        let StateChange = {};

        let idNode = null;
        if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }
        

        StateChange.vizNodeId = idNode;

        this.setState(StateChange);

        event.preventDefault();

    };


    visualise(){
    
        if (!this.state.Association){
            return null;
        }


        const styles = {
            scrollableDiv: {
                    backgroundColor: "#f1f1f1",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    height: "800px",
                    width: "800px",
                    overflow: "auto",
                    margin: "20px",
                    textAlign: "justify",
                    padding: "20px"
            }
          };


        let vizoptions = {}
        vizoptions.engine = this.vizEngine;
        vizoptions.fit = false;
        vizoptions.width = null;
        vizoptions.height = null;
        vizoptions.zoom = false;
        vizoptions.scale = 1;

        let styleDiv = styles.scrollableDiv;
        if (this.state.Controls.hasOwnProperty('vizFullSize')){
            if (this.state.Controls.vizFullSize){
                styleDiv = null;
            }
        }

        try {

            return(
                <div>
                    <table>
                        <tr>
                            <td>
                            <PD.Checkbox label="Full Screen" name="vizFullSize" value={this.state.Controls.vizFullSize} onChange={this.handleChange}/>                    
                            </td>
                        </tr>
                    </table>

                    <div name="divViz" style={styleDiv} onClick={this.handleVizClick}>
                        <Graphviz dot={this.viz.dot} options={vizoptions}/>
                    </div>

                </div>
            );
          } catch (error) {
            return <div/>
          }
    };

    showError(){


        if (this.state.invalidFields){
            return(
                <>
                    {
                        Object.keys(this.state.invalidFields).map(
                        (field, pos) => 
                            {
                                let error = this.state.invalidFields[field].error;
                                return <p>{field}:{error}</p>
                            }
                        )
                    }
                </>
            )
        }

        return "ERROR - Failed to Update";

    }


    render() {

        switch (this.state.renderState){
            case "invalid":
            case "failed":
            case "denied":
                let Message = "An error has occured";
                if (this.state.errorMessage){
                    Message = this.state.errorMessage;
                }
                return <PD.ErrorBox>{Message}</PD.ErrorBox>
                break;
            case "loading":
                return <Buffering/>
                break;
            case "cancelled":
                return <p>Cancelled</p>
                break;
    
        }


        switch (this.state.mode){
            case 'new':
            case 'edit':

                if (this.state.Association){
                    this.state.FormData = this.state.Association;
                }

                switch (this.state.renderState){
                    case "loaded":
                    case "updating":
                    case "error":

                        let idPackage = null;
                        if (this.state.Package){
                            idPackage = this.state.Package._id;
                        }

                        return (
                            <div>

                                <PD.Heading>{this.state.mode} Association</PD.Heading>

                                {this.state.renderState == "error" &&
                                    <PD.ErrorBox>{this.showError()}</PD.ErrorBox>
                                }

                                <form> 

                                    <fieldset>
                                        <legend>Select the Actor</legend>
                                        <SelectActor label='Actor' name="idActor" onChange={this.handleChange} idPackage={idPackage} value={this.state.FormData.idActor}/>
                                    </fieldset>

                                    <PD.Select label="Type" name='type'  onChange={this.handleChange} value={this.state.FormData.type}>
                                        {
                                            Object.keys(this.dsd.config.AssociationTypes).map(
                                                (type, posType) => 
                                                    {
                                                        return(
                                                            <option key={posType} value={type}>{type}</option>
                                                        )
                                                    }
                                            )
                                        }
                                    </PD.Select>

                                    <fieldset>
                                        <legend>Select the Use Case</legend>
                                        <SelectUseCase label='Use Case' name="idUseCase" onChange={this.handleChange} idPackage={idPackage} value={this.state.FormData.idUseCase}/>
                                    </fieldset>


                                    <PD.Input key={this.state.FormData.type} label="Name" width="60" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>

                                    <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>
                                    <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>

                                    <PD.Select label="Status" name='status'  onChange={this.handleChange} value={this.state.FormData.status}>
                                        {
                                            this.dsd.config.AssociationStatuses.map(
                                                (status, posStatus) => 
                                                    {
                                                        return(
                                                            <option key={posStatus} value={status}>{status}</option>
                                                        )
                                                    }
                                            )
                                        }
                                    </PD.Select>
                                    
                                    {(() => {
                                        switch (this.state.renderState) {
                                            case "updating":
                                                return (
                                                    <div>
                                                        <PD.Button disabled={true} type="submit" name="btnSave">
                                                            <Buffering/>
                                                        </PD.Button>
                                                    </div>
                                                )
                                            default:
                                                return (
                                                    <div>
                                                        <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave" value="Save" />    
                                                        <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" /> 
                                                    </div>
                                                )
                                        }
                                    })()}

                                </form>

                            </div>    
                        );
                }
                break;
            case 'remove':
                return (
                    <div>
                        <PD.Heading>{this.state.mode} Association</PD.Heading>

                        {this.state.isFailed &&
                            <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                        }

                        <PD.Button onClick={this.handleSubmit} type="warning" value="confirm remove?" />
                        <ViewAssociation Association={this.state.Association}/>
                    </div>
                )
            case 'view':
            default:
                
                switch (this.state.renderState){
                    case "loaded":

//                        this.viz = this.dsd.vizAssociation({Association:this.state.Association, AssociationLinks:this.state.AssociationLinks}, this.state.Controls, this.vizEngine );

                        return (
                            <div>
                                <div>
                                    <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                    <PD.Heading>Association:{this.state.Association.name}</PD.Heading>
                                </div>

                                {this.state.OfferEdit &&
                                    <span style={{paddingRight:10}}>
                                            <PD.Button onClick={this.handleMode} type="submit" value="edit" />                        
                                    </span>
                                }

                                <ViewAssociation Association={this.state.Association}/>

                                <br/>

                                <PD.Tabs>

                                <div label='Visualise'>
                                    <table>
                                        <tr>
                                            <td style={{verticalAlign: 'top', width: 'auto'}}>
                                                {this.visualise()}
                                            </td>
                                        </tr>
                                    </table>
                                </div>




                                    <div label='Package'>
                                        <ViewPackage Package={this.state.Package} />
                                    </div>

                                    <div label='Metadata'>
                                        <ViewMetadata Data={this.state.Association} />
                                    </div>


                                    <div label="used in ...">
                                    </div>

                                    <div label='Developer'>
                                        <table>
                                            {this.viz &&
                                                <tr>
                                                    <th style={{verticalAlign:"top"}}>dot</th>
                                                    <td><pre>{this.viz.dot}</pre></td>
                                                </tr>
                                            }
                                        </table>
                                    </div>


                                </PD.Tabs>


                            </div>
                        )
                }
        };

        return <div />;
    };
}


// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Association {...props} navigation={navigation} location={location} params={params} />;
  }
