import React from "react"
import {  NavLink } from "react-router-dom";

import * as PD from '../classes/pdStyle';

import { PublishLayoutContext } from '../contexts/PublishLayoutContext';
import { isNull, isEqual, isArray } from "lodash";

import logo from '../images/SAVVI LOGO COLOUR.jpg';



class PublishContainer extends React.Component {

    static contextType = PublishLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
//            LeftPaneContent:null,
            LayoutContent:null
        }

        this.setLayoutContent = this.setLayoutContent.bind(this);
//        this.setLeftPaneContent = this.setLeftPaneContent.bind(this);

    }


    setLayoutContent(content){

        if (!isEqual(content, this.state.LayoutContent)){
            this.setState({LayoutContent:content});
        }
    }

/*
    setLeftPaneContent(content){

        if (!isEqual(content, this.state.LeftPaneContent)){
            this.setState({LeftPaneContent:content});
        }
    }
*/
    LeftPane(){

        if (this.state.LayoutContent){
            return (
                <div className="publish-sidemenu">

                    {this.state.LayoutContent.processName &&
                        <div className="publish-processtitle">
                            <b>{this.state.LayoutContent.processName}</b>
                        </div>
                    }
                    {this.state.LayoutContent.Sections &&
                        this.state.LayoutContent.Sections.map(
                            (Section, posSection) => 
                            {

                                let classSection = '';
                                if (Section.highlight){
                                    classSection = 'publish-menuHighlight'
                                }
                                return(
                                    <div key={posSection} className={classSection}>
                                        <div style={{paddingLeft: "20px"}}>
                                            {(() => {
                                                if (Section.link) {
                                                    return(
                                                    <p><PD.PdNavLink to={Section.link}> {Section.title}</PD.PdNavLink></p>
                                                    )
                                                }
                                                else{
                                                    return (<p>{Section.title}</p>)
                                                }
                                            })()}

                                            
                                            {Section.Items &&
                                                <ul>
                                                {Section.Items.map(
                                                    (Item, posItem) => 
                                                    {

                                                        let classItem = '';
                                                        if (Item.highlight){
                                                            classItem = 'publish-menuHighlight'
                                                        }

                                                        return(
                                                            <li key={posItem} className={classItem}>
                                                                {(() => {
                                                                    if (Item.link) {
                                                                        return(
                                                                        <PD.PdNavLink to={Item.link}> {Item.label}</PD.PdNavLink>
                                                                        )
                                                                    }
                                                                    else{
                                                                        return (<>{Item.label}</>)
                                                                    }
                                                                })()}
                                                            </li>
                                                        )
                        
                                                    }
                                                )
                                                }
                                                </ul>
                                            }
                                        </div>
                                    </div>
                                )

                            }
                        )
                    }
                </div>
            )
        }
        return <div/>        
    }

    render(){

        return(
            <PublishLayoutContext.Provider value={{"hasLayout":true, "Layout":this, "Content":this.state.LayoutContent}}>
                <div id="publish-container">
                    <div id="publish-header" key="publish-header">
                        <PublishHeader content={this.state.LayoutContent}/>
                    </div>
                    <div id="publish-wrapper">
                        <div id="publish-leftpane" key="publish-leftpane">
                            {this.LeftPane()}
                        </div>
                        <div id="publish-content" key="publish-content">
                            {this.props.children}
                        </div>
                    </div>
                    <div id="publish-footer"  key="publish-footer">
                        <PublishFooter/>
                    </div>
                </div>
            </PublishLayoutContext.Provider>
        )
    };
};


//import logo from './images/SAVVI LOGO COLOUR.jpg';

class PublishHeader extends React.Component {

    static contextType = PublishLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            breadcrumb:null
        }

    }

    componentDidMount(){

        this.setup();
 
     }
 
     componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }
     }


    setup(){


        if (this.props.content){
            if (this.props.content.Breadcrumb){
                  this.setState({breadcrumb:this.props.content.Breadcrumb})
            }
        }

    }

    Breadcrumb(Breadcrumb){

        if (Breadcrumb.Items){

            return (
                <div className="publish-breadcrumb"> 

                    {Breadcrumb.Items.map(
                        (Item, posItem) => 
                        {
                            return(
                                <>
                                    {(() => {
                                        if (Item.link) {
                                            return(
                                                <PD.PdNavLink key={posItem} to={Item.link}> {Item.label}</PD.PdNavLink>
                                            )
                                        }
                                        else{
                                            return (<span key={posItem}>{Item.label}</span>)
                                        }
                                    })()}
                                    {(posItem + 1) < Breadcrumb.Items.length &&
                                        <span key={`span-${posItem}`}> / </span>
                                    }
                                </>
                            )
                        }
                    )
                    }
                </div>
            )
         }
        
    }


    render() {

        let Title = null;
        if (this.props.content){
            if (this.props.content.title){
                Title = this.props.content.title;
            }
        }


        return (
            <div>
                <table>
            <       tr>
                        <td>
                            <a href="http://www.savviuk.org">
                                <img src={logo} height="60" alt="SAVVI Logo"/>
                            </a>
                        </td>
                        <td valign='top'>
                            <div className="publish-title">{Title}</div>
                        </td>
                        <td valign='top'>
                        </td>
                    </tr>
                </table>
                <hr/>
                {this.state.breadcrumb &&
                    this.Breadcrumb(this.state.breadcrumb)
                }
            </div>
        )
    };
};



class PublishFooter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {

        return (
            <div>
                <hr/>
                Generated by the Digital Service Designer for SAVVI <a href="www.savviuk.org">www.savviuk.org</a>
            </div>
        )
    };
};

export default PublishContainer;