import React from "react"

import dsd from "../classes/clsDSD";

import * as PD from '../classes/pdStyle';

import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';
import { isNull, isEqual, isArray } from "lodash";


class DesignerContainer extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            LayoutContent:null,
            user:null
        }

        this.dsd = new dsd(this);

        this.setLayoutContent = this.setLayoutContent.bind(this);
        this.setUser = this.setUser.bind(this);

    }


    setLayoutContent(content){

        if (!isEqual(content, this.state.LayoutContent)){
            this.setState({LayoutContent:content});
        }
    }

    setUser(user){

        if (!isEqual(user, this.state.user)){
            this.setState({user:user});
        }
    }


    FeatureMenu(){

        if (this.dsd.config.Features){
            return (
                <div className="designer-sidemenu">
                    {this.dsd.config.Features.map(
                        (Feature, posFeature) => 
                        {
                            let classSection = '';
                            if (Feature.highlight){
                                classSection = 'designer-menuHighlight'
                            }
                            return(
                                <p><PD.PdNavLink to={Feature.link}> {Feature.label}</PD.PdNavLink></p>
                            )
                        })
                    }
                </div>
            )
        }
        return null;
        
    }

    IndexLinks(){

        if (this.state.LayoutContent){
            if (this.state.LayoutContent.Index){
                const Index = this.state.LayoutContent.Index;
                if (Index.title){
                    return (
                        <div className="designer-sidemenu">
                            {Index.title &&
                                <div className="designer-indextitle">

                                    {(() => {
                                        if (Index.link) {
                                            return(
                                            <p><PD.PdNavLink to={Index.link}> {Index.title}</PD.PdNavLink></p>
                                            )
                                        }
                                        else{
                                            return (<p>{Index.title}</p>)
                                        }
                                    })()}

                                    <div style={{paddingLeft: "20px"}}>
                                        {this.state.LayoutContent.Index.Sections &&
                                            this.state.LayoutContent.Index.Sections.map(
                                                (Section, posSection) => 
                                                {
                                                    let classSection = '';
                                                    if (Section.highlight){
                                                        classSection = 'designer-menuHighlight'
                                                    }
                                                    return(
                                                        <div key={posSection} className={classSection}>

                                                            {(() => {
                                                                if (Section.link) {
                                                                    return(
                                                                    <p><PD.PdNavLink to={Section.link}> {Section.title}</PD.PdNavLink></p>
                                                                    )
                                                                }
                                                                else{
                                                                    return (<p>{Section.title}</p>)
                                                                }
                                                            })()}


                                                            {Section.Items &&
                                                                <ul>
                                                                {Section.Items.map(
                                                                    (Item, posItem) => 
                                                                    {

                                                                        let classItem = '';
                                                                        if (Item.highlight){
                                                                            classItem = 'designer-menuHighlight'
                                                                        }

                                                                        return(
                                                                            <li key={posItem} className={classItem}>
                                                                                {(() => {
                                                                                    if (Item.link) {
                                                                                        return(
                                                                                        <PD.PdNavLink to={Item.link}> {Item.label}</PD.PdNavLink>
                                                                                        )
                                                                                    }
                                                                                    else{
                                                                                        return (<>{Item.label}</>)
                                                                                    }
                                                                                })()}
                                                                            </li>
                                                                        )
                                        
                                                                    }
                                                                )
                                                                }
                                                                </ul>
                                                            }

                                                        </div>
                                                    )
                                                }
                                            )
                                        }
                                    </div>
                                </div>
                            }

                        </div>
                    )
                }
                                        
                return null;
            }

        }

        return <div/>        
    }

    render(){

        return(
            <DesignerLayoutContext.Provider value={{"hasLayout":true, "Layout":this, "Content":this.state.LayoutContent}}>
                <div id="designer-container">
                    <div id="designer-header" key="designer-header">
                        <DesignerHeader content={this.state.LayoutContent} user={this.state.user}/>
                    </div>
                    <div id="designer-wrapper">
                        <div id="designer-leftpane" key="designer-leftpane">
                            {this.IndexLinks()}
                            {this.FeatureMenu()}
                        </div>
                        <div id="designer-content" key="designer-content">
                            {this.props.children}
                        </div>
                    </div>
                    <div id="designer-footer"  key="designer-footer">
                        <DesignerFooter/>
                    </div>
                </div>
            </DesignerLayoutContext.Provider>
        )
    };
};


class DesignerHeader extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            breadcrumb:null
        }

    }

    componentDidMount(){

        this.setup();
 
     }
 
     componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }
     }


    setup(){


        if (this.props.content){
            if (this.props.content.Breadcrumb){
                  this.setState({breadcrumb:this.props.content.Breadcrumb})
            }
        }

    }

    Breadcrumb(Breadcrumb){

        if (Breadcrumb.Items){

            return (
                <div className="designer-breadcrumb"> 

                    {Breadcrumb.Items.map(
                        (Item, posItem) => 
                        {
                            return(
                                <>
                                    {(() => {
                                        if (Item.link) {
                                            return(
                                                <PD.PdNavLink key={posItem} to={Item.link}> {Item.label}</PD.PdNavLink>
                                            )
                                        }
                                        else{
                                            return (<span key={posItem}>{Item.label}</span>)
                                        }
                                    })()}
                                    {(posItem + 1) < Breadcrumb.Items.length &&
                                        <span key={`span-${posItem}`}> / </span>
                                    }
                                </>
                            )
                        }
                    )
                    }
                </div>
            )
         }
        
    }


    render() {

        let Title = 'Digital Service Designer';
        if (this.props.content){
            if (this.props.content.title){
                Title = this.props.content.title;
            }
        }

        let user = this.props.user;

        return (
            <div>
                <div className="designer-title">{Title}</div>
                <table>
                    <tbody>
                        <tr>
                            <td valign='top'>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                            </td>
                            <td valign='top'>
                                <PD.PdNavLink exact to={"/"} >Home</PD.PdNavLink>
                            </td>
                            {user &&
                            <td valign='top'>
                                <PD.PdNavLink exact to={"/login"} >Logout</PD.PdNavLink>
                            </td>            
                            }
                            {user &&
                                <td valign='top'>
                                <PD.P>Logged on as: {user.organisation}</PD.P>
                                </td>            
                            }
                            {!user &&
                                <td>                                
                                    <PD.PdNavLink exact to={"/login"} >Login</PD.PdNavLink> 
                                </td>
                            }

                        </tr>
                    </tbody>
                </table>
                <hr/>
                {this.state.breadcrumb &&
                    this.Breadcrumb(this.state.breadcrumb)
                }
            </div>
        )
    };
};



class DesignerFooter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }

    }


    render() {

        return (
            <div>
                <hr/>
                Digital Service Designer - version 2.20
            </div>
        )

    };
};

export default DesignerContainer;