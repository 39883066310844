import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';

import ListProcesses from '../components/ListProcesses';

import dsd from "../classes/clsDSD";
import {HowToText, getQueryStringParams} from '../utils/utils';

import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

class Processes extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            Filters:{},
            toggle: 0
        };

        this.dsd = new dsd(this);

        this.handleButton = this.handleButton.bind(this);
        this.handleFilters = this.handleFilters.bind(this);
        this.setFilters = this.setFilters.bind(this);

    }

    componentDidMount(){

        this.setup();
    
    }


    componentDidUpdate(prevProps, prevState){

        if (prevProps != this.props){
            this.setup();
        }

    }
    
    setup(){

        this.queryparams = getQueryStringParams(this.props.location.search);

        let StateChange = {};

        let Filters = {};
        Filters.scope = 'published';        
        if (this.dsd.loggedon){
            Filters.scope = 'my';
        }
        StateChange.Filters = Filters;
        this.setState(StateChange);

    }

    handleButton(event) {

        switch (event.target.name){
            case 'btnAdd':
                this.props.navigation(`/process?mode=new`);
                break;
            default:
                break;          
        };

        event.preventDefault();
      }

      handleFilters(event) {

        if (this.idFilterTimeout) {
            clearTimeout(this.idFilterTimeout);
        }

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let Filters = this.state.Filters;
        Filters[FormFieldName] = FormFieldValue;

        switch (FormFieldName){
            case 'scope':
                switch (FormFieldValue){
                    case 'my':
                        break;
                    default:
                        Filters['status'] = '';
                        break;
                };
                break;
            case 'search':
                this.idFilterTimeout = setTimeout(this.setFilters, 500, Filters);
                return;
        }

        this.setFilters(Filters);

        return;

    };

    setFilters(Filters){

        this.setState({"Filters": Filters});
        this.setState({"toggle":!this.state.toggle})

        return;

    };



    about(){

        return(
                <div>                    

                    <div>
                        <div><strong>Processes</strong></div>
                        <br/>
                    </div>

                </div>
            )

        return <div/>

    }


    setLayoutContent(){

        if (this.context.hasLayout){
            
            let Content = {Index:{}};

            this.context.Layout.setLayoutContent(Content)

            localStorage.setItem("dsdContent",JSON.stringify(Content));
            
        }

        return;

    }

    render() {

        this.setLayoutContent();

        return (

            <div>
                <PD.Heading>Processes</PD.Heading>

                <PD.Tabs>

                    <div label="About">                    
                        {this.about()}
                    </div>

                    <div label="Processes">
                        <div style={{display:"flex"}}>
                            <PD.Button value='add a new Process' name='btnAdd' onClick={this.handleButton}/>
                            <fieldset style={{display:"flex"}}>
                                <legend>filters</legend>


                                <PD.Radio label='Scope' name='scope'  onChange={this.handleFilters} value={this.state.Filters.scope}>

                                    {(() => {
                                        switch (this.dsd.loggedon) {
                                        case true:
                                            return(
                                                <>
                                                    <label>my</label>
                                                    <label>my deprecated</label>
                                                    <label>published</label>
                                                </>
                                            )
                                        default:
                                            return(
                                                <>
                                                    <label>published</label>
                                                </>
                                            )
                                        }
                                    })()}

                                </PD.Radio>

                                <PD.Select label='Status' name='status'                                                                         
                                    onChange={this.handleFilters} 
                                    value={this.state.Filters.status}

                                    disabled={this.state.Filters.scope!=='my'}

                                    >                                        


                                    {
                                        this.dsd.config.ComponentStatuses.map(
                                            (status, posStatus) => 
                                                {
                                                    switch (status){
                                                        case 'deprecated':
                                                            return null;
                                                        default:
                                                            return(
                                                                <option key={posStatus} value={status}>{status}</option>
                                                            )
                                                    }
                                                }
                                        )
                                    }
                                </PD.Select>

                                <PD.Input label="Search" width="15" name="search" value={this.state.Filters.serch} onChange={this.handleFilters}/>

                            </fieldset>
                        </div>

                        <ListProcesses filters={this.state.Filters} toggle={this.state.toggle}/>

                    </div>


                </PD.Tabs>
            </div>

            )
        };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();

    return <Processes {...props} navigation={navigation} location={location} />;
  }
