import React from 'react';
import './App.css';

import { BrowserRouter as Router, HashRouter, Route, Routes, Outlet } from 'react-router-dom';
import {Helmet} from 'react-helmet';

import Home from './pages/Home';
import LoginPage from './auth/LoginPage/LoginPage';

import Processes from './pages/Processes';
import Process from './pages/Process';

import Step from './pages/Step';


import Artefacts from './pages/Artefacts';
import Artefact from './pages/Artefact';

import Resources from './pages/Resources';
import Resource from './pages/Resource';


import ConceptModels from './pages/ConceptModels';
import ConceptModel from './pages/ConceptModel';
import Concept from './pages/Concept';
import Link from './pages/Link';


import LogicalModels from './pages/LogicalModels';
import LogicalModel from './pages/LogicalModel';
import Entity from './pages/Entity';
import Relationship from './pages/Relationship';


import DataDictionaries from './pages/DataDictionaries';
import DataDictionary from './pages/DataDictionary';
import Structure from './pages/Structure';
import TermType from './pages/TermType';

import Packages from './pages/Packages';
import Package from './pages/Package';
import System from './pages/System';
import Actor from './pages/Actor';
import UseCase from './pages/UseCase';
import Association from './pages/Association';




import Narratives from './pages/Narratives';
import Narrative from './pages/Narrative';



import Viewer from './pages/Viewer';

import DesignerContainer from "./layout/DesignerLayout";
import PublishContainer from "./layout/PublishLayout";


window.Preferences = {
  style:'pd',
  endpoint:'internal'  
};

//window.data = {};


window.Breadcrumb = [];

//window.TopBanner = null;


class App extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
      };
  };


  setMeta(){
    switch (window.Preferences.style){
      case 'pd':
      default:
        return (

          <Helmet>
            <meta charSet="utf-8" />
            <title>Digital Service Designer</title>
            <link rel="stylesheet" type="text/css" href="./style/pd/style.css" />
            <link rel="stylesheet" type="text/css" href="./style/pd/layout.css" />
            <link rel="stylesheet" type="text/css" href="./style/publish/style.css" />
          </Helmet>
        )
    };
  };

  render() {
    return(

      <HashRouter>
        {this.setMeta()}  
        
        <Routes>

            <Route path="viewer" element={<RoutePublisher/>}>
              <Route path="process/:idTopProcess" element={<Viewer/>}/>
            </Route>


            <Route element={<RouteDesigner/>}>
              <Route exact path="/login" element={<LoginPage />} />

              <Route exact path="processes" element={<Processes/>}/>
              <Route exact path="process" element={<Process/>}/>
              <Route exact path="process/:id" element={<Process/>}/>

              <Route exact path="process/:idProcess/step" element={<Step/>}/>
              <Route exact path="step/:id" element={<Step/>}/>


              <Route exact path="artefacts" element={<Artefacts/>}/>
              <Route exact path="artefact" element={<Artefact/>}/>
              <Route exact path="artefact/:id" element={<Artefact/>}/>

              <Route exact path="resources" element={<Resources/>}/>
              <Route exact path="resource" element={<Resource/>}/>
              <Route exact path="resource/:id" element={<Resource/>}/>

              <Route exact path="conceptmodels" element={<ConceptModels/>}/>
              <Route exact path="conceptmodel" element={<ConceptModel/>}/>
              <Route exact path="conceptmodel/:id" element={<ConceptModel/>}/>

              <Route exact path="conceptmodel/:id/version/:idVersion" element={<ConceptModel/>}/>



              <Route exact path="conceptmodel/:idConceptModel/concept" element={<Concept/>}/>
              <Route exact path="concept/:id" element={<Concept/>}/>

              <Route exact path="conceptmodel/:idConceptModel/link" element={<Link/>}/>
              <Route exact path="link/:id" element={<Link/>}/>



              <Route exact path="logicalmodels" element={<LogicalModels/>}/>
              <Route exact path="logicalmodel" element={<LogicalModel/>}/>
              <Route exact path="logicalmodel/:id" element={<LogicalModel/>}/>

              <Route exact path="logicalmodel/:id/version/:idVersion" element={<LogicalModel/>}/>


              <Route exact path="logicalmodel/:idLogicalModel/entity" element={<Entity/>}/>
              <Route exact path="entity/:id" element={<Entity/>}/>

              <Route exact path="logicalmodel/:idLogicalModel/relationship" element={<Relationship/>}/>
              <Route exact path="relationship/:id" element={<Relationship/>}/>


              <Route exact path="datadictionaries" element={<DataDictionaries/>}/>
              <Route exact path="datadictionary" element={<DataDictionary/>}/>
              <Route exact path="datadictionary/:id" element={<DataDictionary/>}/>

              <Route exact path="datadictionary/:id/version/:idVersion" element={<DataDictionary/>}/>


              <Route exact path="datadictionary/:idDataDictionary/structure" element={<Structure/>}/>
              <Route exact path="structure/:id" element={<Structure/>}/>

              <Route exact path="datadictionary/:idDataDictionary/termtype" element={<TermType/>}/>
              <Route exact path="termtype/:id" element={<TermType/>}/>



              <Route exact path="packages" element={<Packages/>}/>
              <Route exact path="package" element={<Package/>}/>
              <Route exact path="package/:id" element={<Package/>}/>

              <Route exact path="package/:idPackage/actor" element={<Actor/>}/>
              <Route exact path="actor/:id" element={<Actor/>}/>

              <Route exact path="package/:idPackage/system" element={<System/>}/>
              <Route exact path="system/:id" element={<System/>}/>

              <Route exact path="package/:idPackage/usecase" element={<UseCase/>}/>
              <Route exact path="usecase/:id" element={<UseCase/>}/>

              <Route exact path="package/:idPackage/association" element={<Association/>}/>
              <Route exact path="association/:id" element={<Association/>}/>


              <Route exact path="narratives" element={<Narratives/>}/>

              <Route exact path="narrative" element={<Narrative/>}/>
              <Route exact path="narrative/:id" element={<Narrative/>}/>



              <Route path='*' element={<Home/>}/>
            </Route>

        </Routes>
            
            
      </HashRouter>

    )
  }


}


class RouteDesigner extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
      };
  };


  render(){
    return(
        <DesignerContainer>
          <Outlet/>
        </DesignerContainer>
    )

  }
};


class RoutePublisher extends React.Component {

  constructor(props) {
      super(props);

      this.state = {
      };
  };


  render(){
    return(
        <PublishContainer>
          <Outlet/>
        </PublishContainer>
    )

  }
};





export default App;
