import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual } from "lodash";

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';


import dsd from "../classes/clsDSD";

import { Plus, Trash } from 'react-bootstrap-icons';

class StepResourceRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
            isFailed: false
        };


        this.data = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.updateParent = this.updateParent.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `.`;


    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.Resource){
                StateChange.FormData = cloneObject(this.state.Resource);
            }

            if (this.state.Resource && this.state.optResources){
                StateChange.isLoaded = true;
            }

            if (this.state.FormData.idResource){
                if (this.state.FormData.idResource != prevState.FormData.idResource){
                    StateChange.getResource = true;
                }
            }

            if (this.state.isLoaded){
                if (this.state.getResource){
                    if (this.state.FormData.idResource){
                        StateChange.selectedResource = null;
                        for (let pos=0;pos<this.state.optResources.length;pos++){
                            if (this.state.optResources[pos].id == this.state.FormData.idResource){
                                StateChange.selectedResource = this.state.optResources[pos]
                            }
                        }
                    }
                    StateChange.getResource = false;        
                }
            }

            this.setState(StateChange);

        }


    }
    
    setup(){

        let StateChange = {

            isLoaded:false,

            FormData: {},

            Resource: null,
            seq: null,

            parent:null,

            optResources:null,

            getResource:false,
            selectedResource:null
        }

        StateChange.parent = getParam('parent', this.props);
        StateChange.mode = getParam('mode', this.props);

        StateChange.Resource = getParam('Resource', this.props);
        if (isNull(StateChange.Resource)){
            StateChange.Resource = {};
        }
        StateChange.seq = getParam('seq', this.props);


        if (StateChange.mode == null){
            StateChange.mode = 'edit';
        }

        this.loadResources();

        this.setState(StateChange);
        
    }

    loadResources(){

        this.dsd.listResources({},"optResources");

    }

    handleButton(event) {
    
        let StateChange = {};

        switch (event.currentTarget.name){
            case 'btnAdd':
                StateChange.mode = 'edit';
                this.state.parent.addResource();
                break;
            case 'btnDelete':
                this.state.parent.removeResource(this.state.seq);
                break;
    
        }
    
        this.setState(StateChange);
    
        event.preventDefault();    

    };


    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let FormData = this.state.FormData;
        FormData[FormFieldName] = FormFieldValue;

        this.setState({"FormData": FormData});

    };

    updateParent(){
        this.state.parent.updateResource(this.state.seq, this.state.FormData);
        return;
    }


    render() {

        if (this.state.isFailed) {
            return <PD.ErrorBox>ERROR - Failed to Load</PD.ErrorBox>
        }


        if (this.state.isLoaded) {

            switch (this.state.mode){
                case 'new':
                case 'edit':
                    return (
                        <PD.Tr>
                            <PD.Td>
                                {(() => {
                                    switch (this.state.mode) {
                                        case "new": 
                                            return <button name="btnAdd" onClick={this.handleButton}><Plus/></button>;
                                        case "edit": 
                                            return <button name="btnDelete" onClick={this.handleButton}><Trash/></button>;
                                    }
                                })()}
                            </PD.Td>
                            {this.state.mode == 'edit' &&
                                <React.Fragment>
                                    <PD.Td>

                                        <PD.Select label='Resource' name='idResource'  onChange={this.handleChange} onBlur={this.updateParent} value={this.state.FormData.idResource}>
                                            {
                                                this.state.optResources.map(
                                                (optResource, posOptResource) => 
                                                    {
                                                        let optModelResource = null;
                                                        if (optResource.type in this.dsd.model.resources){
                                                            optModelResource = this.dsd.model.resources[optResource.type];
                                                        }
                                                        return (
                                                            <option key={posOptResource} value={optResource.id}>{optResource.name}</option>
                                                        )
                                                    }
                                                )
                                            }
                                        </PD.Select>
                                        <PD.Input label='relevance' name="relevance" width="30" value={this.state.FormData.relevance} onChange={this.handleChange} onBlur={this.updateParent}/>
                                        <PD.Input label='URL' name="URL" width="30" value={this.state.FormData.URL} onChange={this.handleChange} onBlur={this.updateParent}/>
                                    </PD.Td>

                                </React.Fragment>
                            }
                        </PD.Tr>
                    );
                    break;
            };
        };

        return null;
    };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <StepResourceRow {...props} navigation={navigation} location={location} params={params} />;
  }
