import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual } from "lodash";

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';
import {cloneObject, getParam} from '../utils/utils';


import dsd from "../classes/clsDSD";

import { Plus, Trash } from 'react-bootstrap-icons';

class StepOptionRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
        };


        this.data = {};

        this.handleChange = this.handleChange.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.updateParent = this.updateParent.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `.`;


    }

    componentDidMount(){

       this.setup();

    }

    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.Option != prevState.Option){
                StateChange.FormData = cloneObject(this.state.Option);
                StateChange.isLoaded = true;
            }
    
            this.setState(StateChange);



        }

    }

    updateParent(){
        this.state.parent.updateOption(this.state.seq, this.state.FormData);
        return;
    }

    setup(){

        let StateChange = {
            FormData: {},

            isLoaded:false,

            Option: null,
            seq: null,

            parent:null,

        }

        StateChange.parent = getParam('parent', this.props);
        StateChange.mode = getParam('mode', this.props);

        StateChange.Option = getParam('Option', this.props);
        if (isNull(StateChange.Option)){
            StateChange.Option = {};
        }
        StateChange.seq = getParam('seq', this.props);

        if (StateChange.mode == null){
            StateChange.mode = 'edit';
        }

        this.setState(StateChange);
        
    }

    handleButton(event) {
    
        let StateChange = {};

        switch (event.currentTarget.name){
            case 'btnAdd':
                StateChange.mode = 'edit';
                this.state.parent.addOption();
                break;
            case 'btnDelete':
                this.state.parent.removeOption(this.state.seq);
                break;
    
        }
    
        this.setState(StateChange);
    
        event.preventDefault();    

    };


    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let FormData = this.state.FormData;
        FormData[FormFieldName] = FormFieldValue;

        this.setState({"FormData": FormData});


    };
    
    render() {

        if (this.state.isLoaded) {

            switch (this.state.mode){
                case 'new':
                case 'edit':
                    return (
                        <PD.Tr>
                            <PD.Td>
                                {(() => {
                                    switch (this.state.mode) {
                                        case "new": 
                                            return <button name="btnAdd" onClick={this.handleButton}><Plus/></button>;
                                        case "edit": 
                                            return <button name="btnDelete" onClick={this.handleButton}><Trash/></button>;
                                    }
                                })()}
                            </PD.Td>
                            {this.state.mode == 'edit' &&
                                <PD.Td>
                                    <PD.Input name="label" width="30" value={this.state.FormData.label} onChange={this.handleChange} onBlur={this.updateParent}/>
                                </PD.Td>
                            }
                        </PD.Tr>
                    );
                    break;
            };
        };

        return null;
    };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <StepOptionRow {...props} navigation={navigation} location={location} params={params} />;
  }
