import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import 'react-tabs/style/react-tabs.css';

import * as PD from '../classes/pdStyle';

import dsd from "../classes/clsDSD";
import {HowToText, getQueryStringParams} from '../utils/utils';


import ListResources from '../components/ListResources';

import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';


class Resources extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };

        this.handleButton = this.handleButton.bind(this);

        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.data = {}

        this.setup();


    }

    componentDidMount(){

    
    }


    componentDidUpdate(prevProps, prevState){

        if (prevProps != this.props){
            this.setup();
        }

    }
    
    setup(){

        this.queryparams = getQueryStringParams(this.props.location.search);

        for (var i in window.Breadcrumb){
            if (window.Breadcrumb[i][0] === 'resources'){
                window.Breadcrumb = window.Breadcrumb.slice(0,i);
                break;
            }
        }
        this.Breadcrumb = JSON.parse(JSON.stringify(window.Breadcrumb));

    }

    handleButton(event) {

        switch (event.target.name){
            case 'btnAdd':
                this.props.navigation(`/resource?mode=new`);
                break;
            default:
                break;          
        };

        event.preventDefault();
      }


      about(){

        return(
                <div>                    

                    <div>
                        <div><strong>Resources</strong></div>
                        <br/>
                    </div>

                </div>
            )

        return <div/>

    }


    setLayoutContent(){

        if (this.context.hasLayout){
            
            let Content = {Index:{}};

            this.context.Layout.setLayoutContent(Content)

            localStorage.setItem("dsdContent",JSON.stringify(Content));
            
        }

        return;

    }


    render() {

        this.setLayoutContent();
        
        return (
            <div>
                <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                <PD.Heading>Resources</PD.Heading>

                <PD.Tabs>

                    <div label="About">                    
                        {this.about()}
                    </div>

                    {this.dsd.user &&
                        <div label="My Resources">
                            <PD.Button value='add a new Resource' name='btnAdd' onClick={this.handleButton}/>

                            <ListResources filters={{}}/>

                        </div>
                    }
                    <div label="Published Resources">
                        <ListResources filters={{status:'published'}} columns={{"owner":true}}/>
                    </div>
                    {this.dsd.user &&
                        <div label="My Deprecated Resources">
                            <ListResources filters={{status:'deprecated'}}/>
                        </div>
                    }
                </PD.Tabs>

            </div>
            )
        };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();

    return <Resources {...props} navigation={navigation} location={location} />;
  }
