import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';


import * as PD from '../classes/pdStyle';
import {HtmlToText, cloneObject, getParam} from '../utils/utils';
import {formatDate, formatText, truncate} from '../utils/utils';


import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";
import ViewLogicalModel from "../components/ViewLogicalModel";
import ViewMetadata from "../components/ViewMetadata";
import ListEntities from "../components/ListEntities";
import ListRelationships from "../components/ListRelationships";

import Version from "../components/Version";
import ListVersions from "../components/ListVersions";
import ViewVersion from "../components/ViewVersion";


import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

import Buffering from "../components/Buffering";

import { json2csv } from 'json-2-csv';

import JSZip from "jszip";


class LogicalModel extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            isLoaded: false,
            loadData:false
        };


        this.data = {};
        this.viz = null;
        this.vizEngine = "dot";

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleVizClick = this.handleVizClick.bind(this);

        this.handleEntityFilters = this.handleEntityFilters.bind(this);
        this.handleRelationshipFilters = this.handleRelationshipFilters.bind(this);

        this.handleExport = this.handleExport.bind(this);

        this.handleAddVersion = this.handleAddVersion.bind(this);
        this.VersionOnActioned = this.VersionOnActioned.bind(this);
        this.loadedVersions = this.loadedVersions.bind(this);





        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/logicalmodels`;


    }

    componentDidMount(){

       this.setup();

    }


    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.vizNodeId){
                let partsVizNodeId = this.state.vizNodeId.split('_');
                switch(partsVizNodeId[0]){
                    case 'concept':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/concept/${partsVizNodeId[1]}`);
                        }
                        break;
                    case 'entity':
                        if (partsVizNodeId.length > 3){
                            switch(partsVizNodeId[2]){
                                case 'structure':
                                    this.props.navigation(`/structure/${partsVizNodeId[3]}`);
                                    break;
                            }
                        }else{
                            if (partsVizNodeId.length > 1){
                                this.props.navigation(`/entity/${partsVizNodeId[1]}`);
                            }
                        }
                        break;
                    case 'structure':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/structure/${partsVizNodeId[1]}`);
                        }
                        break;
        
                }

            }


            if (this.state.mode === 'new'){
                StateChange.isLoaded = true;
            }

            if (this.state.loadData){
                this.loadData(this.state.idLogicalModel);
                StateChange.updateToggle = Number(!this.state.updateToggle)
                StateChange.loadData = false;
            }

            if (this.state.isUpdated){
                StateChange.isUpdated = false;
                StateChange.mode = 'view';
                StateChange.loadData = true;
            }
            else{
                if (this.state.idLogicalModel != prevState.idLogicalModel){
                    if (this.state.idLogicalModel){
                        this.onUpdateNavigation = `/logicalmodel/${this.state.idLogicalModel}`;
                        StateChange.loadData = true;
                    }
                }
            }

            if (this.state.isLoaded) {
    
                StateChange.OfferEdit = false;
    
                if (this.state.LogicalModel){
                    StateChange.FormData = this.state.LogicalModel;
                    if (this.dsd.loggedon){
                        if (this.dsd.user.id == this.state.LogicalModel.idUser){
                            StateChange.OfferEdit = true;
                        }
                    }        
                }
            }


            if (this.state.idLogicalModel){
                for (var i in window.Breadcrumb){
                    if (window.Breadcrumb[i][0] === 'logicalmodel'){
                        window.Breadcrumb = window.Breadcrumb.slice(0,i);
                        break;
                    }
                }
                this.Breadcrumb = JSON.parse(JSON.stringify(window.Breadcrumb));
    
                window.Breadcrumb.push(['logical model',`/logicalmodel/${this.state.idLogicalModel}`]);

                this.FallBackNavigation = `../logicalmodels`;
                this.onUpdateNavigation = this.FallBackNavigation;
    
            };

            this.makeCsv();
            this.makeRdfs();
            this.makeXsd();

            this.setState(StateChange);

        }

    }
    
    loadData(id){

        if (id !== undefined){
            this.dsd.getLogicalModel(id);
            this.dsd.listEntities({idLogicalModel:id});
            this.dsd.listRelationships({idLogicalModel:id});
            this.dsd.listEntityLinks({idLogicalModel:id},"EntityLinks","isLoadedEntityLinks");
        }
        
        return;
        
    };

    setup(){


        let StateChange = {
            id: null,
            isLoaded: false,
            isUpdated: false,
            isFailed: false,
            loadData: false,

            OfferEdit: false,

            FormData: {},
            Controls: {
                vizFullSize :true,
                vizStructures: false
            },

            EntityFilters: {
                status:'in use'
            },
            RelationshipFilters: {
                status:'in use'
            },

            countEntities : null,

            LogicalModel: null,
            Entities: null,
            Relationships: null,
            EntityLinks: null,

            idVersion: null,

            action: null,

            updateToggle: 0,

            countVersions: 0,

            vizNodeId: null,

            csv: null,
            rdfs: {},
            xsds:{}

        }

        StateChange.mode = getParam('mode', this.props);
        StateChange.idLogicalModel = getParam('id', this.props);
        if (StateChange.idLogicalModel){
            StateChange.loadData = true;
        }

        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        StateChange.idVersion = getParam('idVersion', this.props);

        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let Controls = this.state.Controls;
        switch (FormFieldName){
            case 'vizFullSize':
            case 'vizStructures':
                Controls[FormFieldName] = FormFieldValue;        
                this.setState({"Controls": Controls});
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;        
                this.setState({"FormData": FormData});
                break;                
        }
        return;

    };
    
    handleEntityFilters(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let EntityFilters = this.state.EntityFilters;
        EntityFilters[FormFieldName] = FormFieldValue;
        this.setState({"EntityFilters": EntityFilters});

        return;

    };


    handleRelationshipFilters(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let RelationshipFilters = this.state.RelationshipFilters;
        RelationshipFilters[FormFieldName] = FormFieldValue;
        this.setState({"RelationshipFilters": RelationshipFilters});

        return;

    };


    handleSubmit(event) {

        switch (event.target.name){
            case 'btnCancel':
                switch (this.state.mode){
                    case 'new':
                        this.props.navigation(this.FallBackNavigation);
                        break;
                    case 'edit':
                        this.loadData(this.state.idLogicalModel);
                        this.setState({mode: 'view'});
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };

                break;
            case 'btnSave':
            default:

                this.setState({
                    isUpdated: false,
                    isFailed: false,
                    invalidFields: false
                })
        

                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addLogicalModel(this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editLogicalModel(this.state.idLogicalModel,this.state.FormData);
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };
    
        };

        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
            case 'btnAddEntity':
                this.props.navigation(`/logicalmodel/${this.state.idLogicalModel}/entity?mode=new`);
                break;
            case 'btnAddRelationship':
                this.props.navigation(`/logicalmodel/${this.state.idLogicalModel}/relationship?mode=new`);
                break;
        }

        this.setState(StateChange);

        event.preventDefault();
    };

    handleAddVersion(event) {

        this.setState({newVersion:true});

    }


    handleVizClick(event) {

        let StateChange = {};

        let idNode = null;
        if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }
        

        StateChange.vizNodeId = idNode;

        this.setState(StateChange);

        event.preventDefault();

    };


    async handleExport(event){

        const zip = new JSZip();
        
        let LogicalModel = cloneObject(this.state.LogicalModel);
// remove deprecated items
        let Entities = [];
        if (LogicalModel.Entities){
            for (let pos=0; pos<LogicalModel.Entities.length; pos++){
                let Entity = LogicalModel.Entities[pos];
                switch (Entity.status){
                    case 'deprecated':
                        break;
                    default:
                        Entities.push(Entity);
                        break;
                }
                
            }
        }
        LogicalModel.Entities = Entities;

        let Relationships = [];
        if (LogicalModel.Relationships){
            for (let pos=0; pos<LogicalModel.Relationships.length; pos++){
                let Relationship = LogicalModel.Relationships[pos];
                switch (Relationship.status){
                    case 'deprecated':
                        break;
                    default:
                        Relationships.push(Relationship);
                        break;
                }
                
            }
        }
        LogicalModel.Relationships = Relationships;

        let strLogicalModel = JSON.stringify(LogicalModel, null, 4);

        zip.file(`logicalmodel_${this.state.idLogicalModel}.json`, strLogicalModel);

        zip.file(`logicalmodel_${this.state.idLogicalModel}.dot`,this.viz.dot);

        let listEntities = {};
        for (let posE=0; posE<LogicalModel.Entities.length; posE++){
            let Entity = cloneObject(LogicalModel.Entities[posE]);

            Entity.LogicalModel = LogicalModel;
            listEntities[Entity._id] = Entity;

            let dotEntity = this.dsd.vizEntity({Entity:Entity, EntityLinks: this.state.EntityLinks}).dot;
            zip.file(`entity_${Entity._id}.dot`,dotEntity);

        }

        for (let idEntity in listEntities){
            let Entity = listEntities[idEntity];
            delete Entity.LogicalModel.Entities;
            delete Entity.LogicalModel.Relationships;
            
            let strEntity = JSON.stringify(Entity,null,4);
            zip.file(`entity_${Entity._id}.json`, strEntity);
        }


        const rdfs = this.makeRdfs();
        for (let rdfFilename in rdfs){
            zip.file(`logicalmodel_${this.state.idLogicalModel}/rdfs/${rdfFilename}`,rdfs[rdfFilename]);            
        }

        const xsds = this.makeXsd();
        for (let xsdFilename in xsds){
            zip.file(`logicalmodel_${this.state.idLogicalModel}/xsd/${xsdFilename}`,xsds[xsdFilename]);            
        }

        const zipData = await zip.generateAsync({
            type: "blob",
            streamFiles: true,
        });



        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(zipData);
        link.download = `logicalmodel_${this.state.idLogicalModel}.zip`;
        link.click();

    }


    makeRdfs(format='ttl'){

        if (!this.state.LogicalModel){
            return null;
        }

        let LogicalModel = cloneObject(this.state.LogicalModel);

        let prefix = ""
        let namespace = "http://example.com/";

        if (LogicalModel.namespace){
            namespace = LogicalModel.namespace;
        }
        if (LogicalModel.prefix){
            prefix = LogicalModel.prefix
        }

// set prefix and namespace of other models that are used

        let Prefixes = {};

        let Concepts = {};
        let Links = {};
        let Structures = {};

        for (let posE=0;posE<LogicalModel.Entities.length; posE++){
            let Entity = LogicalModel.Entities[posE];
            if (Entity.Concepts){
                for (let posC=0;posC<Entity.Concepts.length; posC++){
                    if (Entity.Concepts[posC].Concept){
                        let Concept = Entity.Concepts[posC].Concept;
                        if (Concept.ConceptModel){
                            this.makeRdfPrefix(Prefixes, Concept.ConceptModel);
                            Concepts[Concept._id] = Concept;
                        }
                    }
                }
            }

            if (Entity.Attributes){
                for (let posA=0;posA<Entity.Attributes.length; posA++){
                    let Attribute = Entity.Attributes[posA];
                    if (Attribute.Structure){
                        let Structure = Attribute.Structure;
                        let Prefix = this.makeRdfPrefix(Prefixes, Structure.DataDictionary);
//                        Structures[Structure._id] = Structure;
//                        Structure.nodeName = `${Prefix}:${this.makeRdfNode(Structure.name)}`;
                    }
                }    
            }
        }

        for (let posR=0; posR<LogicalModel.Relationships.length; posR++){
            let Relationship = LogicalModel.Relationships[posR];
            if (Relationship.Link){
                let Link = Relationship.Link
                if (Link.ConceptModel){
                    let Prefix = this.makeRdfPrefix(Prefixes, Link.ConceptModel);
                    this.makeRdfPrefix(Prefixes, Link.ConceptModel);
                    Links[Link._id] = Link;
                    Link.nodeName = `${Prefix}:${this.makeRdfNode(Link.name)}`;

                }
            }
        } 


        let rdfs = '';
        let rdfsStructures = '';

        switch (format){
            case 'ttl':
                rdfs += `
@prefix owl: <http://www.w3.org/2002/07/owl#> .
@prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .
@prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#> .
@prefix xml: <http://www.w3.org/XML/1998/namespace> .
@prefix xsd: <http://www.w3.org/2001/XMLSchema#> .
@prefix dct: <http://purl.org/dc/terms/> .
`


                rdfs += `
@prefix ${prefix}: <${namespace}> .
@prefix : <${namespace}> .`


                for (let idOtherPrefix in Prefixes){
                    let OtherPrefix = Prefixes[idOtherPrefix];
                    rdfs += `
@prefix ${OtherPrefix.prefix}: <${OtherPrefix.namespace}> .`                   
                }

                rdfs += `
`


                rdfs += `
    :
        a owl:Ontology;
        owl:versionInfo "1.0";
        rdfs:label "${LogicalModel.name}"@en;`

                if (LogicalModel.description){
                    rdfs += `
        rdfs:comment "${LogicalModel.description}"@en;`
                }

                rdfs += `
        .                
`

                for (let posE=0; posE<LogicalModel.Entities.length;posE++){
                    let Entity = LogicalModel.Entities[posE];
                    switch (Entity.status){
                        case 'deprecated':
                            break;
                        default:
                            rdfs += `
    :${this.makeRdfNode(Entity.name)}
        a owl:Class, rdfs:Class;
        rdfs:label "${Entity.name}"@en;`;

                            if (Entity.description){
                                rdfs += `
        rdfs:comment """${Entity.description}"""@en;`;
                            }


                            if (Entity.Concepts){
                                for (let posC=0;posC<Entity.Concepts.length;posC++){
                                    let Concept = Entity.Concepts[posC].Concept;
                                    if (Concept){
                                        if (Concept.ConceptModel){
                                            if (Concept.ConceptModel._id in Prefixes){

                                                rdfs += `
        rdfs:subClassOf ${Prefixes[Concept.ConceptModel._id].prefix}:${this.makeRdfNode(Concept.name)} ;`;
                                            }
                                        }
                                    }
                                }
                            }

                            rdfs += `
        .
`                        

                            if (Entity.Attributes){
                                for (let posA=0;posA<Entity.Attributes.length;posA++){
                                    let Attribute = Entity.Attributes[posA];
                                    if (Attribute){
                                        if (Attribute.name){


                                            rdfs += `
        :${this.makeRdfNode(Attribute._id)}
            a rdf:Property;
            rdfs:label "${Attribute.name}"@en;`;

                                            if (Attribute.description){
                                                rdfs += `
            rdfs:comment """${Attribute.description}"""@en;`;
                                            }

                                            rdfs += `
            rdfs:domain :${this.makeRdfNode(Entity.name)} ;`;



                                            switch (Attribute.type){
                                                case 'structure':
                                                    if (Attribute.Structure){
                                                        rdfsStructures += this.makeRdfStructure(Attribute.Structure, Structures, Prefixes );
                                                        if (Attribute.Structure._id in Structures){
                                                            rdfs += `
            rdfs:range ${Structures[Attribute.Structure._id].nodeName} ;`;
                                                        }
                                                    }

                                                    break;
                                                default:
                                                    if (Attribute.dataType){
                                                        if (Attribute.dataType in this.dsd.config.DataTypes){
                                                            rdfs += `
            rdfs:range ${this.dsd.config.DataTypes[Attribute.dataType].type} ;`;
                                                        }
                                                    }
                                                    break
                                            }

                                            rdfs +=`
            .
    `

                                        }
                                    }
                                }
                            }



                    }
                }


                rdfs += rdfsStructures;


                for (let posR=0; posR<LogicalModel.Relationships.length;posR++){
                    let Relationship = LogicalModel.Relationships[posR];
                    switch (Relationship.status){
                        case 'deprecated':
                            break;
                        default:
                            rdfs += `
    :${this.makeRdfNode(Relationship._id)}
        a owl:ObjectProperty, rdf:Property;`;
                            if (Relationship.Link){
                                switch (Relationship.inverse){
                                    case true:
                                        rdfs += `
        rdfs:subPropertyOf [ owl:inverseOf ${Relationship.Link.nodeName}; ] ;
        rdfs:label "${Relationship.Link.inverseLabel}"@en;`
                                        break;
                                    default:
                                        rdfs += `
        rdfs:subPropertyOf ${Relationship.Link.nodeName};
        rdfs:label "${Relationship.Link.label}"@en;`
                                        break;
                                }
                            }
                            if (Relationship.EntityFrom){
                                rdfs += `
        rdfs:domain :${this.makeRdfNode(Relationship.EntityFrom.name)};`
                            }
                            if (Relationship.EntityTo){
                                rdfs += `
        rdfs:range :${this.makeRdfNode(Relationship.EntityTo.name)} ;`
                            }

                            if (Relationship.description){
                                rdfs += `                            
        rdfs:comment """${Relationship.description}"""@en;`
                            }

                            rdfs += `
        .
    `

                    }
                }



                
        }

        let rdfsFilename = `${this.makeRdfNode(LogicalModel.name)}.ttl`;

        let rdfsObject = {};
        rdfsObject[rdfsFilename] = rdfs;

        this.setState({rdfs:rdfsObject});

        return rdfsObject;

    }



    makeRdfStructure(Structure, Structures, Prefixes ){

        let rdfs = '';
        let rdfsSubStrucures = '';


        if (!Structure){
            return rdfs;
        }

        if (Structure._id in Structures){
            return rdfs;
        }

        Structures[Structure._id] = Structure;

        switch (Structure.status){
            case 'deprecated':
                break;
            default:

                let Prefix = ''
                if (Structure.DataDictionary._id in Prefixes){
                    Prefix = Prefixes[Structure.DataDictionary._id].prefix;
                }

                Structure.nodeName = `${Prefix}:${this.makeRdfNode(Structure.name)}`;

                rdfs += `
    ${Structure.nodeName}
        a rdfs:Class;
        rdfs:label "${Structure.name}"@en;`;

                if (Structure.description){
                    rdfs += `
        rdfs:comment """${Structure.description}"""@en;`;
                }
            
                rdfs += `
        .
`                        
        
                if (Structure.Attributes){
                    for (let posA=0;posA<Structure.Attributes.length;posA++){
                        let Attribute = Structure.Attributes[posA];
                        if (Attribute){
                            if (Attribute.name){

                                rdfs += `
        ${Prefix}:${this.makeRdfNode(Attribute._id)}
            a rdf:Property;
            rdfs:label "${Attribute.name}"@en;`;

                                if (Attribute.description){
                                    rdfs += `
            rdfs:comment """${Attribute.description}"""@en;`;
                                }

                                rdfs += `
            rdfs:domain ${Structure.nodeName} ;`;


                                switch (Attribute.type){
                                    case 'structure':
                                        if (Attribute.Structure){
                                            if (!(Attribute.Structure._id in Structures)){
                                                rdfsSubStrucures += this.makeRdfStructure(Attribute.Structure, Structures, Prefixes );
                                            }
                                            if (Attribute.Structure._id in Structures){
                                                rdfs += `
            rdfs:range ${Structures[Attribute.Structure._id].nodeName} ;`;
                                            }
                                        }

                                        break;
                                    default:
                                        if (Attribute.dataType){
                                            if (Attribute.dataType in this.dsd.config.DataTypes){
                                                rdfs += `
            rdfs:range ${this.dsd.config.DataTypes[Attribute.dataType].type} ;`;
                                            }
                                        }
                                        break
                                }

                                rdfs +=`
            .
`

                            }
                        }
                    }
                }
        }


        rdfs += rdfsSubStrucures;

        return rdfs; 

    }

    makeRdfPrefix(Prefixes = {}, Model = null){

        if (!Model){
            return;
        }

        if (Model._id in Prefixes){
            return Prefixes[Model._id].prefix;
        }

        let prefix = `ns${Object.keys(Prefixes).length +1}`;
        let namespace = Model._id;

        if (Model.namespace){
            namespace = Model.namespace;
        }
        if (Model.prefix){
            prefix =Model.prefix
        }

        Prefixes[Model._id] = {
            prefix:prefix,
            namespace:namespace,
            id: Model._id
        }

        return prefix;

    }

    makeRdfNode(name){

        if (name === undefined){
            return null;
        }

        let node = name;
        node = node.replace(/ /g,'_');
        node = node.replace('/{/g','_');
        node = node.replace(/}/g,'_');
        node = node.replace(/\[/g,'_');
        node = node.replace(/\]/g,'_');

//        node = node.replace(/?/g,'');

        return node;
    }




    makeCsv(){

        if (!this.state.LogicalModel){
            return null;
        }

        if (!this.state.Entities){
            return null;
        }
    

        let rows = [];

        for (let posC=0;posC<this.state.Entities.length;posC++){
            const Entity = this.state.Entities[posC];
            let row = {
                Entity:'',
                Label:'',
                Description:'',
                AttributeName:'',
                DataType:'',
                Structure:'',
                Occurs:'',
                Required:''
            };

            let rowPrefix = '';
            if (this.state.LogicalModel.prefix){
                rowPrefix = `${this.state.LogicalModel.prefix}:`
            }
            row.Entity = `${rowPrefix}${Entity.name.replace(/ /g,"_")}`;
            row.Label = Entity.name;
            row.Description = HtmlToText(Entity.description);

            rows.push(row);

            for (let posA=0;posA<Entity.Attributes.length;posA++){
                let Attribute = Entity.Attributes[posA];

                let row = {
                    Entity:'',
                    Label:'',
                    Description:'',
                    AttributeName:'',
                    DataType:'',
                    Structure:'',
                    Occurs:'',
                    Required:''
                };
    


                if (Attribute.type){                    
                    row.AttributeName = Attribute.name;
                    row.DataType = '';
                    row.Structure = '';
                    row.Occurs = '';
                    row.Required = '';
                    switch (Attribute.type){
                        case 'structure':

                            let rowPrefix = '';
                            if (Attribute.Structure){
                                if (Attribute.Structure.DataDictionary){
                                    if (Attribute.Structure.DataDictionary.prefix){
                                        rowPrefix = `${Attribute.Structure.DataDictionary.prefix}:`;
                                    }
                                }
                                row.Structure = `${rowPrefix}${Attribute.Structure.name.replace(/ /g,"_")}`;
                            }
                            break;
                        default:
                            row.DataType = Attribute.dataType;
                            break;
                    }
                    if (Attribute.occurs){
                        row.Occurs = Attribute.occurs;
                    }
                    if (Attribute.required){
                        row.Required = Attribute.required;
                    }
                    rows.push(row);
                }
            }
        }
        
        const options = [];
        const csv = json2csv(rows)
        this.setState({csv:csv});

        return true;

    };



    makeXsd(){

        if (!this.state.LogicalModel){
            return null;
        }

        if (!this.state.Entities){
            return null;
        }

        let LogicalModel = cloneObject(this.state.LogicalModel);

        let Models = {};

// set the namespace for the Logical Model 
        this.makeXsdModel(Models, LogicalModel);

        let Concepts = {};
        let Links = {};
        let Structures = {};

        // create a Schema object for the Logical Model
// schema object has an xsd property which contains the xsd schema

let Schemas = {};
const Schema = this.getXsdSchema(LogicalModel, Schemas, Models);


// find the structures that are used and set up namespaces for their parent Dictionaries
        for (let posE=0;posE<LogicalModel.Entities.length; posE++){
            let Entity = LogicalModel.Entities[posE];
            if (Entity.Attributes){
                for (let posA=0;posA<Entity.Attributes.length; posA++){
                    let Attribute = Entity.Attributes[posA];
                    if (Attribute.Structure){
                        let Structure = Attribute.Structure;
                        let Model = this.makeXsdModel(Models, Structure.DataDictionary);
                        Structures[Structure._id] = Structure;
                        Structure.nodeName = `${this.makeXsdNode(Structure.name)}`;

                        this.getXsdSchema(Attribute.Structure.DataDictionary, Schemas, Models)                

                    }
                }
    
            }
        }

// find the links that are used and set up namespaces for their parent Concpet Models
        for (let posR=0; posR<LogicalModel.Relationships.length; posR++){
            let Relationship = LogicalModel.Relationships[posR];
            if (Relationship.Link){
                let Link = Relationship.Link
                if (Link.ConceptModel){
                    let Namespace = this.makeXsdModel(Models, Link.ConceptModel);
                    Links[Link._id] = Link;
                    Link.nodeName = `${this.makeXsdNode(Link.name)}`;
                }
            }
        } 


        if (Schema){
            for (let namespace in Schemas){
                let ImportSchema = Schemas[namespace];
                Schema.xsd.att(`xmlns:${ImportSchema.alias}`, ImportSchema.namespace);

                if (ImportSchema.namespace != Schema.namespace){
                    let xsdImport = Schema.xsd.ele('xsd:import');
                    xsdImport.att('namespace', ImportSchema.namespace);
                    xsdImport.att('schemaLocation', ImportSchema.location);
                }
            }
            for (let posE=0;posE<this.state.Entities.length;posE++){
                const Entity = this.state.Entities[posE];
                this.makeXsdType(Entity, Schema, Schemas, Models);
            }
        }

// create schemas for each referenced Dictionary

        let StructureIds = {}
        for (let idStructure in Structures){
            const Structure = Structures[idStructure];
            this.makeXsdStructure(Structure, Schemas, Models, StructureIds);
        }


// put all the schemas together into a single text document
        let xsds = {};
        for (let namespace in Schemas){
            xsds[Schemas[namespace].location] = Schemas[namespace].xsd.end({ prettyPrint: true })
//            xsd += Schemas[namespace].location + "\n";
//            xsd += Schemas[namespace].xsd.end({ prettyPrint: true }) + "\n\n";
        }


        if (xsds) {
            this.setState({xsds:xsds});
        }

        return xsds;

    };


    makeXsdStructure(Structure, Schemas, Models, Ids={}){

        if (Structure._id in Ids){
            return;
        }

        Ids[Structure._id] = Structure;

        if (Structure.DataDictionary){
            let Schema = this.getXsdSchema(Structure.DataDictionary, Schemas, Models);
            if (Schema){
                this.makeXsdType(Structure, Schema, Schemas, Models);
            }
        }
        
        if (Structure.Attributes){
            for (let posA=0; posA<Structure.Attributes.length;posA++){
                const Attribute = Structure.Attributes[posA];
                if (Attribute.Structure){
                    this.makeXsdStructure(Attribute.Structure, Schemas, Models, Ids);
                }
            }
        }

    }

    makeXsdType(Item, Schema, Schemas, Models){

        if (!Item){
            return false;
        }
        if (!Item.name){
            return false;
        }
        
        let hasAttributes = false;

        if (Item.Attributes){
            for (let posA=0;posA<Item.Attributes.length;posA++){
                let Attribute = Item.Attributes[posA];
                if (Attribute.name){                    
                    hasAttributes = true;
                }
            }
        }

        if (!hasAttributes){
            let xsdSimpleType = Schema.xsd.ele('xsd:simpleType');
            xsdSimpleType.att('name', this.makeXsdNode(Item.name));
            let xsdRestriction = xsdSimpleType.ele('xsd:restriction');
            xsdRestriction.att('base', 'xsd:string');
            let xsdMaxLength = xsdRestriction.ele('xsd:maxLength');
            xsdMaxLength.att('value','0');
            return xsdSimpleType;
        }

        let xsdComplexType = Schema.xsd.ele('xsd:complexType');
        xsdComplexType.att('name', this.makeXsdNode(Item.name));

        let xsdSequence = xsdComplexType.ele('xsd:sequence');

        for (let posA=0;posA<Item.Attributes.length;posA++){
            let Attribute = Item.Attributes[posA];


            let xsdElement = xsdSequence.ele('xsd:element');
            xsdElement.att('name', this.makeXsdNode(Attribute.name));
            
            switch (Attribute.required){
                case 'yes':
                case true:
                    break;
                default:
                    xsdElement.att('minOccurs', '0');
                    break;
            }
            switch (Attribute.occurs){
                case 'many':
                    xsdElement.att('maxOccurs', 'unbounded');
                    break;
            }

            switch (Attribute.type){
                case 'structure':

                    if (Attribute.Structure){
                        if (Attribute.Structure.DataDictionary){
                            let ImportSchema = this.getXsdSchema(Attribute.Structure.DataDictionary, Schemas, Models)
                            let ImportSchemaAlias = '';
                            if (ImportSchema.alias != Schema.alias){
                                ImportSchemaAlias = `${ImportSchema.alias}:`;
                            }
                            if (ImportSchema) {
                                xsdElement.att('type',`${ImportSchemaAlias}${this.makeXsdNode(Attribute.Structure.name)}`);
                            }
                        }
                    }
                    break;
                default:
                    if (Attribute.dataType){
                        if (Attribute.dataType in this.dsd.config.DataTypes){
                            xsdElement.att('type',this.dsd.config.DataTypes[Attribute.dataType].type);
                        }
                    }

                    break;
            }
        }

        return true;

    }


    makeXsdModel(Models = {}, Model = null){

        if (!Model){
            return;
        }

        if (Model._id in Models){
            return Models[Model._id];
        }

        let namespace = null;

        if (Model.namespace){
            namespace = Model.namespace;
        }

        Models[Model._id] = {
            namespace:namespace,
            id: Model._id
        }

        return Models[Model._id];

    }


    makeXsdNode(name){
        let node = name;
        node = node.replace(/ /g,'_');
        node = node.replace('/{/g','_');
        node = node.replace(/}/g,'_');
        node = node.replace(/\[/g,'_');
        node = node.replace(/\]/g,'_');
        node = node.replace(/\?/g,'');


        return node;
    }

    getXsdSchema(Model, Schemas, Models){

        if (!Model){
            return false;
        }
        if (!(Model._id in Models)){
            return false;
        }
        if (Model.namespace in Schemas){
            return Schemas[Model.namespace];
        }

        let Schema = {};
        Schema.namespace = Model.namespace;
        Schema.alias = `ns${Object.keys(Schemas).length +1}`;

        if (Model.prefix){
            Schema.alias = Model.prefix;
        }

        Schema.location = `${this.makeXsdNode(Model.name)}.xsd`;

        const { create } = require('xmlbuilder2');
        const nsXSD = 'http://www.w3.org/2001/XMLSchema';


        let options = {};
        options.encoding = 'UTF-8';        

        Schema.xsd = create(options).ele(nsXSD,'xsd:schema');
        if (Schema.namespace){
            Schema.xsd.att('xmlns', Schema.namespace);
            Schema.xsd.att('targetNamespace', Schema.namespace);
        }        

        Schemas[Model.namespace] = Schema;

        return Schema;

    }

    log(){

        if (!this.state.isLoaded){
            return null;
        }

        if (!this.state.LogicalModel){
            return null;
        }

        let arrLog = [];
        for (let pos=0;pos<this.state.LogicalModel.Entities.length;pos++){
            let Entity = this.state.LogicalModel.Entities[pos];
            if (Entity.createdAt){
                let LogEntry = {};
                LogEntry.date = Entity.createdAt;
                LogEntry.action = 'created';
                LogEntry.subjectType = 'Entity';
                LogEntry.Subject = Entity;
                LogEntry.name = Entity.name;
                LogEntry.link = `/entity/${Entity._id}`;
                arrLog = this.insertLog(arrLog, LogEntry);
            }
            if (Entity.Revisions){
                for (let posR=0; posR<Entity.Revisions.length;posR++){
                    let Revision = Entity.Revisions[posR];
                    if (Revision.report){
                        let LogEntry = {};
                        LogEntry.date = Revision.createdAt;
                        LogEntry.action = 'revised';
                        LogEntry.subjectType = 'Entity';
                        LogEntry.Subject = Entity;
                        LogEntry.name = Entity.name;
                        LogEntry.link = `/entity/${Entity._id}`;
                        LogEntry.Revision = Revision
                        LogEntry.description = Revision.description;
                        arrLog = this.insertLog(arrLog, LogEntry);
                    }
                }
            }
        }

        for (let pos=0;pos<this.state.LogicalModel.Relationships.length;pos++){
            let Relationship = this.state.LogicalModel.Relationships[pos];


            let label = "-";
            try {
              if (Relationship.Link){
                label = `${Relationship.Link.ConceptFrom.name} ${Relationship.Link.label} ${Relationship.Link.ConceptTo.name}`;
                if (Relationship.inverse){
                    label = `${Relationship.Link.ConceptTo.name} ${Relationship.Link.inverseLabel} ${Relationship.Link.ConceptFrom.name}`;
                }
              }
            } catch { };
          

            if (Relationship.createdAt){
                let LogEntry = {};
                LogEntry.date = Relationship.createdAt;
                LogEntry.action = 'created';
                LogEntry.subjectType = 'Relationship';
                LogEntry.Subject = Relationship;
                LogEntry.name = label;
                LogEntry.link = `/relationship/${Relationship._id}`;
                arrLog = this.insertLog(arrLog, LogEntry);
            }
            if (Relationship.Revisions){
                for (let posR=0; posR<Relationship.Revisions.length;posR++){
                    let Revision = Relationship.Revisions[posR];
                    if (Revision.report){
                        let LogEntry = {};
                        LogEntry.date = Revision.createdAt;
                        LogEntry.action = 'revised';
                        LogEntry.subjectType = 'Relationship';
                        LogEntry.Subject = Relationship;
                        LogEntry.name = label;
                        LogEntry.link = `/relationship/${Relationship._id}`;
                        LogEntry.Revision = Revision
                        LogEntry.description = Revision.description;
                        arrLog = this.insertLog(arrLog, LogEntry);                       
                    }
                }
            }
        }

        if (!arrLog.length){
            return null;
        }

        return (
            <PD.Table>
                <PD.Thead>
                    <PD.Tr>
                        <PD.Th>on</PD.Th>
                        <PD.Th>what</PD.Th>
                        <PD.Th>name</PD.Th>
                        <PD.Th>change</PD.Th>
                        <PD.Th>description</PD.Th>
                    </PD.Tr>
                </PD.Thead>

                <PD.Tbody>
                    {
                        arrLog.map(
                        (entry, pos) => 
                            {
                                return (
                                    <PD.Tr>
                                        <PD.Td>{formatDate(entry.date,'dmy')}</PD.Td>
                                        <PD.Td>{entry.subjectType}</PD.Td>
                                        <PD.Td>
                                            <PD.PdNavLink to={entry.link} >{entry.name}</PD.PdNavLink>
                                        </PD.Td>
                                        <PD.Td>{entry.action}</PD.Td>
                                        <PD.Td>{formatText(truncate(HtmlToText(entry.description), 400))}</PD.Td>
                                    </PD.Tr>
                                )
                            }
                        )
                    }
                </PD.Tbody>
            </PD.Table>

        )


    }

    insertLog(array, entry){

        let boolInserted = false;

        let newArray = [];

        for (let pos=0; pos<array.length;pos++){
            if (!boolInserted){
                if (entry.date < array[pos].date){
                    newArray.push(entry);
                    boolInserted = true;
                }
            }
            newArray.push(array[pos]);
        }

        if (!boolInserted){
            newArray.push(entry);
        }

        return newArray;

    }


    versions(){

        if (!this.state.isLoaded){
            return null;
        }

        if (!this.state.LogicalModel){
            return null;
        }

        let newVersion = false;
        let showAdd = false;

        if (this.state.OfferEdit){
            showAdd = true;
        }
        if (this.state.newVersion){
            showAdd = false;
            newVersion = true;
        }

        return (
            <div>
                {showAdd &&
                    <div style={{display:"flex"}}>
                        <PD.Button name='btnAdd' value='add a Named Version' onClick={this.handleAddVersion}/>
                    </div>
                }
                
                {newVersion &&
                    <Version mode='new' idAbout={this.state.idLogicalModel} type='logicalmodel' document={this.state.LogicalModel} onActioned={this.VersionOnActioned}/>
                }
                <ListVersions key={this.state.updateToggle} OfferEdit={this.state.OfferEdit} filters={{idAbout:this.state.idLogicalModel}} onLoad={this.loadedVersions}/>

            </div>
        )
    }

    VersionOnActioned(){
       this.setState(
        {
            newVersion:false,
            updateToggle: Number(!this.state.updateToggle)
        })
    }

    loadedVersions(count){

        let StateChange = {};

        StateChange.countVersions = 0;
        if (count){
            StateChange.countVersions = count;
        }

        this.setState(StateChange);

    }


    visualise(){
    
        if (!this.state.LogicalModel){
            return <Buffering/>;
        }

        const styles = {
            scrollableDiv: {
                    backgroundColor: "#f1f1f1",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    height: "800px",
                    width: "800px",
                    overflow: "auto",
                    margin: "20px",
                    textAlign: "justify",
                    padding: "20px"
            }
          };


        let vizoptions = {}
        vizoptions.engine = this.vizEngine;
        vizoptions.fit = false;
        vizoptions.width = null;
        vizoptions.height = null;
        vizoptions.zoom = false;
        vizoptions.scale = 1;

        let styleDiv = styles.scrollableDiv;
        if (this.state.Controls.hasOwnProperty('vizFullSize')){
            if (this.state.Controls.vizFullSize){
                styleDiv = null;
            }
        }

        try {

            return(
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <PD.Checkbox label="Full Screen" name="vizFullSize" value={this.state.Controls.vizFullSize} onChange={this.handleChange}/>                    
                                </td>
                                <td>
                                    <PD.Checkbox label="Structures?" name="vizStructures" value={this.state.Controls.vizStructures} onChange={this.handleChange}/>                    
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div name="divViz" style={styleDiv} onClick={this.handleVizClick}>
                        <Graphviz dot={this.viz.dot} options={vizoptions}/>
                    </div>

                </div>
            );
          } catch (error) {
            return <div/>
          }
    };


    setLayoutContent(){

        if (this.context.hasLayout){
            
            let Content = {Index:{}};

            if (this.state.LogicalModel){
                Content.Index.title = this.state.LogicalModel.name;
                Content.Index.link = `/logicalmodel/${this.state.LogicalModel._id}`

                Content.Index.Sections = [];

                if (this.state.Entities){
                    let Section = {title:"Entities"}
                    Section.Items = [];
                    for (let posI=0;posI<this.state.Entities.length; posI++){
                        let Entity = this.state.Entities[posI];

                        switch (Entity.status){
                            case 'deprecated':
                                break;
                            default:

                                let Item = {}
                                Item.label = Entity.name;
                                Item.link = `/entity/${Entity._id}`
                                Section.Items.push(Item);
                                break;
                        }
                    }
                    Content.Index.Sections.push(Section);
                }

                if (this.state.Relationships){
                    let Section = {title:"Relationships"}
                    Section.Items = [];
                    for (let posI=0;posI<this.state.Relationships.length; posI++){
                        let Relationship = this.state.Relationships[posI];

                        switch (Relationship.status){
                            case 'deprecated':
                                break;
                            default:

                                let Item = {}
                                Item.label = '-';
                                if (Relationship.Link){
                                    Item.label = `${Relationship.EntityFrom.name} ${Relationship.Link.label} ${Relationship.EntityTo.name}`;
                                    if (Relationship.inverse){
                                        Item.label = `${Relationship.EntityFrom.name} ${Relationship.Link.inverseLabel} ${Relationship.EntityTo.name}`;
                                    }
                                }

                                Item.link = `/relationship/${Relationship._id}`
                                Section.Items.push(Item);
                                break;
                        }
                    }
                    Content.Index.Sections.push(Section);
                }



            }
            this.context.Layout.setLayoutContent(Content)

            localStorage.setItem("dsdContent",JSON.stringify(Content));
            

        }

        return;

    }


    showError(){


        if (this.state.invalidFields){
            return(
                <>
                    {
                        Object.keys(this.state.invalidFields).map(
                        (field, pos) => 
                            {
                                let error = this.state.invalidFields[field].error;
                                return <p>{field}:{error}</p>
                            }
                        )
                    }
                </>
            )
        }

        return "ERROR - Failed to Update";

    }


    render() {


        if (this.state.isUpdated){
            switch (this.state.mode){
                case 'remove':
                    this.props.navigation(this.FallBackNavigation);
                    return null;
                default:
                    break;
            };
        };

        if (this.state.isLoaded) {

            this.setLayoutContent();

            if (this.state.idVersion){
                return(
                    <ViewVersion idVersion={this.state.idVersion} type='logicalmodel'/>
                )
            }

            switch (this.state.mode){
                case 'new':
                case 'edit':

                    return (
                    <div>

                        <PD.Heading>{this.state.mode} Logical Model</PD.Heading>
    
                        {this.state.isFailed &&
                            <PD.ErrorBox>{this.showError()}</PD.ErrorBox>
                        }
    
                        <form> 
                            <PD.Input label="Name" width="60" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>

                            <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>

                            <PD.Input label="Namespace" width="30" name="namespace" value={this.state.FormData.namespace} onChange={this.handleChange}/>
                            <PD.Input label="Default Prefix" width="10" name="prefix" value={this.state.FormData.prefix} onChange={this.handleChange}/>

                            <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>

                            <PD.Select label="Status" name='status'  onChange={this.handleChange} value={this.state.FormData.status}>
                                {
                                    this.dsd.config.ComponentStatuses.map(
                                        (status, posStatus) => 
                                            {
                                                return(
                                                    <option key={posStatus} value={status}>{status}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>

                            <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave"value="Save" />    
                            <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" />    
                        </form>
    
                    </div>    
                    );
                case 'remove':
                    return (
                        <div>
                            <PD.Heading>{this.state.mode} Logical Model</PD.Heading>

                            {this.state.isFailed &&
                                <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                            }

                            <PD.Button onClick={this.handleSubmit} type="warning" value="confirm remove?" />
                            <ViewLogicalModel json={this.state.LogicalModel}/>
                        </div>
                    )
                case 'view':
                default:

                    if (!this.state.LogicalModel){
                        return <div/>
                    }
                    
//                    const countItems = this.state.countItems == null ? "Loading..." : this.state.countItems;

                    this.viz = this.dsd.vizLogicalModel({LogicalModel:this.state.LogicalModel}, this.state.Controls, this.vizEngine );

                    return (
                        <div>
                            <div>
                                <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                <PD.Heading>Logical Model:{this.state.LogicalModel.name}</PD.Heading>
                            </div>

                            {this.state.OfferEdit &&
                                <span style={{paddingRight:10}}>
                                        <PD.Button onClick={this.handleMode} type="submit" value="edit" />                        
                                </span>
                            }

                            <PD.Button onClick={this.handleExport} name="btnExport" value="export" />

                            <ViewLogicalModel LogicalModel={this.state.LogicalModel}/>

                            <br/>

                            <PD.Tabs>

                                <div label='Visualise'>
                                    <table>
                                        <tr>
                                            <td style={{verticalAlign: 'top', width: 'auto'}}>
                                                {this.visualise()}
                                            </td>
                                        </tr>
                                    </table>
                                </div>



                                <div label='Entities'>
                                    <div style={{display:"flex"}}>
                                        {this.state.OfferEdit && 
                                            <PD.Button name='btnAddEntity' value='add a new Entity' onClick={this.handleButton}/>
                                        }
                                        <fieldset style={{display:"flex"}}>
                                            <legend>filters</legend>
                                            <PD.Select label='status' name='status'  onChange={this.handleEntityFilters} value={this.state.EntityFilters.status}>
                                                {
                                                    this.dsd.config.EntityStatuses.map(
                                                        (status, posStatus) => 
                                                            {
                                                                return(
                                                                    <option key={posStatus} value={status}>{status}</option>
                                                                )
                                                            }
                                                    )
                                                }
                                            </PD.Select>
    
                                        </fieldset>
                                    </div>

                                    <ListEntities filters={{idLogicalModel:this.state.idLogicalModel, ...this.state.EntityFilters}} parent={this} />
                                </div>


                                <div label='Relationships'>
                                    <div style={{display:"flex"}}>
                                        {this.state.OfferEdit && 
                                            <PD.Button name='btnAddRelationship' value='add a new Relationship' onClick={this.handleButton}/>
                                        }
                                        <fieldset style={{display:"flex"}}>
                                            <legend>filters</legend>
                                            <PD.Select label='status' name='status'  onChange={this.handleRelationshipFilters} value={this.state.RelationshipFilters.status}>
                                                {
                                                    this.dsd.config.RelationshipStatuses.map(
                                                        (status, posStatus) => 
                                                            {
                                                                return(
                                                                    <option key={posStatus} value={status}>{status}</option>
                                                                )
                                                            }
                                                    )
                                                }
                                            </PD.Select>
    
                                        </fieldset>
                                    </div>

                                    <ListRelationships filters={{idLogicalModel:this.state.idLogicalModel, ...this.state.RelationshipFilters}} parent={this} />
                                </div>

                                <div label='Log'>
                                    {this.log()}
                                </div>

                                <div label={`Versions(${this.state.countVersions})`}  forceRender={true}>
                                    {this.versions()}
                                </div>


                                <div label='Developer'>
                                    <table>
                                        {this.viz &&
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>dot</th>
                                                <td><pre>{this.viz.dot}</pre></td>
                                            </tr>
                                        }
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>csv</th>
                                                <td><pre>{this.state.csv}</pre></td>
                                            </tr>

                                            <tr>
                                                <th style={{verticalAlign:"top"}}>rdf Schema</th>
                                                <td><pre>
                                                {
                                                    Object.keys(this.state.rdfs).map(
                                                    (filename, pos) => 
                                                        {
                                                            return (
                                                                <>
                                                                    {filename}{"\n"}{this.state.rdfs[filename]}{"\n\n"}
                                                                </>
                                                            )
                                                        }
                                                    )
                                                }
                                                    
                                                </pre></td>
                                            </tr>


                                            <tr>
                                                <th style={{verticalAlign:"top"}}>xml Schema</th>
                                                <td><pre>
                                                {
                                                    Object.keys(this.state.xsds).map(
                                                    (filename, pos) => 
                                                        {
                                                            return (
                                                                <>
                                                                    {filename}{"\n"}{this.state.xsds[filename]}{"\n\n"}
                                                                </>
                                                            )
                                                        }
                                                    )
                                                }
                                                    
                                                </pre></td>
                                            </tr>


                                    </table>

                                </div>


                                <div label='Metadata'>
                                    <ViewMetadata Data={this.state.LogicalModel} />
                                </div>



                                <div label="used in ...">
                                </div>


                            </PD.Tabs>


                        </div>
                    )
            };
        };

        return <div />;
    };
};

// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <LogicalModel {...props} navigation={navigation} location={location} params={params} />;
  }
