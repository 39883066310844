import React from "react"

import { isNull, isEqual, isArray } from "lodash";

import {hasProperty, formatText} from '../utils/utils';

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';

import {SanitizeHTML} from '../utils/utils';



class ViewStep extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.data = {};
        this.state.Step = null;

        this.filters = {};

        this.columns = {
        };

        this.data.id = null;
        if (this.props.id){
            this.data.id = this.props.id; 
        }


    }


    componentDidMount(){

        this.setup();
 
     }
 
     componentDidUpdate(prevProps, prevState){
 
         if (!isEqual(this.props, prevProps)){
             this.setup();
         }
 
         if (!isEqual(this.state, prevState)){

            let StateChange = {};

            if (this.state.Step){
                StateChange.isLoaded = true;
            }

            this.setState(StateChange);

         }

        return;

    };

    setup(){


        let StateChange = {
            isLoaded: false,
            reload: false,

            Step: null,
            Artefacts: null,
            Resources: null,

        }


        if (this.props.Step){
            StateChange.Step = this.props.Step;            
        }

//        if (this.props.Artefacts){
//            StateChange.Artefacts = this.props.Artefacts; 
//        }
//        if (this.props.Resources){
//            StateChange.Resources = this.props.Resources; 
//        }

        this.setState(StateChange);

    }

    view(Step){

        if (Step){
            return(
                <div>
                    <div style={{marginBottom:"50px"}}>
                        <PD.Table className="panel">
                            <PD.Tbody>                                

                                {hasProperty(Step,'name') &&
                                    <PD.Tr>                                
                                        <PD.Th>Name</PD.Th><PD.Td>{Step.name}</PD.Td>
                                    </PD.Tr>
                                }

                                {hasProperty(Step,'type') &&
                                    <PD.Tr>
                                        <PD.Th>Type</PD.Th><PD.Td>{Step.type}</PD.Td>
                                    </PD.Tr>
                                }


                                {hasProperty(Step,'description') &&
                                    <PD.Tr>
                                        <PD.Th>Description</PD.Th><PD.Td><SanitizeHTML html={Step.description}/></PD.Td>
                                    </PD.Tr>
                                }

                                {hasProperty(Step,'proposals') &&
                                    <PD.Tr>
                                        <PD.Th>Proposals</PD.Th><PD.Td><SanitizeHTML html={Step.proposals}/></PD.Td>
                                    </PD.Tr>
                                }


                                {hasProperty(Step,'organisation') &&
                                    <PD.Tr>
                                        <PD.Th>Organisation</PD.Th><PD.Td>{formatText(Step.organisation)}</PD.Td>
                                    </PD.Tr>
                                }
                                {hasProperty(Step,'role') &&
                                    <PD.Tr>
                                        <PD.Th>Role</PD.Th><PD.Td>{formatText(Step.role)}</PD.Td>
                                    </PD.Tr>
                                }


                                {this.viewSubProcess(Step)}
                                {this.viewMultiInstance(Step)}

                            </PD.Tbody>
                        </PD.Table>
                    </div>

                    {this.viewOptions(Step)}
                    {this.viewArtefacts(Step)}
                    {this.viewResources(Step)}
                    {this.viewFlows(Step)}

                </div>

            )
        };
        return null;
    };



    viewSubProcess(Step){
        if (Step.type != 'subprocess'){
            return null;
        }
        if (!Step.SubProcess){
            return null;
        }
        return (
            <PD.Tr>
                <PD.Th>Sub Process</PD.Th><PD.Td><PD.PdNavLink to={"/process/" + Step.SubProcess._id} > {Step.SubProcess.name}</PD.PdNavLink></PD.Td>
            </PD.Tr>
        )
    }

    viewMultiInstance(Step){
        switch (Step.type){
            case 'task':
            case 'subprocess':
                break;
            default:
                return null;
        }
        if (!Step.multiinstance){
            return null;
        }
        return (
            <PD.Tr>
                <PD.Th>For each</PD.Th><PD.Td>{Step.foreach}</PD.Td>
            </PD.Tr>
        )
    }


    viewFlows(Step){

        if ("Flows" in Step){
            if (isArray(Step.Flows)){
                if (Step.Flows.length > 0){
                    return(
                        <div style={{marginBottom:"50px"}}>
                            <PD.Heading level="3">This Step is preceded by</PD.Heading>
                            <PD.Table type="list">
                                <PD.Tr>
                                    <PD.Th>Step</PD.Th>
                                </PD.Tr>

                                {
                                    Step.Flows.map(
                                        (StepFlow, posStepFlow) => 
                                            {
                                                return(
                                                    this.rowFlow(StepFlow, posStepFlow)
                                                )
                                            }
                                    )
                                }
                            </PD.Table>
                        </div>
                    )
                }
            }
        }
        return null;
    }

    rowFlow(StepFlow, key){

        if (!StepFlow){
            return null;
        }
        let label = null;
        if (StepFlow.Step){
            label = StepFlow.Step.name;
            if (StepFlow.idOption){
                if (StepFlow.Step.Options){
                    for (let posO=0; posO<StepFlow.Step.Options.length; posO++){
                        let Option = StepFlow.Step.Options[posO];
                        if (Option._id == StepFlow.idOption){
                            label += ` > ${Option.label}`;
                        }
                    }
                }
            }
        }
        return(


            <PD.Tr key={key}>
                <PD.Td>
                    {StepFlow.Step &&
                        <PD.PdNavLink to={"/step/" + StepFlow.Step._id} >
                            {label}
                        </PD.PdNavLink>}
                    {!StepFlow.Step &&
                        StepFlow.idStep}
                </PD.Td>
            </PD.Tr>
        )
    }


    viewOptions(Step){

        if ("Options" in Step){
            if (isArray(Step.Options)){
                if (Step.Options.length > 0){
                    return(
                        <div style={{marginBottom:"50px"}}>
                            <PD.Heading level="3">Options</PD.Heading>
                            <PD.Table type="list">
                                <PD.Tr>
                                    <PD.Th>Option</PD.Th>
                                </PD.Tr>

                                {
                                    Step.Options.map(
                                        (StepOption, posStepOption) => 
                                            {
                                                return(
                                                    this.rowOption(StepOption, posStepOption)
                                                )
                                            }
                                    )
                                }
                            </PD.Table>
                        </div>
                    )
                }
            }
        }
        return null;
    }

    rowOption(StepOption, key){

        if (!StepOption){
            return null;
        }
        return(
            <PD.Tr key={key}>
                <PD.Td>{StepOption.label}</PD.Td>
            </PD.Tr>
        )
    }


    viewArtefacts(Step){

        if ("Artefacts" in Step){
            if (isArray(Step.Artefacts)){
                if (Step.Artefacts.length > 0){
                    return(
                        <div style={{marginBottom:"50px"}}>
                            <PD.Heading level="3">Artefacts</PD.Heading>
                            <PD.Table type="list">
                                <PD.Tr>
                                    <PD.Th>Artefact</PD.Th>
                                    <PD.Th>Use</PD.Th>
                                </PD.Tr>

                                {
                                    Step.Artefacts.map(
                                        (StepArtefact, posStepArtefact) => 
                                            {
                                                return(
                                                    this.rowArtefact(StepArtefact, posStepArtefact)
                                                )
                                            }
                                    )
                                }
                            </PD.Table>
                        </div>
                    )
                }
            }
        }
        return null;
    }

    rowArtefact(StepArtefact, key){

        return(
            <PD.Tr key={key}>
                <PD.Td>
                    {StepArtefact.Artefact &&
                        <PD.PdNavLink to={"/artefact/" + StepArtefact.Artefact._id} > {StepArtefact.Artefact.name}</PD.PdNavLink>}
                    {!StepArtefact.Artefact &&
                        StepArtefact.idArtefact}
                </PD.Td>
                <PD.Td>{StepArtefact.use}</PD.Td>
            </PD.Tr>
        )

    }

    viewResources(Step){

        if ("Resources" in Step){
            if (isArray(Step.Resources)){
                if (Step.Resources.length > 0){
                    return(
                        <div style={{marginBottom:"50px"}}>
                            <PD.Heading level="3">Resources</PD.Heading>
                            <PD.Table type="list">
                                <PD.Tr>
                                    <PD.Th>Resource</PD.Th>
                                    <PD.Th>Type</PD.Th>
                                    <PD.Th>Relevance</PD.Th>
                                </PD.Tr>

                                {
                                    Step.Resources.map(
                                        (StepResource, posStepResource) => 
                                            {
                                                return(this.rowResource(StepResource, posStepResource))
                                            }
                                    )
                                }
                            </PD.Table>
                        </div>
                    )
                }
            }
        }
        return null;
    }

    rowResource(StepResource, key){

        return(
            <PD.Tr key={key}>
                <PD.Td>
                    {StepResource.Resource &&
                        <PD.PdNavLink to={"/resource/" + StepResource.Resource._id} > {StepResource.Resource.name}</PD.PdNavLink>}
                </PD.Td>
                <PD.Td>
                    {StepResource.Resource &&
                        StepResource.Resource.type}
                </PD.Td>
                <PD.Td>
                    {StepResource &&
                        StepResource.relevance}
                </PD.Td>

            </PD.Tr>
        )
    }


    render() {

        if (this.state.isLoaded) {

            return (
                <div>
                    {this.view(this.state.Step)}
                </div>
            )
        }
        else {
            return <div />;
        }

    };    
};
        
export default ViewStep;