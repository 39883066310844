import React from "react"

import {hasProperty, formatText} from '../utils/utils';

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';

import {SanitizeHTML} from '../utils/utils';


class ViewActor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.data = {};
        this.data.Actor = null;

        if (this.props.Actor){
            this.data.Actor = this.props.Actor; 
        }


        this.filters = {};

        this.columns = {
        };

        this.data.id = null;
        if (this.props.id){
            this.data.id = this.props.id; 
        }


    }

    componentDidMount() {

        if (this.data.Actor){
            this.setState({"isLoaded":true});
            return;
        }

    };

    view(json){

        if (json){
            return(
                <PD.Table className="panel">
                    <PD.Tbody>                                


                        {hasProperty(json,'type') &&
                            <PD.Tr>                                
                                <PD.Th>Type</PD.Th><PD.Td>{json.type}</PD.Td>
                            </PD.Tr>
                        }

                        {hasProperty(json,'name') &&
                            <PD.Tr>                                
                                <PD.Th>Name</PD.Th><PD.Td><PD.PdNavLink to={"/actor/" + json._id} > {json.name}</PD.PdNavLink></PD.Td>
                            </PD.Tr>
                        }

                        {hasProperty(json,'description') &&
                            <PD.Tr>
                                <PD.Th>Description</PD.Th><PD.Td><SanitizeHTML html={json.description}/></PD.Td>
                            </PD.Tr>
                        }

                        {hasProperty(json,'proposals') &&
                            <PD.Tr>
                                <PD.Th>Proposals</PD.Th><PD.Td><SanitizeHTML html={json.proposals}/></PD.Td>
                            </PD.Tr>
                        }


                        {hasProperty(json,'status') &&
                            <PD.Tr>                                
                                <PD.Th>Status</PD.Th><PD.Td>{json.status}</PD.Td>
                            </PD.Tr>
                        }

                        {hasProperty(json,'Package') &&
                            <>
                                <PD.Tr>                                
                                    <PD.Th>Package</PD.Th>
                                    <PD.Td>    
                                        <PD.PdNavLink to={"/package/" + json.Package._id} > {json.Package.name}</PD.PdNavLink>
                                    </PD.Td>
                                </PD.Tr>

                                {json.Package.Owner &&
                                    <PD.Tr>
                                        <PD.Th>Owner</PD.Th><PD.Td>{json.Package.Owner.organisation}</PD.Td>
                                    </PD.Tr>
                                }
                            </>

                        }


                    </PD.Tbody>
                </PD.Table>
            )
        };
        return null;
    };

    render() {

        if (this.state.isLoaded) {

            return (
                <div>
                    {this.view(this.data.Actor)}
                </div>
            )
        }
        else {
            return <div />;
        }

    };    
};
        
export default ViewActor;