import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import {cloneObject, getParam, formatText, formatDate, truncate} from '../utils/utils';
import {SanitizeHTML,  HtmlToText} from '../utils/utils';

import dsd from "../classes/clsDSD";

import * as PD from '../classes/pdStyle';

import { Pencil } from 'react-bootstrap-icons';
import { CheckSquare } from 'react-bootstrap-icons';

import Version from "../components/Version";


class ListVersions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            Versions : null
        };

        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.handleButton = this.handleButton.bind(this);
        this.VersionOnActioned = this.VersionOnActioned.bind(this);

    }

componentDidMount(){

    this.setup();
 
}



componentDidUpdate(prevProps, prevState){

   if (!isEqual(this.props, prevProps)){
       this.setup();
   }

   if (!isEqual(this.state, prevState)){
        if (this.state.load){
            this.loadData();
        }

        if (this.state.isLoaded){
            if (this.state.onLoad){
                this.state.onLoad(this.state.Versions.length);
            }
        }
   }

}

loadData(){

    this.dsd.listVersions(this.state.filters);
    this.setState({load:false});
}

setup(){

    let StateChange = {
        filters: {},
        data: null,
        columns: {
        },
        load: false,
        onLoad: null,

        OfferEdit: false,
        idEdit: null,

    }

    if (getParam('filters', this.props)){
        StateChange.filters = getParam('filters', this.props);
    }

    if (getParam('columns', this.props)){
        StateChange.columns = getParam('columns', this.props);
    }

    if (getParam('onLoad', this.props)){
        StateChange.onLoad = getParam('onLoad', this.props);
    }


    if (getParam('OfferEdit', this.props)){
        StateChange.OfferEdit = getParam('OfferEdit', this.props);
    }



    StateChange.load = true;

    this.setState(StateChange);
        
}


handleButton(event) {
    
    let StateChange = {};

    switch (event.currentTarget.name){
        case 'btnEdit':
            StateChange.idEdit = event.currentTarget.attributes['data-idversion'].value;
            break;
    }

    this.setState(StateChange);

    event.preventDefault();    

};



    included(Version) {

        let IncludeThis = true;

        return IncludeThis;
    }

    VersionOnActioned(){

        this.setState({
            idEdit: null,
            load: true
        })

        return;
    }

    render() {


        if (this.state.isFailed) {
            return (
                <PD.ErrorBox>
                    A problem has occurred
                </PD.ErrorBox>
            )
        }


        if (this.state.isLoaded) {


            let arrVersions = this.state.Versions;
            if (isArray(arrVersions)){
                if (arrVersions.length > 0){

                    return (
                        <PD.Table className="list" style={{width:"100%"}}>
                            <PD.Thead>
                                <PD.Tr>
                                    {this.state.OfferEdit &&
                                        <PD.Th/>
                                    }
                                    <PD.Th>Name</PD.Th>
                                    <PD.Th>Date/Time</PD.Th>
                                    <PD.Th>Description</PD.Th>
                                </PD.Tr>
                            </PD.Thead>

                            <PD.Tbody>

                            {
                                arrVersions.map(
                                    (objVersion, pos) => 
                                    {
                                        if (this.included(objVersion)) {

                                            let maxRows = 1;

                                            let Rows = []
                                            for (let i = 1; i <= maxRows; i++) {
                                                Rows.push(null);
                                            }

                                            if (this.state.idEdit == objVersion._id){
                                                return(
                                                    <PD.Tr key={pos}>
                                                        <PD.Td width="100%" colSpan='10'>
                                                            <Version idVersion={this.state.idEdit} onActioned={this.VersionOnActioned}/>
                                                        </PD.Td>
                                                    </PD.Tr>
                                                )
                                            }
                
                                            let type = 'conceptmodel';
                                            if (objVersion.type){
                                                type = objVersion.type;
                                            }

                                            return (

                                                    <PD.Tr key={pos}>

                                                        {this.state.OfferEdit &&
                                                            <PD.Td  style={{width:"1em"}}>
                                                                <button name="btnEdit" onClick={this.handleButton} data-idversion={objVersion._id}><Pencil/></button>
                                                            </PD.Td>
                                                        }

                                                        <PD.Td style={{width:"20%"}}>
                                                            <PD.PdNavLink to={`/${type}/${objVersion.idAbout}/version/${objVersion.id}`} > {objVersion.name}</PD.PdNavLink>
                                                        </PD.Td>
                                                        <PD.Td style={{width:"1em"}}>
                                                            {formatDate(objVersion.createdAt,'dmy hms')}
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {formatText(truncate(HtmlToText(objVersion.description), 400))}
                                                        </PD.Td>
                                                    </PD.Tr>

                                            )
                                        }
                                        else{
                                            return null;
                                        }                
                                    }
                                )
                            }
                            </PD.Tbody>

                        </PD.Table>
                    )
                }
            }
        }

        return <div />;

    };    
};
        
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <ListVersions {...props} navigation={navigation} location={location} params={params} />;
  }
