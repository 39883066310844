import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import {cloneObject, getParam, formatText, truncate} from '../utils/utils';
import {SanitizeHTML,  HtmlToText} from '../utils/utils';

import dsd from "../classes/clsDSD";

import * as PD from '../classes/pdStyle';

import { Pencil } from 'react-bootstrap-icons';
import { CheckSquare } from 'react-bootstrap-icons';

import Revision from "../components/Revision";


class ListRevisions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            Revisions : null
        };


        this.refRevision = React.createRef();

        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.handleButton = this.handleButton.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

componentDidMount(){

    this.setup();
 
}



componentDidUpdate(prevProps, prevState){

   if (!isEqual(this.props, prevProps)){
       this.setup();
   }

   if (!isEqual(this.state, prevState)){
        if (this.state.load){
            this.loadData();
        }

        if (this.state.isLoaded){
            if (this.state.onLoad){
                this.state.onLoad(this.state.Revisions.length);
            }
        }
   }

}

loadData(){

    this.dsd.listRevisions(this.state.filters);
    this.setState({load:false});
}

setup(){

    let StateChange = {
        filters: {},
        data: null,
        columns: {
        },
        load: false,
        onLoad: null,

        OfferEdit: false,
        idEdit: null,

    }

    if (getParam('filters', this.props)){
        StateChange.filters = getParam('filters', this.props);
    }

    if (getParam('columns', this.props)){
        StateChange.columns = getParam('columns', this.props);
    }

    if (getParam('onLoad', this.props)){
        StateChange.onLoad = getParam('onLoad', this.props);
    }


    if (getParam('OfferEdit', this.props)){
        StateChange.OfferEdit = getParam('OfferEdit', this.props);
    }


    StateChange.load = true;

    this.setState(StateChange);
        
}


handleButton(event) {
    
    let StateChange = {};

    switch (event.currentTarget.name){
        case 'btnEdit':
            StateChange.idEdit = event.currentTarget.attributes['data-idrevision'].value;
            break;
    }

    this.setState(StateChange);

    event.preventDefault();    

};

handleSubmit(event) {

    switch (event.target.name){
        
        case 'btnCancelRevision':

            this.setState({
                idEdit: null
            })

            break;
        case 'btnSaveRevision':

            this.refRevision.current.update();

            this.setState({
                idEdit: null,
                load: true
            })

            break;

    };

    event.preventDefault();
}



    included(Revision) {

        let IncludeThis = true;

        return IncludeThis;
    }

    render() {


        if (this.state.isFailed) {
            return (
                <PD.ErrorBox>
                    A problem has occurred
                </PD.ErrorBox>
            )
        }


        if (this.state.isLoaded) {


            let arrRevisions = this.state.Revisions;
            if (isArray(arrRevisions)){
                if (arrRevisions.length > 0){

                    return (
                        <PD.Table className="list">
                            <PD.Thead>
                                <PD.Tr>
                                    {this.state.OfferEdit &&
                                        <PD.Th/>
                                    }

                                    <PD.Th>Num</PD.Th>
                                    <PD.Th>Report?</PD.Th>
                                    <PD.Th>By</PD.Th>
                                    <PD.Th>Description</PD.Th>
                                    <PD.Th>On</PD.Th>
                                    <PD.Th>Last Amended</PD.Th>
                                </PD.Tr>
                            </PD.Thead>

                            <PD.Tbody>

                            {
                                arrRevisions.map(
                                    (objRevision, pos) => 
                                    {
                                        if (this.included(objRevision)) {

                                            let maxRows = 1;

                                            let Rows = []
                                            for (let i = 1; i <= maxRows; i++) {
                                                Rows.push(null);
                                            }

                                            if (this.state.idEdit == objRevision._id){
                                                return(
                                                    <PD.Tr key={pos}>
                                                        <PD.Td colSpan='10'>
                                                            <Revision  ref={this.refRevision}  idRevision={this.state.idEdit}/>
                                                            <div>
                                                                <PD.Button type="submit" onClick={this.handleSubmit} name="btnSaveRevision" value="Save" />    
                                                                <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancelRevision" value="Cancel" /> 
                                                            </div>

                                                        </PD.Td>
                                                    </PD.Tr>
                                                )
                                            }
                
                                            return (

                                                    <PD.Tr key={pos}>

                                                        {this.state.OfferEdit &&
                                                            <button name="btnEdit" onClick={this.handleButton} data-idrevision={objRevision._id}><Pencil/></button>
                                                        }


                                                        <PD.Td contentType='numeric'>
                                                            {objRevision.seq}
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {objRevision.report &&
                                                                <CheckSquare/>
                                                            }
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {(() => {
                                                                if (objRevision.User){
                                                                    return objRevision.User.organisation;
                                                                }
                                                                return null;
                                                            })()}
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {formatText(truncate(HtmlToText(objRevision.description), 400))}
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {objRevision.createdAt}
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {(objRevision.updatedAt  && ( objRevision.updatedAt != objRevision.createdAt)) &&
                                                                objRevision.updatedAt
                                                            }
                                                        </PD.Td>
                                                    </PD.Tr>

                                            )
                                        }
                                        else{
                                            return null;
                                        }                
                                    }
                                )
                            }
                            </PD.Tbody>

                        </PD.Table>
                    )
                }
            }
        }

        return <div />;

    };    
};
        
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <ListRevisions {...props} navigation={navigation} location={location} params={params} />;
  }
