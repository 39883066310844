import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import {cloneObject, getParam} from '../utils/utils';

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';

import Buffering from "../components/Buffering"; 



class SelectStructure extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            renderState: null
        };

        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.handleChange = this.handleChange.bind(this);
    

    }

    componentDidMount(){

        this.setup();
 
     }
 
     componentDidUpdate(prevProps, prevState){
 
        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){


            if (this.state.idSelected != prevState.idSelected){
                if (this.state.idSelected){
                    this.state.loadSelectedStructure = true;
                }
            }

            if (this.state.isLoadedSelectedStructure){
                if (this.state.SelectedStructure.DataDictionary){
                    StateChange.idDataDictionary = this.state.SelectedStructure.DataDictionary._id;
                }

            }

            if (this.state.idDataDictionary != prevState.idDataDictionary){
                if (this.state.idDataDictionary){
                    StateChange.loadStructures = true;
                }
            }

            if (this.state.loadDataDictionaries){
                this.loadDataDictionaries();
            }

            if (this.state.loadStructures){
                this.loadStructures();
            }

            if (this.state.loadSelectedStructure){
                this.loadSelectedStructure(this.state.idSelected);
                if (this.state.onBlur){
                    this.state.onBlur();
                }
            }


            if (this.state.isFailed){
                StateChange.renderState = "failed";
            }

            if (this.state.renderState == "loading"){
                if (this.state.isLoadedDataDictionaries){
                    StateChange.renderState = "loaded";
                }

                if (this.state.isLoadedStructures){
                    StateChange.renderState = "loaded";
                }
            }
             
            this.setState(StateChange);

        }
        

    
    }
 

    setup(){

        let StateChange = {
            renderState: null,

            loadDataDictionaries: false,
            loadStructures: false,
            loadSelectedStructure: false,

            selectDataDictionary: false,

            label: "Structure",
            idSelected: "",
            idDataDictionary: null,

            onChange: null,
            onBlur: null

        }

        StateChange.name = getParam('name', this.props);

        if (getParam('label',this.props)){
            StateChange.label = getParam('label',this.props);
        }

        if (getParam('selectDataDictionary',this.props)){
            StateChange.selectDataDictionary = getParam('selectDataDictionary',this.props);
        }


        StateChange.onChange = getParam('onChange', this.props);
        StateChange.onBlur = getParam('onBlur', this.props);

        StateChange.idDataDictionary = getParam('idDataDictionary', this.props);

        StateChange.idSelected = getParam('value', this.props);


        if (StateChange.selectDataDictionary){
            StateChange.loadDataDictionaries = true;
        }
        else
        {
            StateChange.loadStructures = true;
        }

        this.setState(StateChange);

    }

    loadSelectedStructure(idStructure){

        let StateChange = {};
        StateChange.loadSelectedStructure = false;
        StateChange.isLoadedSelectedStructure = false;
        StateChange.SelectedStructure = null;

        StateChange.renderState = "loading";
        this.dsd.getStructure(idStructure,"SelectedStructure","isLoadedSelectedStructure")
        
        this.setState(StateChange);

        return;
        
    };



    loadStructures(){

        let StateChange = {};
        StateChange.loadStructures = false;
        StateChange.isLoadedStructures = false;
        StateChange.Structures = null;

        StateChange.renderState = "loading";
        this.dsd.listStructures({idDataDictionary:this.state.idDataDictionary},"Structures","isLoadedStructures")
        
        this.setState(StateChange);

        return;
        
    };


    loadDataDictionaries(){

        let StateChange = {};
        StateChange.loadDataDictionaries = false;
        StateChange.isLoadedDataDictionaries = false;
        StateChange.DataDictionaries = null;

        StateChange.renderState = "loading";
        this.dsd.listDataDictionaries({},"DataDictionaries","isLoadedDataDictionaries");
        
        this.setState(StateChange);

        return;
        
    };



    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        switch (FormFieldName){
            case "idDataDictionary":
                this.setState({"idDataDictionary":FormFieldValue})
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;
                this.setState({"FormData": FormData});
                break;
        };

    };


    render() {

        switch (this.state.renderState){
            case "invalid":
            case "failed":
            case "denied":
                let Message = "An error has occured";
                if (this.state.errorMessage){
                    Message = this.state.errorMessage;
                }
                return <PD.ErrorBox>{Message}</PD.ErrorBox>
                break;
            case "loading":
                return <Buffering/>
                break;
            case "cancelled":
                return <p>Cancelled</p>
                break;
            case "loaded":

                return(
                    <>                    

                        {this.state.selectDataDictionary && this.state.isLoadedDataDictionaries &&
                            <PD.Select label='Select a Data Dictionary' name='idDataDictionary' onChange={this.handleChange} value={this.state.idDataDictionary}>
                                {
                                    this.state.DataDictionaries.map(
                                        (DataDictionary, pos) => 
                                            {
                                                return(
                                                    <option key={pos} value={DataDictionary._id}>{DataDictionary.name}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>
                        }


                        {this.state.isLoadedStructures &&

                            <PD.Select label={this.state.label} name={this.state.name} onChange={this.state.onChange} onBlur={this.state.onBlur} value={this.state.idSelected}>
                                {
                                    this.state.Structures.map(
                                        (Structure, pos) => 
                                            {
                                                return(
                                                    <option key={pos} value={Structure._id}>{Structure.name}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>
                        }

                    </>
                )
        }

    }
}


export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <SelectStructure {...props} navigation={navigation} location={location} params={params} />;
  }
