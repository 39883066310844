import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import {cloneObject, getParam} from '../utils/utils';

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';

import Buffering from "../components/Buffering"; 

import ResourceRow from "../components/ResourceRow"



class FormResources extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            renderState: null
        };

        this.dsd = new dsd();
        this.dsd.StateObject = this;

    }

    componentDidMount(){

        this.setup();
 
     }
 
     componentDidUpdate(prevProps, prevState){
 
        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){


            if (this.state.isFailed){
                StateChange.renderState = "failed";
            }

            if (this.state.FormData){
                StateChange.renderState = "loaded";
            }
             
            this.setState(StateChange);

        }
        

    
    }
 

    setup(){

        let StateChange = {
            Resources: [], 

            renderState: null,

            FormData: {},
            parent: null

        }

        StateChange.parent = getParam('parent', this.props);
        if (StateChange.parent){
            if (StateChange.parent.state.FormData){
                StateChange.FormData = StateChange.parent.state.FormData;
                if (StateChange.FormData.Resources){
                    StateChange.Resources = StateChange.FormData.Resources;
                }
            }
        }

        this.setState(StateChange);

    }

    render(){

        switch (this.state.renderState){
            case "loaded":

                let seqNext = 1;

                if (this.state.FormData.Resources){
                    seqNext = this.state.FormData.Resources.length + 1;
                }
                return (
                    <div>
                        <PD.Heading level="2">Resources</PD.Heading>
                        <PD.Table type="list">
                            {this.state.FormData.Resources &&
                                this.state.FormData.Resources.map(
                                    (Resource, posResource) => 
                                    {
                                        let seq = posResource+1;
                                        return(
                                            this.FormResource('edit', seq, Resource)
                                        )
                                    }
                                )
                            }
                            {this.FormResource('new', seqNext)}
                        </PD.Table>
                    </div>
                );
                break;
            default:
                return null;
                break;
        };

    };

    FormResource(mode, seq, Resource = {}){
        return(
            <ResourceRow key={seq.toString()} mode={mode} parent={this} Resource={Resource} seq={seq} seqStep={this.state.seqStep}/>
        )
    }

    addResource(){

        let FormData = this.state.FormData;

        if (!('Resources' in FormData)){
            FormData['Resources'] = [];
        }
        if (!FormData.Resources){
            FormData['Resources'] = [];
        }

        FormData.Resources.push({});

        this.setState({"FormData": FormData});
        if (this.state.parent){
            this.state.parent.setState({"FormData": FormData});
        }

    }

    removeResource(seq){

        let FormData = this.state.FormData;

        if (!('Resources' in FormData)){
            FormData['Resources'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Resources.length;pos++){
            let item = FormData.Resources[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Resources = arrNew;
        this.setState({"FormData": FormData});
        if (this.state.parent){
            this.state.parent.setState({"FormData": FormData});
        }

    }


    updateResource(seq, Resource){

        let FormData = this.state.FormData;

        if (!('Resources' in FormData)){
            FormData['Resources'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Resources.length;pos++){
            let item = FormData.Resources[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Resource;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Resource);
        }

        FormData.Resources = arrNew;
        this.setState({"FormData": FormData});
        if (this.state.parent){
            this.state.parent.setState({"FormData": FormData});
        }

    }
};



export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <FormResources {...props} navigation={navigation} location={location} params={params} />;
  }
