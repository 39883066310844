import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { isNull, isEqual, isArray } from "lodash";

import {hasProperty, formatText} from '../utils/utils';
import {cloneObject, getParam} from '../utils//utils';

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';

import {SanitizeHTML} from '../utils/utils';
import { Graphviz } from "graphviz-react";


import { PublishLayoutContext } from '../contexts/PublishLayoutContext';


class PublishProcess extends React.Component {

    static contextType = PublishLayoutContext;
    
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            idTopProcess: null,
            idProcess: null,
            idArtefact: null,
            Process: null,
            TopProcess: null,
            ProcessArtefacts: null,
            ProcessResources: null,
            ProcessesUsingThisProcess: null,
            
            ArtefactResources: null,
            ArtefactSteps: null,
        };

        this.viz = null;
        this.vizEngine = "neato";

        this.handleClickProcessMap = this.handleClickProcessMap.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

    }

    setup(){


        let StateChange = {

            action: null,
            idTopProcess : null,
            title: null,
            idProcess: null,
            section: null,
            idStep: null,
            seq: null

        }

        StateChange.idTopProcess = getParam('idTopProcess', this.props);

        StateChange.idProcess = getParam('idProcess', this.props);
        if (!StateChange.idProcess){
            StateChange.idProcess = StateChange.idTopProcess;
        }

        StateChange.idStep = getParam('idStep', this.props);

        StateChange.section = getParam('section', this.props);
        if (!StateChange.section){
            StateChange.section = "introduction";
        }

        StateChange.seq = getParam('seq', this.props);

        this.filters = {};

        this.columns = {
        };

        this.setState(StateChange);

// top of page
        window.scrollTo(0, 0);
        
    }


    componentDidMount(){

        this.setup();
 
     }
 
     componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.idProcess){
                if (this.state.idProcess != prevState.idProcess){
                    StateChange.Process = null;
                    StateChange.isLoaded = false;
                    this.loadData();
                }
            }

            if (this.state.ProcessArtefacts){
                if (this.state.section){
                    if (this.state.section == "artefacts"){
                        if (this.state.seq){

                            let Artefact = this.state.ProcessArtefacts[this.state.seq-1];
                            if (this.state.idArtefact != Artefact.id){
                                StateChange.idArtefact = Artefact.id;
                            }
                        }
                    }
                }
            }

            if (this.state.idArtefact){
                if (this.state.idArtefact != prevState.idArtefact){
                    StateChange.ArtefactResources = null;
                    this.loadArtefactResources(this.state.idArtefact);
                    StateChange.ArtefactSteps = null;
                    this.loadArtefactSteps(this.state.idArtefact);
                }
            }

            if (this.state.Process){
                StateChange.isLoaded = true;
            }

            if (this.state.idTopProcess != prevState.idTopProcess ){
                localStorage.removeItem("dsdContent");
            }

            if (this.state.TopProcess){
                StateChange.title = this.state.TopProcess.name
            }

    
            this.setState(StateChange);

        }

    }

    loadData(){


        if (this.state.idTopProcess){
            this.dsd.getProcess(this.state.idTopProcess,"TopProcess");
        }

        if (this.state.idProcess){
            this.dsd.getProcess(this.state.idProcess);
            this.dsd.listArtefacts({"idProcess":this.state.idProcess},"ProcessArtefacts");
            this.dsd.listResources({"idProcess":this.state.idProcess},"ProcessResources");
            this.dsd.listProcesses({"idSubProcess":this.state.idProcess},"ProcessesUsingThisProcess");
        }
        
        return;
        
    };

    loadArtefactResources(idArtefact){

        this.dsd.listResources({"idArtefact":idArtefact},"ArtefactResources");
        return;

    }

    loadArtefactSteps(idArtefact){

        this.dsd.listSteps({"idArtefact":idArtefact},"ArtefactSteps");
        return;

    }


    view(Process){

        if (Process){

            this.viz = this.dsd.vizProcess(this.state.Process, this.vizEngine, false, true);

            return(
                <div className='dsdPublish'>

                    {(() => {
                        switch (this.state.section) {
                            case "introduction":
                                return(
                                    <>
                                        <div className="publish-contentheading">Introduction</div>
                                        <SanitizeHTML className='dsdPublishHTML' html={Process.description}/>
                                        <p><PD.PdNavLink to={`${this.props.location.pathname}?idProcess=${this.state.idProcess}&section=process`}>The Process</PD.PdNavLink></p>
                                    </>
                                )
                                break;
                            case "process":
                                return(
                                    <>
                                        <div className="publish-contentheading">The Process</div>
                                        {this.visualise()}
                                    </>
                                )
                                break;
                            case "steps":
                                let Step = null;
                                if (this.state.idStep){
                                    for (let posS=0; posS<this.state.Process.Steps.length; posS++){
                                        if (this.state.Process.Steps[posS]._id == this.state.idStep){
                                            Step = this.state.Process.Steps[posS];
                                        }
                                    }
                                    if (Step){
                                        return(
                                            <>
                                                {this.viewStep(Step)}
                                            </>
                                        )
                                    }
                                }
                                return(
                                    <>
                                        {this.Steps()}
                                    </>
                                )
                                break;    


                            case "artefacts":
                                if (this.state.ProcessArtefacts){
                                    if (this.state.seq){
                                        let Artefact = this.state.ProcessArtefacts[this.state.seq-1];
                                        return(
                                            <>
                                                {this.viewArtefact(Artefact)}
                                            </>
                                        )
                                        break;
                                    }
                                    return(
                                        <>
                                            {this.Artefacts()}
                                        </>
                                    )
                                }
                                return null;
                                break;    

                            default:
                                return(
                                    <>
                                        <SanitizeHTML className='dsdPublishHTML' html={Process.description}/>
                                        <h2>The Process</h2>
                                        {this.visualise()}
                                        {this.ProcessesUsingThisProcess()}
                                        {this.Steps()}                                    
                                    </>
                                )
                        }
                    })()}

                </div>
            )
        }

        return null;
    };

    visualise(){
    
        if (!this.state.Process){
            return null;
        }

        if (!this.state.ProcessArtefacts){
            return null;
        }

        let vizoptions = {}
        vizoptions.engine = this.vizEngine;
        vizoptions.scale = 1;
        vizoptions.fit = false;
        vizoptions.zoom = false;
        vizoptions.width = null;
        vizoptions.height = null;

        let styleDiv = null;

        try {

            return(
                <div>
                    <div name="divProcessMap" style={styleDiv} onClick={this.handleClickProcessMap}>
                        <Graphviz dot={this.viz.dot} options={vizoptions}/>
                    </div>
                </div>
            );
          } catch (error) {
            return <div/>
          }
    };


    handleClickProcessMap(event) {

        let idNode = null;
        if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }

        if (idNode){
            this.showNode(idNode);
        }

        event.preventDefault();

    };


    showNode(idNode){

        if (!idNode){
            return null;
        }

        let partsNodeId = idNode.split('_');
        if (partsNodeId.length > 1){
            switch(partsNodeId[0]){
                case 'step':
                    let idStep = partsNodeId[1];
                    this.props.navigation(`${this.props.location.pathname}?idProcess=${this.state.idProcess}&section=steps&idStep=${idStep}`);
                    break;
                case 'artefact':
                    let idArtefact = partsNodeId[1];

                    let Artefact = null;
                    for (let posArtefact=0; posArtefact<this.state.ProcessArtefacts.length; posArtefact++){
                        if (this.state.ProcessArtefacts[posArtefact].id == idArtefact){
                            this.props.navigation(`${this.props.location.pathname}?idProcess=${this.state.idProcess}&section=artefacts&seq=${posArtefact + 1}`);
                            break;
                        }
                    }
                    break;
            }
        }

        return null;

    }

    Steps(){

        if (!this.state.Process.Steps){
            return null;
        }
        if (this.state.Process.Steps == 0){
            return null;
        }

        return(
            <div>
                <h2>The Steps</h2>
                <table className="dsdPublishList">
                    <tr>
                        <th>Step</th>
                        <th>Method</th>
                        <th>Links</th>
                    </tr>


                    {
                        this.state.Process.Steps.map(
                            (Step, posStep) => 
                            {
                            <PD.Tr>
                                    <PD.Th>Organisation</PD.Th><PD.Td>{formatText(Step.organisation)}</PD.Td>
                                </PD.Tr>
                                
                                return (
                                    <tr>
                                        <td style={{maxWidth:"150px"}}>
                                            {Step.name}
                                        </td>
                                        <td  style={{maxWidth:"800px"}}>
                                            <SanitizeHTML className='dsdPublishHTML' html={Step.description}/>
                                        </td>
                                        <td>
                                            {this.SubProcess(Step)}
                                            {this.StepArtefacts(Step)}
                                            {this.StepWho(Step)}
                                            {this.StepResources(Step)}
                                        </td>
                                    </tr>
                                    
                                )
                            }
                        )
                    }

                </table>
            </div>
        )
    };


    viewStep(Step){

        if (Step){
            return(
                <div className='dsdPublish'>
                    <div style={{marginBottom:"50px"}}>
                        <div className="publish-contentheading">{Step.name}</div>

                        <SanitizeHTML className="dsdPublishHTML" style={{marginBottom:"15px"}} html={Step.description}/>

                        {this.SubProcess(Step)}
                        {this.StepWho(Step)}
                        {this.StepArtefacts(Step)}
                        {this.StepResources(Step)}
                        {this.NextSteps(Step)}
                        {this.PreviousSteps(Step)}

                    </div>
                </div>

            )
        };
        return null;
    };

    ProcessesUsingThisProcess(){
        if (!this.state.ProcessesUsingThisProcess){
            return null;
        }
        if (this.state.ProcessesUsingThisProcess.length == 0){
            return null;
        }

        return (
            <div>
                <h2>The Processes that use this Process</h2>
                <ul>
                    {
                        this.state.ProcessesUsingThisProcess.map(
                            (UsingProcess, posUsing) => 
                            {
                                return(
                                    <li key={posUsing}>
                                        <PD.PdNavLink to={`${this.props.location.pathname}?idProcess=${UsingProcess.id}`}> {UsingProcess.name}</PD.PdNavLink>
                                    </li>
                                )

                            }
                        )
                    }

                </ul>
            </div>
        )

    }

    SubProcess(Step){

        if (Step.SubProcess){
            return (
                <div>
                    <div className="publish-sectionheading">How is this done?</div>
                    <ul>
                        <li>
                            <PD.PdNavLink to={`${this.props.location.pathname}?idProcess=${Step.idSubProcess}`}> {Step.SubProcess.name}</PD.PdNavLink>
                        </li>
                    </ul>
            </div>)
        }


        return null;
    }





    StepWho(Step){
        let Who = '';

        if (Step.organisation){
            Who += Step.organisation;
        }
        if (Step.role){
            if (Who){
                Who += "/ ";
            }
            Who += Step.role;
        }

        if (Who){
            return(
                <div>
                    <div className="publish-sectionheading">
                        Who does this?
                    </div>
                    <ul>
                        <li>{Who}</li>
                    </ul>
                </div>
            )
        }
        return null;

    }



    ListArtefactSteps(ArtefactSteps){

        let Processes = {};

        if (isArray(ArtefactSteps)){
            if (ArtefactSteps.length > 0){

// group by Process
                for (let posP = 0; posP<ArtefactSteps.length; posP++){
                    let Step = ArtefactSteps[posP];

                    if (!(Step.Process._id in Processes)){
                        Processes[Step.Process._id] = Step.Process;
                        Processes[Step.Process._id].Steps = {};
                    }
                    let Process = Processes[Step.Process._id]
                    Process.Steps[Step._id] = Step;
                            
                }

                return(
                    <div>
                        <div className="publish-sectionheading">Processes where this is used</div>
                        <ul>
                            {
                                Object.keys(Processes).map(
                                    (idProcess, posProcess) => 
                                    {
                                        let Process = Processes[idProcess];

                                        return(
                                            <li key={posProcess}>
                                                <PD.PdNavLink to={`${this.props.location.pathname}?idProcess=${Process._id}`}>{Process.name}</PD.PdNavLink>
                                                <ul>

                                                    {
                                                        Object.keys(Process.Steps).map(
                                                            (idStep, posStep) => 
                                                            {
                                                                let Step = Process.Steps[idStep];                
                                                                return(
                                                                    <li key={posStep}>
                                                                        <PD.PdNavLink to={`${this.props.location.pathname}?idProcess=${Process._id}&idStep=${Step._id}&section=steps`}>{Step.name}</PD.PdNavLink>
                                                                    </li>
                                                                )
                                                            }
                                                        )
                                                    }


                                                
                                                </ul>
                                            </li>
                                        )
                                    }
                                )
                            }
                        </ul>
                    </div>
                )
            }
        }
        return null;
    }


    ListResources(itemResources, ListResources){

        if (isArray(itemResources)){
            if (itemResources.length > 0){
                if (isArray(ListResources)){

// sort by Resource
                    let Resources = []
                    for (let posSR = 0; posSR<itemResources.length; posSR++){
                        let itemResource = itemResources[posSR];
// find in List Resources
                        for (let posPR = 0; posPR<ListResources.length; posPR++){
                            if (ListResources[posPR].id == itemResource.idResource){
// find in local Resources array
                                let boolResourceFound = false;
                                let posR = 0;
                                for (posR = 0; posR<Resources.length; posR++){
                                    if (ListResources[posPR].id == Resources[posR].Resource.id){
                                        boolResourceFound = true;
                                        break;
                                    }
                                }
                                if (!boolResourceFound){
                                    Resources.push({Resource:ListResources[posPR], Relevances:[]})
                                    posR = Resources.length -1;
                                }

                                if (itemResource.relevance){
                                    Resources[posR].Relevances.push({label:itemResource.relevance,URL:itemResource.URL})
                                }

                                break;
                            }
                        }
                            
                    }

                    return(
                        <div>
                            <div className="publish-sectionheading">Resources</div>
                            <ul>
                                {
                                    Resources.map(
                                        (Resource, posResource) => 
                                        {
                                            let ResourceLink = Resource.Resource.name
                                            if (Resource.Resource.URL){
                                                ResourceLink = <a href={Resource.Resource.URL}>{ResourceLink}</a>
                                            }

                                            return(
                                                <li key={posResource}>
                                                    {ResourceLink}
                                                    <ul>

                                                        {
                                                            Resource.Relevances.map(
                                                                (Relevance, posRelevance) => 
                                                                {

                                                                    let RelevanceLink = Relevance.label;
                                                                    if (Relevance.URL){
                                                                        RelevanceLink = <a href={Relevance.URL}>{RelevanceLink}</a>
                                                                    }
                    
                                                                    return(
                                                                        <li key={posRelevance}>
                                                                            {RelevanceLink}
                                                                        </li>
                                                                    )
                                                                }
                                                            )
                                                        }


                                                    
                                                    </ul>
                                                </li>
                                            )
                                        }
                                    )
                                }
                            </ul>
                        </div>
                    )
                }
            }
        }
        return null;
    }

    StepResources(Step){

        if ("Resources" in Step){
            if (isArray(Step.Resources)){
                if (Step.Resources.length > 0){
                    if (isArray(this.state.ProcessResources)){

// sort by Resource
                        let Resources = []
                        for (let posSR = 0; posSR<Step.Resources.length; posSR++){
                            let StepResource = Step.Resources[posSR];
    // find in Process Resources
                            for (let posPR = 0; posPR<this.state.ProcessResources.length; posPR++){
                                if (this.state.ProcessResources[posPR].id == StepResource.idResource){
    // find in local Resources array
                                    let boolResourceFound = false;
                                    let posR = 0;
                                    for (posR = 0; posR<Resources.length; posR++){
                                        if (this.state.ProcessResources[posPR].id == Resources[posR].Resource.id){
                                            boolResourceFound = true;
                                            break;
                                        }
                                    }
                                    if (!boolResourceFound){
                                        Resources.push({Resource:this.state.ProcessResources[posPR], Relevances:[]})
                                        posR = Resources.length -1;
                                    }

                                    if (StepResource.relevance){
                                        Resources[posR].Relevances.push({label:StepResource.relevance,URL:StepResource.URL})
                                    }

                                    break;
                                }
                            }
                                
                        }

                        return(
                            <div>
                                <div className="publish-sectionheading">Resources</div>
                                <ul>
                                    {
                                        Resources.map(
                                            (Resource, posResource) => 
                                            {
                                                let ResourceLink = Resource.Resource.name
                                                if (Resource.Resource.URL){
                                                    ResourceLink = <a href={Resource.Resource.URL}>{ResourceLink}</a>
                                                }

                                                return(
                                                    <li key={posResource}>
                                                        {ResourceLink}
                                                        <ul>

                                                            {
                                                                Resource.Relevances.map(
                                                                    (Relevance, posRelevance) => 
                                                                    {

                                                                        let RelevanceLink = Relevance.label;
                                                                        if (Relevance.URL){
                                                                            RelevanceLink = <a href={Relevance.URL}>{RelevanceLink}</a>
                                                                        }
                        
                                                                        return(
                                                                            <li key={posRelevance}>
                                                                                {RelevanceLink}
                                                                            </li>
                                                                        )
                                                                    }
                                                                )
                                                            }


                                                        
                                                        </ul>
                                                    </li>
                                                )
                                            }
                                        )
                                    }
                                </ul>
                            </div>
                        )
                    }
                }
            }
        }
        return null;
    }

    StepArtefacts(Step){

        if ("Artefacts" in Step){
            if (isArray(Step.Artefacts)){
                if (Step.Artefacts.length > 0){
                    if (isArray(this.state.ProcessArtefacts)){
                        return(
                            <div>
                                <div className="publish-sectionheading">Inputs and Outputs</div>
                                <ul>
                                    {
                                        Step.Artefacts.map(
                                            (StepArtefact, posStepArtefact) => 
                                            {

                                                let Artefact = null;
                                                for (let posArtefact=0; posArtefact<this.state.ProcessArtefacts.length; posArtefact++){
                                                    if (this.state.ProcessArtefacts[posArtefact].id == StepArtefact.idArtefact){
                                                        Artefact = this.state.ProcessArtefacts[posArtefact];

                                                        if (Artefact){
                                                            return (
                                                                <li key={posStepArtefact}>
                                                                    <PD.PdNavLink to={`${this.props.location.pathname}?idProcess=${this.state.idProcess}&section=artefacts&seq=${posArtefact+1}`}>{Artefact.name}</PD.PdNavLink>
                                                                </li>
                                                            )
                                                        }
                                                    }
                                                }
                                                return null;
                                            }
                                        )
                                    }
                                </ul>
                            </div>
                        )
                    }
                }
            }
        }
        return null;
    }


    NextSteps(Step){

        let nextSteps = [];

        if (this.viz){
            if (this.viz.nodes){
// find flows for this step
                for (let posNode=0;posNode<this.viz.nodes.length;posNode++){
                    let Node = this.viz.nodes[posNode];
                    if (Node.Step){
                        if (Node.Step.Flows){
                            for (let posFlow=0;posFlow<Node.Step.Flows.length;posFlow++){
                                let Flow = Node.Step.Flows[posFlow];
                                if (Flow){
                                    if (Flow.seqStep){
                                        if (Flow.seqStep == Step.seq){
                                            let nextStep = {};
                                            nextStep.seqStep = Node.seqStep;
                                            if (Flow.seqOption){
                                                nextStep.seqOption = Flow.seqOption;
                                            }
                                            nextStep.Step = this.state.Process.Steps[nextStep.seqStep-1];
                                            nextSteps.push(nextStep);
                                        }
                                    }
                                }                                
                            }
                        }
                    }
                }


            }
        }

        if (nextSteps.length > 0){
            return(

                <div>
                    <div className="publish-sectionheading">Next Step</div>
                    <ul>
                        {
                            nextSteps.map(
                                (nextStep, posNextStep) => 
                                {

                                    if (nextStep.Step){

                                        let LinkLabel = nextStep.Step.name;
                                        if (nextStep.seqOption){
                                            if (Step.Options){
                                                if (nextStep.seqOption <= Step.Options.length){
                                                    LinkLabel = `${Step.Options[nextStep.seqOption -1].label} - ${LinkLabel}`
                                                }
                                            }
                                        }


                                        return (
                                            <li key={posNextStep}>
                                                <PD.PdNavLink to={`${this.props.location.pathname}?idProcess=${this.state.idProcess}&section=steps&seq=${nextStep.seqStep}`}>{LinkLabel}</PD.PdNavLink>
                                            </li>
                                        )
                                    }
                                }
                            )
                        }
                    </ul>
                </div>
            )
        }

        return null;

    }

    PreviousSteps(Step){

        let prevSteps = [];

        if (this.viz){
            if (this.viz.nodes){
// find this Step and look for Flows
                for (let posNode=0;posNode<this.viz.nodes.length;posNode++){
                    let Node = this.viz.nodes[posNode];
                    if (Node.Step){
                        if (Node.seqStep == Step.seq){
                            if (Node.Step.Flows){
                                for (let posFlow=0;posFlow<Node.Step.Flows.length;posFlow++){
                                    let Flow = Node.Step.Flows[posFlow];
                                    if (Flow){
                                        if (Flow.seqStep){
                                            if (this.state.Process.Steps){
                                                let prevStep = {};
                                                prevStep.seqStep = Flow.seqStep;
                                                if (Flow.seqOption){
                                                    prevStep.seqOption = Flow.seqOption;
                                                }
                                                prevStep.Step = this.state.Process.Steps[Flow.seqStep-1];
                                                prevSteps.push(prevStep);
                                            }
                                        }
                                    }                                
                                }
                            }
                        }
                    }
                }


            }
        }

        if (prevSteps.length > 0){
            return(

                <div>
                    <div className="publish-sectionheading">Previous Step</div>
                    <ul>
                        {
                            prevSteps.map(
                                (prevStep, posPrevStep) => 
                                {

                                    if (prevStep.Step){

                                        let LinkLabel = prevStep.Step.name;
                                        if (prevStep.seqOption){
                                            if (prevStep.Step.Options){
                                                if (prevStep.seqOption <= prevStep.Step.Options.length){
                                                    LinkLabel += ` - ${prevStep.Step.Options[prevStep.seqOption -1].label}`
                                                }
                                            }
                                        }


                                        return (
                                            <li key={posPrevStep}>
                                                <PD.PdNavLink to={`${this.props.location.pathname}?idProcess=${this.state.idProcess}&section=steps&seq=${prevStep.seqStep}`}>{LinkLabel}</PD.PdNavLink>
                                            </li>
                                        )
                                    }
                                }
                            )
                        }
                    </ul>
                </div>
            )
        }

        return null;

    }


    Artefacts(){

        return <></>

    }



    viewArtefact(Artefact){

        if (Artefact){
            return(
                <div className='dsdPublish'>
                    <div style={{marginBottom:"50px"}}>
                        <div className="publish-contentheading">{Artefact.name}</div>
                       <SanitizeHTML className="dsdPublishHTML" style={{marginBottom:"15px"}} html={Artefact.description}/>

                       {this.ListResources(Artefact.Resources, this.state.ArtefactResources)}
                       {this.ListArtefactSteps(this.state.ArtefactSteps)}
                    </div>
                </div>

            )
        };
        return null;
    };


    setLayoutContent(){

        if (this.context.hasLayout){

            let OldContent = JSON.parse(localStorage.getItem('dsdContent'));

            let Breadcrumb = {Items:[]};

            let boolCrumbFound = false;
            let boolTopFound = false;

            if (OldContent){
                if (OldContent.Breadcrumb){
                    if (OldContent.Breadcrumb.Items){
    // check if the process is already in the Breadcrumb
                        for (let posCrumb=0; posCrumb<OldContent.Breadcrumb.Items.length; posCrumb++){
                            let crumb = OldContent.Breadcrumb.Items[posCrumb]
                            Breadcrumb.Items.push(crumb);
                            if (crumb.id == this.state.idProcess){
                                boolCrumbFound = true;
                                break;
                            }
                        }
                    }
                }
            }

            if (!boolCrumbFound){
                let crumb = {};
                crumb.label = this.state.Process.name;
                crumb.id = this.state.idProcess;
                crumb.link = `${this.props.location.pathname}?idProcess=${this.state.idProcess}`;
                Breadcrumb.Items.push(crumb);
            }
// check the top Process is first in the breadcrumb
            if (Breadcrumb.Items.length > 0){
                if (Breadcrumb.Items[0].id == this.state.idTopProcess){
                    boolTopFound = true;
                }
            }
            if (!boolTopFound){
                let crumb = {};
                crumb.label = "Top";
                if (this.state.TopProcess){
                    crumb.label=this.state.TopProcess.name;
                }
                crumb.id = this.state.idTopProcess;
                crumb.link = this.props.location.pathname;
                Breadcrumb.Items = [crumb].concat(Breadcrumb.Items);
            }


            let Content = {};
            Content.Breadcrumb = Breadcrumb;

            Content.title = this.state.title;
            Content.processName = this.state.Process.name;

            Content.Sections = [];

            let Introduction = {};
            Introduction.title = "Introduction";
            Introduction.link = `${this.props.location.pathname}?idProcess=${this.state.idProcess}&section=introduction`
            if (this.state.section){
                if (this.state.section == "introduction"){
                    Introduction.highlight = true;
                }
            }
            Content.Sections.push(Introduction);

            let Process = {};
            Process.title = "The Process";
            Process.link = `${this.props.location.pathname}?idProcess=${this.state.idProcess}&section=process`
            if (this.state.section){
                if (this.state.section == "process"){
                    Process.highlight = true;
                }
            }
            Content.Sections.push(Process);

            if (this.state.Process.Steps){
                if (this.state.Process.Steps.length > 0){
                    let Steps = {};
                    Steps.title = "Steps";
                    Steps.Items = [];

// put steps into the order that they appear in the process 

                    if (this.viz){
                        if (this.viz.nodes){
                            let sortNodes = [];
                            let stopRank = 0;

                            for (let posNode in this.viz.nodes){
                                let Node = this.viz.nodes[posNode];
                                if (Node.Rank > stopRank){
                                    stopRank = Node.Rank;
                                }
                            }
                            stopRank++;

                            for (let Rank=0;Rank<stopRank;Rank++){

                                for (let posNode in this.viz.nodes){
                                    let Node = this.viz.nodes[posNode];
                                    if (Node.Rank == Rank){
                                        sortNodes.push(Node)
                                    }
                                }
                            }


                            for (let posNode=0;posNode<sortNodes.length;posNode++){
                                let Node = sortNodes[posNode];
                                if (Node.Step){
                                    let ProcessStep = Node.Step;
                                    if (ProcessStep.name){
                                        let Item = {}
                                        Item.label = ProcessStep.name;

                                        Item.link = `${this.props.location.pathname}?idProcess=${this.state.idProcess}&idStep=${Node.Step._id}&section=steps`

                                        if (this.state.section){
                                            if (this.state.section == "steps"){
                                                if (this.state.idStep){
                                                    if (this.state.idStep == Node.Step._id){
                                                        Item.highlight = true;
                                                    }
                                                }
                                            }
                                        }

                                        Steps.Items.push(Item);
                                    }
                                }
                            }

                        }
                    }

                    Content.Sections.push(Steps);
                }
            }


            if (this.state.ProcessArtefacts){
                if (this.state.ProcessArtefacts.length > 0){
                    let Artefacts = {};
                    Artefacts.title = "Inputs and Outputs";
                    Artefacts.Items = [];
                    for (let posArtefact=0;posArtefact<this.state.ProcessArtefacts.length;posArtefact++){
                        let ProcessArtefact = this.state.ProcessArtefacts[posArtefact]; 
                        let Item = {}
                        Item.label = ProcessArtefact.name;
                        Item.link = `${this.props.location.pathname}?idProcess=${this.state.idProcess}&section=artefacts&seq=${posArtefact+1}`

                        if (this.state.section){
                            if (this.state.section == "artefacts"){
                                if (this.state.seq){
                                    if (this.state.seq == posArtefact+1){
                                        Item.highlight = true;
                                    }
                                }
                            }
                        }

                        Artefacts.Items.push(Item);
                    }
                    Content.Sections.push(Artefacts);
                }
            }


            if (this.state.ProcessResources){
                if (this.state.ProcessResources.length > 0){
                    let Resources = {};
                    Resources.title = "Resources";
                    Resources.Items = [];
                    for (let posResource=0;posResource<this.state.ProcessResources.length;posResource++){
                        let ProcessResource = this.state.ProcessResources[posResource]; 
                        let Item = {}
                        Item.label = ProcessResource.name;
                        Item.link = ProcessResource.URL;

                        if (this.state.section){
                            if (this.state.section == "resources"){
                                if (this.state.seq){
                                    if (this.state.seq == posResource+1){
                                        Item.highlight = true;
                                    }
                                }
                            }
                        }

                        Resources.Items.push(Item);
                    }
                    Content.Sections.push(Resources);
                }
            }

            this.context.Layout.setLayoutContent(Content)

            localStorage.setItem("dsdContent",JSON.stringify(Content));

        }

        return;
    }


    render() {

        if (this.state.isLoaded) {
            if (this.state.Process){
                if (this.state.Process.id == this.state.idProcess){

                    this.setLayoutContent();

                    return (
                        <React.Fragment>
                            <link rel="stylesheet" type="text/css" href="./style/publish/style.css" />
                            <div className='dsdPublish'>
                                {this.view(this.state.Process)}
                            </div>
                        </React.Fragment>
                    )
                }
            }
        }

        return <div className='wait'/>;

    };    
};
        
// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <PublishProcess {...props} navigation={navigation} location={location} params={params} />;
  }
