import React from "react"

import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';


import { isNull, isEqual, isArray } from "lodash";

import {hasProperty, formatText, formatDate} from '../utils/utils';
import {cloneObject, getParam, truncate} from '../utils/utils';
import {SanitizeHTML,  HtmlToText} from '../utils/utils';
import { Graphviz } from "graphviz-react";

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';


import ViewConceptModel from "../components/ViewConceptModel";
import ViewLogicalModel from "../components/ViewLogicalModel";
import ViewDataDictionary from "../components/ViewDataDictionary";


class ViewVersion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };


        this.handleVizClick = this.handleVizClick.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

    }

    componentDidMount(){

        this.setup();
 
     }

     componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.loadData){
                this.loadData(this.state.idVersion);
                StateChange.loadData = false;
            }

            if (this.state.isLoaded){
                if (this.state.Version.Document){
                    StateChange.Document = this.state.Version.Document;
                }
            }

            this.setState(StateChange);
        }

     }



    setup(){

        let StateChange = {
            idVersion: null,
            type: null,
            Version: null,
            Document: null,
            isLoaded: false,
            loadData: false

        }

        StateChange.idVersion = getParam('idVersion', this.props);
        if (StateChange.idVersion){
            StateChange.loadData = true;
        }

        StateChange.Document = getParam('Document', this.props);
        if (StateChange.Document){
            StateChange.loadData = false;
            StateChange.isLoaded = true;
        }


        StateChange.Version = getParam('Version', this.props);
        if (StateChange.Version){
            StateChange.Document = StateChange.Version.Document;
            StateChange.loadData = false;
            StateChange.isLoaded = true;
        }

        StateChange.type = getParam('type', this.props);

        this.setState(StateChange);

    }

    loadData(id){

        if (id){
            this.dsd.getVersion(id);
        }

        return;
    }

    handleVizClick(event) {

        event.preventDefault();

    };

    viewVersion(Version){

        if (Version){
            return(
                <fieldset>
                    <legend>Named Version</legend>
                    <PD.Table className="panel">
                        <PD.Tbody>                                

                            {hasProperty(Version,'name') &&
                                <PD.Tr>                                
                                    <PD.Th>Name</PD.Th><PD.Td>{Version.name}</PD.Td>
                                </PD.Tr>
                            }

                            {hasProperty(Version,'description') &&
                                <PD.Tr>
                                    <PD.Th>Description</PD.Th><PD.Td><SanitizeHTML html={Version.description}/></PD.Td>
                                </PD.Tr>
                            }

                            {hasProperty(Version,'createdAt') &&
                                <PD.Tr>
                                    <PD.Th>Date/Time</PD.Th><PD.Td>{formatDate(Version.createdAt,'dmy hms')}</PD.Td>
                                </PD.Tr>
                            }

                        </PD.Tbody>
                    </PD.Table>
                </fieldset>
            )
        };
        return null;
    };

    viewConceptModel(ConceptModel){
        if (ConceptModel){

            let countConcepts = 0;
            if (ConceptModel.Concepts){
                if (ConceptModel.Concepts.length){
                    countConcepts = ConceptModel.Concepts.length;
                }
            }

            let countLinks = 0;
            if (ConceptModel.Links){
                if (ConceptModel.Links.length){
                    countLinks = ConceptModel.Links.length;
                }
            }

            const viz = this.dsd.vizConceptModel({ConceptModel:ConceptModel}, {}, 'dot' );

            let vizoptions = {}
            vizoptions.engine = 'dot';
            vizoptions.fit = false;
            vizoptions.width = null;
            vizoptions.height = null;
            vizoptions.zoom = false;
            vizoptions.scale = 1;

            let styleDiv = {}


            return(
                <div>
                    <ViewConceptModel ConceptModel={ConceptModel}/>

                    <fieldset>
                        <legend>visualisation</legend>
                        <div name="divVizConceptModel" style={styleDiv} onClick={this.handleVizClick}>
                            <Graphviz dot={viz.dot} options={vizoptions}/>
                        </div>
                    </fieldset>


                    {countConcepts &&
                        <fieldset>
                            <legend>Concepts</legend>
                            <PD.Table className="list">
                                <PD.Thead>
                                    <PD.Tr>
                                        <PD.Th>Name</PD.Th>
                                        <PD.Th>Status</PD.Th>                            
                                        <PD.Th>Description</PD.Th>
                                    </PD.Tr>
                                </PD.Thead>
                                <PD.Tbody>
                                    {
                                        ConceptModel.Concepts.map(
                                            (Concept, pos) => 
                                            {                
                                                return (

                                                    <PD.Tr key={pos}>
                                                        <PD.Td>
                                                            {Concept.name}
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {Concept.status}
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {formatText(truncate(HtmlToText(Concept.description), 400))}
                                                        </PD.Td>
                                                    </PD.Tr>

                                                )
                                            }
                                    
                                        )
                                    }
                                </PD.Tbody>
                            </PD.Table>
                        </fieldset>
                    }



                    {countLinks &&
                        <fieldset>
                            <legend>Links</legend>
                            <PD.Table className="list">
                                <PD.Thead>
                                    <PD.Tr>
                                        <PD.Th>Concept From</PD.Th>                            
                                        <PD.Th>Link</PD.Th>
                                        <PD.Th>Concept To</PD.Th>                            
                                        <PD.Th>Status</PD.Th>                            
                                        <PD.Th>Description</PD.Th>
                                    </PD.Tr>
                                </PD.Thead>
                                <PD.Tbody>
                                    {
                                        ConceptModel.Links.map(
                                            (Link, pos) => 
                                            {                
                                                return (

                                                    <PD.Tr key={pos}>
                                                        <PD.Td>{Link.ConceptFrom.name}</PD.Td>
                                                        <PD.Td>{Link.name}</PD.Td>
                                                        <PD.Td>{Link.ConceptTo.name}</PD.Td>
                                                        <PD.Td>
                                                            {Link.status}
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {formatText(truncate(HtmlToText(Link.description), 400))}
                                                        </PD.Td>

                                                    </PD.Tr>

                                                )
                                            }
                                    
                                        )
                                    }
                                </PD.Tbody>
                            </PD.Table>
                        </fieldset>
                    }

                </div>
            )
        }
    }


    viewLogicalModel(LogicalModel){
        if (LogicalModel){

            let countEntities = 0;
            if (LogicalModel.Entities){
                if (LogicalModel.Entities.length){
                    countEntities = LogicalModel.Entities.length;
                }
            }

            let countRelationships = 0;
            if (LogicalModel.Relationships){
                if (LogicalModel.Relationships.length){
                    countRelationships = LogicalModel.Relationships.length;
                }
            }

            const viz = this.dsd.vizLogicalModel({LogicalModel:LogicalModel}, {}, 'dot' );

            let vizoptions = {}
            vizoptions.engine = 'dot';
            vizoptions.fit = false;
            vizoptions.width = null;
            vizoptions.height = null;
            vizoptions.zoom = false;
            vizoptions.scale = 1;

            let styleDiv = {}


            return(
                <div>
                    <ViewLogicalModel LogicalModel={LogicalModel}/>

                    <fieldset>
                        <legend>visualisation</legend>
                        <div name="divVizLogicalModel" style={styleDiv} onClick={this.handleVizClick}>
                            <Graphviz dot={viz.dot} options={vizoptions}/>
                        </div>
                    </fieldset>


                    {countEntities &&
                        <fieldset>
                            <legend>Entities</legend>
                            <PD.Table className="list">
                                <PD.Thead>
                                    <PD.Tr>
                                        <PD.Th>Name</PD.Th>
                                        <PD.Th>Status</PD.Th>                            
                                        <PD.Th>Description</PD.Th>
                                    </PD.Tr>
                                </PD.Thead>
                                <PD.Tbody>
                                    {
                                        LogicalModel.Entities.map(
                                            (Entity, pos) => 
                                            {                
                                                return (

                                                    <PD.Tr key={pos}>
                                                        <PD.Td>
                                                            {Entity.name}
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {Entity.status}
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {formatText(truncate(HtmlToText(Entity.description), 400))}
                                                        </PD.Td>
                                                    </PD.Tr>

                                                )
                                            }
                                    
                                        )
                                    }
                                </PD.Tbody>
                            </PD.Table>
                        </fieldset>
                    }



                    {countRelationships &&
                        <fieldset>
                            <legend>Relationships</legend>
                            <PD.Table className="list">
                                <PD.Thead>
                                    <PD.Tr>
                                        <PD.Th>Name</PD.Th>                            
                                        <PD.Th>Status</PD.Th>                            
                                        <PD.Th>Description</PD.Th>
                                    </PD.Tr>
                                </PD.Thead>
                                <PD.Tbody>
                                    {
                                        LogicalModel.Relationships.map(
                                            (Relationship, pos) => 
                                            {                
                                                return (

                                                    <PD.Tr key={pos}>
                                                        <PD.Td>{Relationship.name}</PD.Td>
                                                        <PD.Td>
                                                            {Relationship.status}
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {formatText(truncate(HtmlToText(Relationship.description), 400))}
                                                        </PD.Td>

                                                    </PD.Tr>

                                                )
                                            }
                                    
                                        )
                                    }
                                </PD.Tbody>
                            </PD.Table>
                        </fieldset>
                    }

                </div>
            )
        }
    }


    viewDataDictionary(DataDictionary){
        if (DataDictionary){

            let countStructures = 0;
            if (DataDictionary.Structures){
                if (DataDictionary.Structures.length){
                    countStructures = DataDictionary.Structures.length;
                }
            }

            return(
                <div>
                    <ViewDataDictionary DataDictionary={DataDictionary}/>

                    {countStructures &&
                        <fieldset>
                            <legend>Structues</legend>
                            <PD.Table className="list">
                                <PD.Thead>
                                    <PD.Tr>
                                        <PD.Th>Name</PD.Th>
                                        <PD.Th>Status</PD.Th>                            
                                        <PD.Th>Description</PD.Th>
                                    </PD.Tr>
                                </PD.Thead>
                                <PD.Tbody>
                                    {
                                        DataDictionary.Structures.map(
                                            (Structure, pos) => 
                                            {                
                                                return (

                                                    <PD.Tr key={pos}>
                                                        <PD.Td>
                                                            {Structure.name}
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {Structure.status}
                                                        </PD.Td>
                                                        <PD.Td>
                                                            {formatText(truncate(HtmlToText(Structure.description), 400))}
                                                        </PD.Td>
                                                    </PD.Tr>

                                                )
                                            }
                                    
                                        )
                                    }
                                </PD.Tbody>
                            </PD.Table>
                        </fieldset>
                    }

                </div>
            )
        }
    }

    render() {

        if (this.state.isLoaded){
            return(
                <div>
                    {this.state.Version &&
                        this.viewVersion(this.state.Version)
                    }
                    {(() => {
                        switch (this.state.type) {
                            case "conceptmodel":
                                return (this.viewConceptModel(this.state.Document));
                            case "logicalmodel":
                                return (this.viewLogicalModel(this.state.Document));
                            case "datadictionary":
                                return (this.viewDataDictionary(this.state.Document));
        
                        }
                    })()}


                </div>
            )
        }
        return <div/>

    };    
};
        
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <ViewVersion {...props} navigation={navigation} location={location} params={params} />;
  }
