import { isNull, isEqual, isArray } from "lodash";
import axios from 'axios';
import { FileSpreadsheet } from "react-bootstrap-icons";
import { cloneObject, dotField, wrap, round } from "../utils/utils";

class dsd{

    StateObject = null;

    config = {

        Features :[
            {
                label:"Concept Models",
                description:"Concept Models",
                link:"/conceptmodels"
            },
            {
                label:"Logical Models",
                description:"Logical Models",
                link:"/logicalmodels"
            },
            {
                label:"Data Dictionaries",
                description:"Data Dictionaries",
                link:"/datadictionaries"
            },
            {
                label:"Packages",
                description:"Packages",
                link:"/packages"
            },
            {
                label:"Lists",
                description:"Lists",
                link:"/lists"
            },
            {
                label:"Patterns",
                description:"Patterns",
                link:"/patterns"
            },
            {
                label:"Processes",
                description:"Processes",
                link:"/processes"
            },
            {
                label:"Artefacts",
                description:"Artefacts",
                link:"/artefacts"
            },
            {
                label:"Resources",
                description:"Resources",
                link:"/resources"
            },
            {
                label:"Narratives",
                description:"Narratives",
                link:"/narratives"
            }

        ],

        ComponentStatuses :[
            'under construction',
            'private',
            'published',
            'deprecated'
            ],
    
        ProcessStatuses :[
        'under construction',
        'private',
        'published',
        'deprecated'
        ],
        ArtefactStatuses :[
            'under construction',
            'private',
            'published',
            'deprecated'
        ],
        ArtefactUses :[
            'input',
            'output',
            'updated'
        ],

        ResourceStatuses :[
            'under construction',
            'private',
            'published',
            'deprecated'
        ],

        ConceptStatuses :[
            'in use',
            'deprecated'
        ],

        LinkStatuses :[
            'in use',
            'deprecated'
        ],

        EntityStatuses :[
            'in use',
            'deprecated'
        ],

        RelationshipStatuses :[
            'in use',
            'deprecated'
        ],

        RelationshipCardinalities :[
            'one to one',
            'one to many',
            'many to many'
        ],

        StructureStatuses :[
            'in use',
            'deprecated'
        ],

        ActorStatuses :[
            'in use',
            'deprecated'
        ],

        SystemStatuses :[
            'in use',
            'deprecated'
        ],

        UseCaseStatuses :[
            'in use',
            'deprecated'
        ],

        AssociationStatuses :[
            'in use',
            'deprecated'
        ],


        xDataTypes :[
            'line',
            'text',
            'number',
            'currency',
            'date',
            'yes/no',
            'lookup'
        ],

        DataTypes : {
            'line':{type:'xsd:string'},
            'text':{type:'xsd:string'},
            'number':{type:'xsd:decimal'},
            'currency':{type:'xsd:decimal'},
            'date':{type:'xsd:date'},
            'yes/no':{type:'xsd:boolean'},
            'lookup':{type:'xsd:string'}
        },


        Occurs :[
            'once',
            'many',
        ],

        Required :[
            'yes',
            'no',
        ],

        TermTypeStatuses :[
            'in use',
            'deprecated'
        ],


        ActorTypes : {
            role:{
                label:"role",
                image:"stick.png"
            },
            interface: {
                label:"interface",
                image:"interface.jpg"
            },
            system: {
                label:"system",
                image:"system.png"
            }

        },

        AssociationTypes : {
            initiation:{
                label:"initiation"
            },
            communication: {
                label:"communication"
            }
        }



    }


    constructor(parent) {


        let urlOrigin = window.location.origin;
        let urlParts = new URL(urlOrigin);
    
        let urlAPI = `${urlParts.protocol}//${urlParts.hostname}/api/dsd`;

        if (urlParts.hostname == 'localhost'){
            urlAPI = `${urlParts.protocol}//${urlParts.hostname}:5050`;
        }
        if (urlParts.hostname.startsWith('192.168')){
            urlAPI = `${urlParts.protocol}//${urlParts.hostname}:5050`;
        }

// //////////// live api
//        urlAPI = "http://52.56.166.229/api/dsd";
// ////////////   


        this.httpConfig = axios.create({
            baseURL: urlAPI,
            headers: {
              "Content-type": "application/json"
            }
        });

        if (parent){
            this.StateObject = parent;
        }

     this.user = null;
        this.user = JSON.parse(localStorage.getItem('user'));
        this.loggedon = false;
    
        if (this.user){
            if (this.user.hasOwnProperty("id")){
                this.loggedon = true;
            }
        }
    
        if (global.model){
            this.model = global.model;
        }
        else{
            this.getModel();
        }

    }


    getModel(){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        this.httpConfig.get(`/model`, headers)
            .then(response => {
                this.model = response.data;
                global.model = this.model;
                this.StateObject.setState({ ModelLoaded: true });
              })
              .catch(e => {
                this.StateObject.setState({ isFailed: true });

                console.log(e);
              });
    
        return;
    
    };
    

    authHeader(){


        let user = JSON.parse(localStorage.getItem('user'));

       if (user && user.authdata) {
           return { 'Authorization': 'Basic ' + user.authdata };
       } else {
           return {};
       }
   };


    addProcess( Data = {}){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }
    
        let payload = this.payloadProcess(Data)
    
        this.httpConfig.post(`/processes`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange.isUpdated = true;
                StateChange.idProcess = response.data._id;
                this.StateObject.setState(StateChange);
              })
              .catch(e => {
                this.StateObject.setState({ isFailed: true });
                console.log(e);
              });
    
        return;
    }
    
    
    editProcess(id = null, Data = {}){
    
        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }
    
    
        let payload = this.payloadProcess(Data)
    
        this.httpConfig.put(`/processes/${id}`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange.isUpdated = true;
                this.StateObject.setState(StateChange);
              })
              .catch(e => {
                this.StateObject.setState({ isFailed: true });
                console.log(e);
              });
    
        return true;
    };
    
    payloadProcess(data){
    
        let payload = {}
        payload.name = data.name;
        payload.description = data.description;
        payload.proposals = data.proposals;
        payload.status = data.status;
    
        return payload;
    
    }
    
    getProcess(id=null,  nameStateVar = "Process", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        this.httpConfig.get(`/processes/${id}`, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsLoadedVar] = true;
                StateChange[nameStateVar] = this.dataProcess(response.data);
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsFailedVar] = true;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;

    };

    

    listProcesses(Filters={}, nameStateVar = "Processes"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }
    
        let urlAPI = "/processes"
        if (Object.keys(Filters).length > 0){
            urlAPI += "?"
        }
        
        if (Filters.idUser){
            urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
        }
    
        if (Filters.scope){
            urlAPI += `scope=${encodeURIComponent(Filters.scope)}&`;
        }
    
        if (Filters.status){
            urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
        }
    
        if (Filters.search){
            urlAPI += `search=${encodeURIComponent(Filters.search)}&`;
        }

        if (Filters.idSubProcess){
            urlAPI += `idSubProcess=${encodeURIComponent(Filters.idSubProcess)}&`;
        }

        if (Filters.idArtefact){
            urlAPI += `idArtefact=${encodeURIComponent(Filters.idArtefact)}&`;
        }
        if (Filters.idResource){
            urlAPI += `idResource=${encodeURIComponent(Filters.idResource)}&`;
        }

        this.httpConfig.get(urlAPI, headers)
            .then(response => {
    
                let arrProcesses = [];
                for (let pos=0; pos<response.data.length;pos++){
                  arrProcesses.push(this.dataProcess(response.data[pos]));
                }
    
                let StateChange = {};
                StateChange.isLoaded = true;
                StateChange[nameStateVar] = arrProcesses;
                this.StateObject.setState(StateChange);
              })
              .catch(e => {
                this.StateObject.setState({ isFailed: true });
                console.log(e);
              });
    
        return;
    
    };
    



    dataProcess(data){

        let Process = data;
    
        return Process;
    
    }

// ==== Steps



    addStepToProcess( idProcess, Data = {}, nameIdVar = "idStep", nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        let payload = this.payloadStep(Data)

        this.httpConfig.post(`/processes/${idProcess}/steps`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsUpdatedVar] = true;
                StateChange[nameIdVar] = response.data._id;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsErrorVar] = true;
                StateChange[nameErrorMessageVar] = e.message;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;
    }


    editStep(id = null, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){


        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        let payload = this.payloadStep(Data);
        let idProcess = null;
        if (Data.Process) {
            idProcess = Data.Process._id;
        }

        this.httpConfig.put(`/processes/${idProcess}/steps/${id}`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsUpdatedVar] = true;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsErrorVar] = true;
                StateChange[nameErrorMessageVar] = e.message;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return true;
    };


    removeStep(idProcess, idStep, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }
    
        this.httpConfig.delete(`/processes/${idProcess}/steps/${idStep}`, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsUpdatedVar] = true;
                this.StateObject.setState(StateChange);
              })
              .catch(e => {
                let StateChange = {};
                StateChange[nameIsErrorVar] = true;
                StateChange[nameErrorMessageVar] = e.message;
                this.StateObject.setState(StateChange);
                console.log(e);
              });

        return true;
    };

    payloadStep(data){
        
        let step = {}
        step.name = data.name;
        if (data.description){
            step.description = data.description;
        }
        if (data.organisation){
            step.organisation = data.organisation;
        }
        if (data.role){
            step.role = data.role;
        }


        if (data.type){
            step.type = data.type;
        }
        if (data.multiinstance){
            step.multiinstance = data.multiinstance;
        }
        if (data.foreach){
            step.foreach = data.foreach;
        }
        if (data.Options){
            step.Options = data.Options;
        }
        if (data.Flows){
            step.Flows = data.Flows;
        }
        if (data.Artefacts){
            step.Artefacts = data.Artefacts;
        }
        if (data.Resources){
            step.Resources = data.Resources;
        }

        if (data.idSubProcess){
            step.idSubProcess = data.idSubProcess;
        }

        return step;

    }


    listSteps(Filters={}, nameStateVar = "Steps", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        let urlAPI = "/steps"
        if (Object.keys(Filters).length > 0){
            urlAPI += "?"
        }
        
        if (Filters.idUser){
            urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
        }

        if (Filters.status){
            urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
        }

        if (Filters.idProcess){
            urlAPI += `idProcess=${encodeURIComponent(Filters.idProcess)}&`;
        }

        if (Filters.idArtefact){
            urlAPI += `idArtefact=${encodeURIComponent(Filters.idArtefact)}&`;
        }


        if (Filters.idNarrative){
            urlAPI += `idNarrative=${encodeURIComponent(Filters.idNarrative)}&`;
        }


        this.httpConfig.get(urlAPI, headers)
            .then(response => {

                let arr = [];
                for (let pos=0; pos<response.data.length;pos++){
                arr.push(this.dataStep(response.data[pos]));
                }

                let StateChange = {};
                StateChange[nameIsLoadedVar] = true;
                StateChange[nameStateVar] = arr;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsFailedVar] = true;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;

    };


    getStep(id=null,  nameStateVar = "Step", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        this.httpConfig.get(`/steps/${id}`, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsLoadedVar] = true;
                StateChange[nameStateVar] = this.dataStep(response.data);
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsFailedVar] = true;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;

    };

    dataStep(data){

        let obj = data;

        return obj;

    }


    vizProcess(Process, engine = "dot", boolSwimlanes = false, boolArtefacts = false){

        let dotNodes = {};
        let dotEdges = {};
        let dotStartNode = this.makeStartNode();

        dotNodes['start'] = dotStartNode;

// create Step Nodes        
        for (let posStep = 0; posStep < Process.Steps.length; posStep++ ){
            let Step = Process.Steps[posStep];
            let boolMakeStep = true;
            if (isNull(Step)){
                boolMakeStep = false;
            }else{
                if (isEqual(Step,{})){
                    boolMakeStep = false;
                }
                if ( isNull(Step.name)){
                    boolMakeStep = false;
                }
            }

            if (boolMakeStep){
                let idNode = `step_${Step._id}`
                dotNodes[idNode] = this.makeStepNode(Step, idNode);
            }
        };

// create Edges
        for (let posStep = 0; posStep < Process.Steps.length; posStep++ ){
            let Step = Process.Steps[posStep];

            let ToNodeId = `step_${Step._id}`;
            if (ToNodeId in dotNodes){

                let ToStepNode = dotNodes[ToNodeId];

                if (ToStepNode){
                    let boolHasFlow = false;
                    if ("Flows" in Step){
                        for (let posFlow=0; posFlow<Step.Flows.length; posFlow++){
                            let Flow = Step.Flows[posFlow];

                            if (Flow.idStep){

                                let FromNodeId = `step_${Flow.idStep}`;
                                if (FromNodeId in dotNodes){
                                    let FromStepNode = dotNodes[FromNodeId];
                
                                    if (FromStepNode){
                                        boolHasFlow = true;
                                        let Label = null;
                                        let seqOption = 0;
                                        if (Flow.idOption){
                                            if (FromStepNode.Step.Options){
                                                for (let posOption=0; posOption<FromStepNode.Step.Options.length;posOption++){
                                                    let Option = FromStepNode.Step.Options[posOption];
                                                    seqOption = posOption+1;
                                                    if (Option._id == Flow.idOption){
                                                        Label = Option.label;
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                        let dotEdge = this.makeEdge(Label ,FromStepNode, ToStepNode);
                                        dotEdge.seqOption = seqOption;

            // put the edge into the From Node Edge Array, in the order that the Option is defined - so that the 1st parallel is the 'happy path'

                                        let arrNodeEdges = [];
                                        let boolFlowInserted = false;
                                        for (let posEdge=0; posEdge<FromStepNode.Edges.length; posEdge++){
                                            if (!boolFlowInserted){
                                                if (FromStepNode.Edges[posEdge].seqOption > seqOption){
                                                    boolFlowInserted = true;
                                                    arrNodeEdges.push(dotEdge);
                                                }
                                            }
                                            arrNodeEdges.push(FromStepNode.Edges[posEdge]);
                                        }
                                        if (!boolFlowInserted){
                                            boolFlowInserted = true;
                                            arrNodeEdges.push(dotEdge);
                                        }

                                        FromStepNode.Edges = arrNodeEdges;

                                        dotEdges[`flow_${Flow._id}`] = dotEdge;
                                    }        
                                }
                            }
                        }
                    };

                    if (!boolHasFlow){
                        let dotEdge = this.makeEdge("" ,dotStartNode, ToStepNode);
                        dotStartNode.Edges.push(dotEdge);
                        let idEdge = 'start_'+dotStartNode.Edges.length;
                        dotEdges[idEdge] = dotEdge;
                    }

                }
            }
        }


// put nodes into Pools, Lanes        

        let LaneNames = {}
        let seqLane = 1;
        let dotLanes = {};
        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode];
            dotNode.Lane = 1;
            if (boolSwimlanes){
                if ('Step' in dotNode){
                    if (dotNode.Step){
                        let LaneName = '';
                        if ('organisation' in dotNode.Step){
                            LaneName += dotNode.Step.organisation;
                        }
                        if ('role' in dotNode.Step){
                            if (LaneName.length > 0){
                                LaneName += '/';
                            }
                            LaneName += dotNode.Step.role;
                        }
                        if (LaneName){
                            if (!(LaneName in LaneNames)){
                                LaneNames[LaneName] = {}
                                LaneNames[LaneName].seq = ++seqLane;
                            }
                            dotNode.Lane = LaneNames[LaneName].seq;
                        }
                    }
                }
            }
        }


// put nodes into Ranks, Parallels        
        let seqParallel = 0;

        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode];
// find start nodes ( those with no Flows )
            if (dotNode.Step){

                let boolStartNode = true;
                if ("Flows" in dotNode.Step){
                    for (let posFlow = 0; posFlow<dotNode.Step.Flows.length; posFlow++){
                        if (!isNull(dotNode.Step.Flows[posFlow])){
                            let Flow = dotNode.Step.Flows[posFlow]
                            if ("idStep" in Flow){
                                if (Flow.idStep != ""){
                                    boolStartNode = false;
                                }
                            }
                        }
                    }
                }
                if (boolStartNode){
                    dotNode.Rank = 2;
                    dotNode.Parallel = ++seqParallel;

                    this.setNextNodes(dotNode, dotNodes, dotEdges);
                }
            }

        }

        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode];
            if (!(dotNode.Lane in dotLanes)){
                dotLanes[dotNode.Lane] = {maxParallels:0, Parallels:{}}
            }    
            if (dotNode.Parallel > dotLanes[dotNode.Lane].maxParallels){
                dotLanes[dotNode.Lane].maxParallels = dotNode.Parallel;
            }
            if (!(dotNode.Parallel in dotLanes[dotNode.Lane].Parallels)){
                dotLanes[dotNode.Lane].Parallels[dotNode.Parallel] = {type:"step"};
            }
        }



// artefacts
        if (boolArtefacts){
            for (let idNode in dotNodes){
                let dotNode = dotNodes[idNode];

                if (dotNode.type == 'step'){
                    let StepNode = dotNode;

                    if ("Artefacts" in StepNode.Step){
                        if (isArray(StepNode.Step.Artefacts)){
                            for (let posStepArtefact = 0; posStepArtefact < StepNode.Step.Artefacts.length; posStepArtefact++ ){
                                const StepArtefact = StepNode.Step.Artefacts[posStepArtefact];
//                                const idNode = `artefact_${StepArtefact.idArtefact}`;
                                let boolMakeArtefact = true;
                                let ArtefactNode = {};

                                let seqNextNode = 0;
                                for (let ArtefactNode in dotNodes){
                                    if (ArtefactNode.type == 'artefact'){
                                        if (ArtefactNode.idArtefact == StepArtefact.idArtefact){
// duplicate artefacts if they are not within two ranks
                                            seqNextNode++;
                                            if (Math.abs((Number(StepNode.Rank) - Number(ArtefactNode.Rank))) < 2){
                                                boolMakeArtefact = false;
                                            }
                                        }
                                    }
                                }

                                if (boolMakeArtefact){
                                    ArtefactNode = this.makeArtefactNode(StepArtefact, seqNextNode);
        // ? has an artefact parallel been set up?
                                    let seqArtefactParallel = StepNode.Parallel + 1;
                                    let boolMakeArtefactParallel = true;
                                    if (seqArtefactParallel in dotLanes[StepNode.Lane].Parallels){
                                        if (dotLanes[StepNode.Lane].Parallels[seqArtefactParallel].type == 'artefact'){
                                            boolMakeArtefactParallel = false;
                                        }
                                    }
                                    if (boolMakeArtefactParallel){
                                        this.makeArtefactParallel(StepNode.Lane, seqArtefactParallel, dotNodes, dotLanes);
                                    }
// find unoccupied rank 
                                    ArtefactNode.Rank = StepNode.Rank;
                                    ArtefactNode.Lane = StepNode.Lane;
                                    ArtefactNode.Parallel = seqArtefactParallel;
                                    for (let checkNode in dotNodes){
                                        if (checkNode.Lane == ArtefactNode.Lane){
                                            if (checkNode.Parallel == ArtefactNode.Parallel){
                                                if (checkNode.Rank == ArtefactNode.Rank){
                                                    ArtefactNode.Rank++;
                                                }
                                            }
                                        }
                                    }
                                    

                                    if (ArtefactNode.Rank > StepNode.Rank){
                                        const fromRank = StepNode.Rank + 1;
                                        const numRanksToShift = ArtefactNode.Rank - StepNode.Rank
                                        for (let ShiftNode in dotNodes){
                                            if (ShiftNode.Rank >= fromRank){
                                                ShiftNode.Rank = ShiftNode.Rank + numRanksToShift;
                                            }
                                        }
                                    }

                                    dotNodes[ArtefactNode.idNode] = ArtefactNode;
                                }

                                let ArtefactEdge = this.makeArtefactEdge(StepNode, ArtefactNode, StepArtefact.use);
                                let idEdge = `artefact_${StepArtefact.idArtefact}_${StepNode.idNode}`;
                                dotEdges[idEdge] =  ArtefactEdge;
                            }
                        }
                    }
                }
            };
        }

// add stop nodes

        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode];
            let boolAddStopNode = false;
            if (!("Edges" in dotNode)){
                boolAddStopNode = true;
            }else{
                if (dotNode.Edges.length == 0){
                    boolAddStopNode = true;
                }
            }
            switch (dotNode.type){
                case "artefact":
                case "stop":
                    boolAddStopNode = false;
                    break;
                case 'step':
                    if ("Step" in dotNode){
                        switch (dotNode.Step.type){
                            case 'stop':
                                boolAddStopNode = false;
                                break;
                        }
                    }
                    break;
            }

            if (boolAddStopNode){
                let StopNode = this.makeStopNode(dotNode.Rank + 1, dotNode.Lane, dotNode.Parallel); 
                dotNodes[StopNode.idNode] = StopNode;
                let StopEdge = this.makeEdge("",dotNode, StopNode );
                let idEdge = `stop_${dotNode.idNode}`
                dotEdges[idEdge] = StopEdge;
            }
        }
    


        let maxRanks = 0;
        let maxLanes = 0;
        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode];
            if (dotNode.Rank > maxRanks){
                maxRanks = dotNode.Rank;
            }
            if (dotNode.Lane > maxLanes){
                maxLanes = dotNode.Lane;
            }
        }



        let dot = '';

        let Key = {};
    
        dot += `digraph  { 
            overlap=false; 
            splines=true; 
            node [ fixedsize=true, width=2, color=black, fillcolor=lightblue ,fontname="Arial", fontcolor=black, fontsize=7 ]; 
            edge [fontname="Arial", fontsize=7, labelfontname="Arial", labelfontsize=7, len=3.0];            
            `
    
        if (boolSwimlanes){
            dot += `
            rankdir="LR"
            `
        }


        let thisIdNode = null;
        for (let seqRank = 1; seqRank <= maxRanks; seqRank++){
            for (let seqLane = 1; seqLane <= maxLanes; seqLane++){
                if (seqLane in dotLanes){
                    for (let seqParallel = 1; seqParallel <= dotLanes[seqLane].maxParallels; seqParallel++){
                        let boolFound = false;
                        for (let idNode in dotNodes){
                            let dotNode = dotNodes[idNode];
                            if (dotNode.Rank == seqRank){
                                if (dotNode.Lane == seqLane){
                                    if (dotNode.Parallel == seqParallel){
                                        boolFound = true;
                                        dot += `
                                ${this.makeNodeDot(dotNode, true, maxRanks, dotLanes)}`;
                        
                                        thisIdNode = dotNode.idNode;
                                    }
                                }
                            } 
                        }
                        if (engine == 'dot'){
                            if (!boolFound){
                                thisIdNode = `invis_${seqRank}_${seqLane}_${seqParallel}`;
                                dot += this.makeInvisNode(thisIdNode);
                            }

                            if ("idNode" in dotLanes[seqLane].Parallels[seqParallel]){
                                let idNodeFrom = dotLanes[seqLane].Parallels[seqParallel].idNode;
                                let idNodeTo = thisIdNode;

                                let boolInvisEdgeRequired = true;
                                for (let idEdge in dotEdges){
                                    let dotEdge = dotEdges[idEdge];
                                    if (dotEdge.FromNode.idNode == idNodeFrom){
                                        if (dotEdge.ToNode.idNode == idNodeTo){
                                            boolInvisEdgeRequired = false;
                                        }
                                    }
                                }

                                if (boolInvisEdgeRequired){
                                    dot += this.makeInvisEdge(idNodeFrom, idNodeTo);
                                }
                            }

                            dotLanes[seqLane].Parallels[seqParallel].idNode = thisIdNode;
                        }
                    }
                }
            }

        }


// apply flow 

        for (let idEdge in dotEdges){
            let dotEdge = dotEdges[idEdge];
            dot += dotEdge.dot;
        }

        dot += `}
        `; 

        return {"dot":dot,"nodes":dotNodes};

    };


    isExistingEdge(dotEdges, idNodeFrom, idNodeTo){

        let boolIsExistingEdge = false;

        for (let idEdge in dotEdges){
            let dotEdge = dotEdges[idEdge];
            if (dotEdge.FromNode){
                if (dotEdge.ToNode){

                    if (dotEdge.FromNode.idNode == idNodeFrom){
                        if (dotEdge.ToNode.idNode == idNodeTo){
                            boolIsExistingEdge = true;
                        }
                    }

                    if (dotEdge.FromNode.idNode == idNodeTo){
                        if (dotEdge.ToNode.idNode == idNodeFrom){
                            boolIsExistingEdge = true;
                        }
                    }
                }
            }

        }

        return boolIsExistingEdge;

    }


    makeInvisNode(idNode){
        let dot = `${idNode} [label="invis", style="invis"];
      `;

        return dot;
    }

    makeInvisEdge(idNodeFrom, idNodeTo){
        let dot = `${idNodeFrom} -> ${idNodeTo} [label="invis", style="invis"];
        `;

        return dot;
    }


    setNextNodes(dotNode, dotNodes, dotEdges){
//

        let boolHasNextNode = false;

        if ("Edges" in dotNode){            
            for (let posEdge = 0; posEdge<dotNode.Edges.length; posEdge++){
                let NextNode = dotNode.Edges[posEdge].ToNode;
                boolHasNextNode = true;
                if (!("Rank" in NextNode)){
                    NextNode.Rank = dotNode.Rank + 1;
                    NextNode.Lane = dotNode.Lane;
                    NextNode.Parallel = dotNode.Parallel + posEdge;

                    this.setNextNodes(NextNode, dotNodes, dotEdges);
                }else{
                    if (NextNode.Rank < dotNode.Rank){
                          if (!this.NodesConnected(NextNode, dotNode, dotNodes)){
                            NextNode.Rank = dotNode.Rank + 1;
                            this.shiftConnectedNodes(NextNode, dotNodes);
                          }
                    }
                }
            }
        }

    }


    NodesConnected(FromNode, ToNode, dotNodes, CheckedNodes={}){

        if (FromNode.idNode in CheckedNodes){
            return false;
        }

        CheckedNodes[FromNode.idNode] = true;

        if ("Edges" in FromNode){            
            for (let posEdge = 0; posEdge<FromNode.Edges.length; posEdge++){
                let checkEdge = FromNode.Edges[posEdge];
                if (isEqual(checkEdge.ToNode,ToNode)){
                    return true;
                }
                if (this.NodesConnected(checkEdge.ToNode, ToNode, dotNodes, CheckedNodes)){
                    return true;
                }
            }
        }

        return false;

    }

    shiftConnectedNodes(dotNode, dotNodes, ShiftedNodes={}){

        if (dotNode.idNode in ShiftedNodes){
            return
        }

        ShiftedNodes[dotNode.idNode] = true;

        if ("Edges" in dotNode){            
            for (let posEdge = 0; posEdge<dotNode.Edges.length; posEdge++){
                let NextNode = dotNode.Edges[posEdge].ToNode;
                if ("Rank" in NextNode){
                    if (NextNode.Rank <= dotNode.Rank){
                        NextNode.Rank = dotNode.Rank + 1;
                        this.shiftConnectedNodes(NextNode,dotNodes, ShiftedNodes);
                    }
                }
            }
        }
        return;
    }


    makeEdge(Label, FromNode, ToNode, style='solid', LabelPos = 'tail', LabelLength=100, LineLength=10, EdgeAtts = "", FromPort=null, ToPort=null){
        let dotEdge = {}
        dotEdge.FromNode = FromNode;
        dotEdge.ToNode = ToNode;
        dotEdge.Label = Label;
        if (isNull(dotEdge.Label)){
            dotEdge.Label = "";
        }

        if (dotEdge.Label){
            if (dotEdge.Label.length > LabelLength){
                dotEdge.Label = dotEdge.Label.substring(1, LabelLength) + '...';
            }
            dotEdge.Label = wrap(dotEdge.Label,LineLength,'\\l') + '\\l';
        }


        let dotLabel = '';
        if (Label != ""){
            switch (LabelPos){
                case 'centre':
                    dotLabel = `xlabel="`;
                    break;
                case 'head':
                    dotLabel = `headlabel="`;
                    break;
                default:            
                    dotLabel = `taillabel="`;
                    break;
            }
            dotLabel += `${dotEdge.Label}"`
        }

        dotEdge.EdgeFrom = FromNode.idNode;
        if (FromPort){
            dotEdge.EdgeFrom = `${dotEdge.EdgeFrom}:${FromPort}`;
        }
        dotEdge.EdgeTo = ToNode.idNode;
        if (ToPort){
            dotEdge.EdgeTo = `${dotEdge.EdgeTo}:${ToPort}`;
        }

        dotEdge.dot = `${dotEdge.EdgeFrom} -> ${dotEdge.EdgeTo} [style="${style}"  ${dotLabel} ${EdgeAtts}];
        `;

        return dotEdge;
    }


    makeArtefactEdge(FromNode, ToNode, Use){
        let dotEdge = {}
        dotEdge.FromNode = FromNode;
        dotEdge.ToNode = ToNode;
        dotEdge.Label = "";
        if (isNull(dotEdge.Label)){
            dotEdge.Label = "";
        }

        let dir = 'none';
        switch (Use){
            case 'input':
                dir='back';
                break;
            case 'output':
                dir='forward';
                break;
            case 'update':
                dir='both';
                break;
        }


        dotEdge.dot = `${FromNode.idNode} -> ${ToNode.idNode} [label="${dotEdge.Label}" style="dashed", dir=${dir}];
        `;

        return dotEdge;
    }


    makeStepNode(Step, idNode){

        let Node = {};

        Node.type = 'step';
        Node.Step = Step;
        if (idNode){
            Node.idNode = idNode;
        }
        else {
            Node.idNode = `step_${Step._id}`;
        }

        Node.Edges = [];

        Node.color = "lightblue";
        Node.style = "filled,rounded";

        Node.height= "0.7";
        Node.width= "2";

        let LabelLength = 200;
        let LineLength = 30


        Node.shape = "rect";
        switch (Node.Step.type){
            case 'subprocess':
                Node.style = "filled,dotted";
                Node.color = "lightgrey";
                break;
            case 'decision':
            case 'choices':
                Node.shape = "diamond";
                break;
            case 'stop':
                Node.shape = "circle";
                Node.color = "red";
                Node.height= "0.5";
                Node.width= "0.5";

                LabelLength = 30;
                LineLength = 12;
                break;
        }


        Node.label = Node.Step.name;


        if (Node.label){
            if (Node.label.length > LabelLength){
                Node.label = Node.label.substring(1, LabelLength) + '...';
            }
        }

        Node.label = wrap(Node.label,LineLength,'\n');

        let StepIcon = '';

        if (Node.Step.multiinstance){
            StepIcon += "|||";
        }
        if (Node.Step.type == 'subprocess'){
            StepIcon += "+";
        }

        if (StepIcon){
            Node.label += `\n\n${StepIcon}`
        }

        return Node;

    }


    makeArtefactNode(StepArtefact, seq=0){

        let Node = {};

        Node.type = 'artefact';
        Node.idArtefact = StepArtefact.idArtefact;
        Node.Artefact={};
        Node.idNode = `artefact_${Node.idArtefact}_${seq}`;
        Node.Edges = [];

        Node.Artefact = null;

        if (StepArtefact.Artefact){
            Node.Artefact = StepArtefact.Artefact;
        }

        Node.shape = "folder";

        if (Node.Artefact){
            switch (Node.Artefact.type){
                case 'document':
                    Node.shape = "note";
                    break;
                case 'datastore':
                    Node.shape = "cylinder";
                    break;
            }
        }



        Node.height= "0.7";
        Node.width= "0.7";


        Node.color = "grey";
        Node.style = "filled";

        Node.label = Node.idArtefact;
        if ("name" in Node.Artefact){
            Node.label = Node.Artefact.name;
        }

        if (Node.label){
            if (Node.label.length > 200){
                Node.label = Node.label.substring(1, 200);
            }
        }

        let LineLength = 12;
        Node.label = wrap(Node.label,LineLength,'\n');

        return Node;

    }

    makeNodeDot(Node, boolPosition = false, maxRanks=null, dotLanes=null){

        let posX = 0;
        let posY = 0;

        if (boolPosition){

            if ("Rank" in Node){
                posY = ( maxRanks - Node.Rank);
                posY = posY * 1.2;
            }
            if ("Lane" in Node){
                for (let posLane=1; posLane<Node.Lane; posLane++){
                    if (posLane in dotLanes){
                        posX += Number(dotLanes[posLane].maxParallels);
                    }
                }
                posX += Number(Node.Parallel) - 1;
            }
            posX = posX * 2.5;

            posX = round(posX,2);
            posY = round(posY,2);

            Node.posX = posX;
            Node.posY = posY;
        }

        if (Node.dot){
            return Node.dot;
        }

       let dot = `${Node.idNode} [`;

        if (posX||posY){
            dot += `pos="${posX},${posY}!", `;
        }

        let Label = Node.label;
        Label = Label.trim()

        
        if (Label.startsWith("<<")){
            dot += ` label=${Label}`;
        } else {
            dot += ` label="${Node.label}"`;
        }
        
        if (Node.image){
            dot += `, image="${Node.image}" `
        }
        else{
            dot += `, shape=${Node.shape} `
        }

        if (Node.fixedsize){
            dot += `, fixedsize=true`
        }

        if (Node.height){
            dot += `, height=${Node.height}`
        }
        if (Node.width){
            dot += `, width=${Node.width}`
        }
        if (Node.style){
            dot += `, style="${Node.style}"`
        }

        if (Node.color){
            dot += `, fillcolor="${Node.color}"`
        }

        dot += `, URL="${Node.idNode}"]; `
        return dot;

    }

    makeStartNode(){

        let Node = {};

        Node.type = 'start';
        Node.seqStep = null;
        Node.Step = null;
        Node.idNode = "start";
        Node.Edges = [];

        Node.height= "0.5";
        Node.width= "0.5";

        Node.Rank = 1;
        Node.Lane = 1;
        Node.Parallel = 1;

        Node.shape = "circle";
        Node.color = "green";
        Node.style = "filled";

        Node.label = "start";

        return Node;

    }


    makeStopNode(Rank, Lane, Parallel){

        let Node = {};

        Node.type = 'stop';
        Node.seqStep = null;
        Node.Step = null;
        Node.idNode = `stop_${Rank}_${Lane}_${Parallel}`;
        Node.Edges = [];

        Node.Rank = Rank;
        Node.Lane = Lane;
        Node.Parallel = Parallel;

        Node.height= "0.5";
        Node.width= "0.5";

        Node.shape = "circle";
        Node.color = "red";
        Node.style = "filled";

        Node.label = "end";

        return Node;

    }

    makeArtefactParallel(seqLane, seqParallel, dotNodes, dotLanes){
    
// shift other parallels to make room

        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode];
            if (dotNode.Lane == seqLane){
                if (Number(dotNode.Parallel) >= Number(seqParallel)){
                    dotNode.Parallel++;
                }
            }
        }

        let newParallels = {};
        for (const [key, dotLane] of Object.entries(dotLanes[seqLane].Parallels)) {
            if (Number(key) < Number(seqParallel)){
                newParallels[key] = dotLane;
            }
            if (Number(key) >= Number(seqParallel)){
                newParallels[Number(key)+1] = dotLane;
            }
        }
        newParallels[seqParallel] = {type:'artefact'};
        dotLanes[seqLane].Parallels = newParallels;
        dotLanes[seqLane].maxParallels++;

    }






    addArtefact( Data = {}){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }
    
        let payload = this.payloadArtefact(Data)
    
        this.httpConfig.post(`/artefacts`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange.isUpdated = true;
                StateChange.idArtefact = response.data.id;
                this.StateObject.setState(StateChange);
              })
              .catch(e => {
                this.StateObject.setState({ isFailed: true });
                console.log(e);
              });
    
        return;
    }
    
    
    editArtefact(id = null, Data = {}){
    
        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }
    
    
        let payload = this.payloadArtefact(Data)
    
        this.httpConfig.put(`/artefacts/${id}`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange.isUpdated = true;
                this.StateObject.setState(StateChange);
              })
              .catch(e => {
                this.StateObject.setState({ isFailed: true });
                console.log(e);
              });
    
        return true;
    };
    
    payloadArtefact(data){
    
        let payload = {}
        payload.name = data.name;
        payload.description = data.description;
        payload.proposals = data.proposals;
        payload.type = data.type;

        if (data.Resources){
            payload.Resources = data.Resources;
        }

        payload.status = data.status;
    
        return payload;
    
    }
    
    getArtefact(id=null,  nameStateVar = "Artefact"){
    
        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }
    
        this.httpConfig.get(`/artefacts/${id}`, headers)
            .then(response => {
                let StateChange = {};
                StateChange.isLoaded = true;
                StateChange[nameStateVar] = this.dataArtefact(response.data);
                this.StateObject.setState(StateChange);
              })
              .catch(e => {
                this.StateObject.setState({ isFailed: true });
                console.log(e);
              });
    
        return;
    
    };
    

    listArtefacts(Filters={}, nameStateVar = "Artefacts"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }
    
        let urlAPI = "/artefacts"
        if (Object.keys(Filters).length > 0){
            urlAPI += "?"
        }
        
        if (Filters.idUser){
            urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
        }
    
        if (Filters.status){
            urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
        }

        if (Filters.idProcess){
            urlAPI += `idProcess=${encodeURIComponent(Filters.idProcess)}&`;
        }


        this.httpConfig.get(urlAPI, headers)
            .then(response => {
    
                let arrArtefacts = [];
                for (let pos=0; pos<response.data.length;pos++){
                  arrArtefacts.push(this.dataArtefact(response.data[pos]));
                }
    
                let StateChange = {};
                StateChange.isLoaded = true;
                StateChange[nameStateVar] = arrArtefacts;
                this.StateObject.setState(StateChange);
              })
              .catch(e => {
                this.StateObject.setState({ isFailed: true });
                console.log(e);
              });
    
        return;
    
    };
    



    dataArtefact(data){

        let Artefact = data;
    
        return Artefact;
    
    }




    addResource( Data = {}){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }
    
        let payload = this.payloadResource(Data)
    
        this.httpConfig.post(`/resources`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange.isUpdated = true;
                StateChange.idResource = response.data.id;
                this.StateObject.setState(StateChange);
              })
              .catch(e => {
                this.StateObject.setState({ isFailed: true });
                console.log(e);
              });
    
        return;
    }
    
    
    editResource(id = null, Data = {}){
    
        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }
    
    
        let payload = this.payloadResource(Data)
    
        this.httpConfig.put(`/resources/${id}`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange.isUpdated = true;
                this.StateObject.setState(StateChange);
              })
              .catch(e => {
                this.StateObject.setState({ isFailed: true });
                console.log(e);
              });
    
        return true;
    };
    
    payloadResource(data){
    
        let payload = {}
        payload.name = data.name;
        payload.description = data.description;
        payload.proposals = data.proposals;
        payload.type = data.type;
        payload.URL = data.URL;
        payload.status = data.status;
    
        return payload;
    
    }
    
    getResource(id=null,  nameStateVar = "Resource"){
    
        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }
    
        this.httpConfig.get(`/resources/${id}`, headers)
            .then(response => {
                let StateChange = {};
                StateChange.isLoaded = true;
                StateChange[nameStateVar] = this.dataResource(response.data);
                this.StateObject.setState(StateChange);
              })
              .catch(e => {
                this.StateObject.setState({ isFailed: true });
                console.log(e);
              });
    
        return;
    
    };
    

    listResources(Filters={}, nameStateVar = "Resources"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }
    
        let urlAPI = "/resources"
        if (Object.keys(Filters).length > 0){
            urlAPI += "?"
        }
        
        if (Filters.idUser){
            urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
        }
    
        if (Filters.status){
            urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
        }

        if (Filters.idProcess){
            urlAPI += `idProcess=${encodeURIComponent(Filters.idProcess)}&`;
        }
        if (Filters.idArtefact){
            urlAPI += `idArtefact=${encodeURIComponent(Filters.idArtefact)}&`;
        }


        this.httpConfig.get(urlAPI, headers)
            .then(response => {
    
                let arrResources = [];
                for (let pos=0; pos<response.data.length;pos++){
                  arrResources.push(this.dataResource(response.data[pos]));
                }
    
                let StateChange = {};
                StateChange.isLoaded = true;
                StateChange[nameStateVar] = arrResources;
                this.StateObject.setState(StateChange);
              })
              .catch(e => {
                this.StateObject.setState({ isFailed: true });
                console.log(e);
              });
    
        return;
    
    };
    



    dataResource(data){

        let Resource = data;
    
        return Resource;
    
    }



// ======== Concept Models ========



    vizConceptModel(Data={}, Options = {}, engine = "dot" ){

        let dotNodes = {};
        let dotEdges = {};

        let ConceptModel = {};
        let Concepts = [];
        let Links = [];

        if (Data.ConceptModel){
            ConceptModel = cloneObject(Data.ConceptModel);
        }

        if (ConceptModel.Concepts){
            Concepts = ConceptModel.Concepts;
        }
        if (ConceptModel.Links){
            Links = ConceptModel.Links;
        }


        for (let posC=0; posC<Concepts.length; posC++){
            const Concept = Concepts[posC];

            switch (Concept.status){
                case 'deprecated':
                    break;
                default:
    
                    let idNode = `concept_${Concept._id}`;
                    if (!(idNode in dotNodes)){
                        dotNodes[idNode] = this.makeConceptNode(Concept, idNode);
                    }

                    if (Options.vizSuperConcepts){

                        if (Concept.SuperConcepts){

                            for (let posS=0; posS<Concept.SuperConcepts.length; posS++){
                                const SuperConcept = Concept.SuperConcepts[posS];
                                if (!SuperConcept.Concept){
                                    continue;
                                }
                                let idSuperNode = `concept_${SuperConcept.Concept._id}`;
                                if (!(idSuperNode in dotNodes)){
                                    dotNodes[idSuperNode] = this.makeConceptNode(SuperConcept.Concept, idSuperNode);
                                }

                                let idEdge = `super_${Concept._id}_${posS+1}`;
                                let idNodeFrom = idNode;
                                let idNodeTo = idSuperNode;
                    
                                dotEdges[idEdge] = this.makeEdge(null,dotNodes[idNode], dotNodes[idSuperNode], 'dashed')

                            }
                        }

                    }
                    break;
            }


        }

        for (let posR=0; posR<Links.length; posR++){
            const Link = Links[posR];

            switch (Link.status){
                case 'deprecated':
                    break;
                default:
    
                    let idEdge = `link_${Link._id}`;
                    let idNodeFrom = null;
                    let idNodeTo = null;
                    if (Link.ConceptFrom){
                        idNodeFrom = `concept_${Link.ConceptFrom._id}`;
                        if (!(idNodeFrom in dotNodes)){
                            dotNodes[idNodeFrom] = this.makeConceptNode(Link.ConceptFrom, idNodeFrom);
                        }    
                        if (Link.ConceptTo){
                            idNodeTo = `concept_${Link.ConceptTo._id}`;
                            if (!(idNodeTo in dotNodes)){
                                dotNodes[idNodeTo] = this.makeConceptNode(Link.ConceptTo, idNodeTo);
                            }    
                        

                            if (!(idEdge in dotEdges)){
                                dotEdges[idEdge] = this.makeEdge(Link.label,dotNodes[idNodeFrom], dotNodes[idNodeTo])
                            }
                        }
                    }
                    break;
            }
        }



        let dot = '';

        let Key = {};

        dot += `digraph  { 
            overlap=false; 
            splines=true;
            nodesep=0.6;
            ranksep=0.8;

            node [fixedsize=true height=0.8 style=filled width=1.0 color=black fillcolor=lightblue fontname=Arial fontsize=7];
            edge [fontname=Arial fontsize=7 labelfontname=Arial labelfontsize=7 labeldistance=5];
            `


        let ConceptModels = {};
        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode]
            if (dotNode.Data){
                if (!dotNode.Data.ConceptModel){
                    dotNode.Data.ConceptModel = Data.ConceptModel;
                }
                ConceptModels[dotNode.Data.ConceptModel._id] = dotNode.Data.ConceptModel;
            }
        }
    
// create clusters for each concept models

        for (let idClusterConceptModel in ConceptModels){
            const ClusterConceptModel = ConceptModels[idClusterConceptModel];
            dot += `
            subgraph cluster_${idClusterConceptModel} {
                style=filled;
                color=grey92;
                node [style=filled];
                label = "${ClusterConceptModel.name}";
                fontname="Arial";
                fontsize=7;
            `
            for (let idNode in dotNodes){
                let dotNode = dotNodes[idNode]
                if (dotNode.Data.ConceptModel._id == idClusterConceptModel){
                    dot += `
                ${this.makeNodeDot(dotNode)}`;
                }
            }
    
            dot += `
            }            
            `

        }



        for (let idEdge in dotEdges){
            let Edge = dotEdges[idEdge];
            dot += `
            ${Edge.dot}`;
        }


        dot += `}
            `; 

        return {"dot":dot,"nodes":dotNodes};

    }


    makeConceptNode(Concept, idNode=null, Key=null){

        let Node = {};

        Node.type = 'concept';
        Node.Data = Concept;
        Node.idNode = idNode;
        if (!Node.idNode){
            Node.idNode = `${Node.type}_${Concept._id}`;
        }

        Node.Edges = [];

        Node.color = "lightblue";

        if (Key){
            if (Key.color){
                Node.color = Key.color;
            }
        }


        Node.style = "filled,rounded";

        Node.height= "0.8";
        Node.width= "1";
        Node.fixedsize = true;

        let LabelLength = 200;
        let LineLength = 18;

        Node.shape = "oval";

        Node.label = Concept.name;

        if (Node.label){
            if (Node.label.length > LabelLength){
                Node.label = Node.label.substring(1, LabelLength) + '...';
            }
        }

        Node.label = wrap(Node.label,LineLength,'\n');

        return Node;

    }


    makeLinkEdge(Link, idEdge=null){

        let Edge = {};

        Edge.type = 'link';
        Edge.Data = Link;
        Edge.idEdge = idEdge;
        if (!Edge.idEdge){
            Edge.idEdge = `${Edge.type}_${Link._id}`;
        }

        Edge.NodeFrom = `concept_${Link.ConceptFrom._id}`;
        Edge.NodeTo = `concept_${Link.ConceptTo._id}`;

        Edge.color = "black";
        Edge.style = null;

        let LabelLength = 100;
        let LineLength = 20

        Edge.label = Link.label;

        if (Edge.label){
            if (Edge.label.length > LabelLength){
                Edge.label = Edge.label.substring(1, LabelLength) + '...';
            }
        }

        Edge.label = wrap(Edge.label,LineLength,'\\l') + '\\l';

        return Edge;

    }

    addConceptModel( Data = {}){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        let payload = this.payloadConceptModel(Data)

        this.httpConfig.post(`/conceptmodels`, payload, headers)
             .then(response => {
                let StateChange = {};
                StateChange.isUpdated = true;
                StateChange.idConceptModel = response.data._id;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                this.StateObject.setState({ isFailed: true });
                if (e.response){
                    if (e.response.data){
                        if (e.response.data.fields){
                            this.StateObject.setState({ invalidFields:e.response.data.fields});
                        }
                    }
                }
                console.log(e);
            });

        return;
    }


    editConceptModel(id = null, Data = {}){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        let payload = this.payloadConceptModel(Data)

        this.httpConfig.put(`/conceptmodels/${id}`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange.isUpdated = true;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                this.StateObject.setState({ isFailed: true });
                if (e.response){
                    if (e.response.data){
                        if (e.response.data.fields){
                            this.StateObject.setState({ invalidFields:e.response.data.fields});
                        }
                    }
                }
                console.log(e);
            });

        return true;
    };

    payloadConceptModel(data){

        let payload = {}
        payload.name = data.name;
        payload.description = data.description;
        payload.namespace = data.namespace;
        payload.prefix = data.prefix;
        payload.proposals = data.proposals;
        payload.status = data.status;

        return payload;

    }

    getConceptModel(id=null,  nameStateVar = "ConceptModel", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        this.httpConfig.get(`/conceptmodels/${id}`, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsLoadedVar] = true;
                StateChange[nameStateVar] = this.dataConceptModel(response.data);
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsFailedVar] = true;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;

    };


    listConceptModels(Filters={}, nameStateVar = "ConceptModels", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }
    
        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }
    
        let urlAPI = "/conceptmodels"
        if (Object.keys(Filters).length > 0){
            urlAPI += "?"
        }
        
        if (Filters.idUser){
            urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
        }

        if (Filters.scope){
            urlAPI += `scope=${encodeURIComponent(Filters.scope)}&`;
        }


        if (Filters.status){
            urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
        }

        if (Filters.search){
            urlAPI += `search=${encodeURIComponent(Filters.search)}&`;
        }



        this.httpConfig.get(urlAPI, headers)
            .then(response => {
    
                let arr = [];
                for (let pos=0; pos<response.data.length;pos++){
                  arr.push(this.dataConceptModel(response.data[pos]));
                }
    
                let StateChange = {};
                StateChange[nameIsLoadedVar] = true;
                StateChange[nameStateVar] = arr;
                this.StateObject.setState(StateChange);
              })
              .catch(e => {
                let StateChange = {};
                StateChange[nameIsFailedVar] = true;
                this.StateObject.setState(StateChange);
                console.log(e);
              });
    
        return;
    
    };
    


    dataConceptModel(data){

        let obj = data;
    
        return obj;
    
    }



// === Concepts =====



vizConcept(Data={}, Options = {}, engine = "dot" ){

    let dotNodes = {};
    let dotEdges = {};

    let Concept = {};
    let ConceptLinks = [];


    if (Data.Concept){
        Concept = cloneObject(Data.Concept);
        let idNode = `concept_${Concept._id}`;
        if (!(idNode in dotNodes)){
            dotNodes[idNode] = this.makeConceptNode(Concept, idNode);
        }

        if (Concept.SuperConcepts){

            for (let posS=0; posS<Concept.SuperConcepts.length; posS++){
                const SuperConcept = Concept.SuperConcepts[posS];
                if (!SuperConcept.Concept){
                    continue;
                }
                let idSuperNode = `concept_${SuperConcept.Concept._id}`;
                if (!(idSuperNode in dotNodes)){
                    dotNodes[idSuperNode] = this.makeConceptNode(SuperConcept.Concept, idSuperNode);
                }

                let idEdge = `super_${Concept._id}_${posS+1}`;
    
                dotEdges[idEdge] = this.makeEdge(null,dotNodes[idNode], dotNodes[idSuperNode], 'dashed')

            }

        }

        if (Concept.SubConcepts){

            for (let posS=0; posS<Concept.SubConcepts.length; posS++){
                const SubConcept = Concept.SubConcepts[posS];
                if (!SubConcept.Concept){
                    continue;
                }
                let idSubNode = `concept_${SubConcept.Concept._id}`;
                if (!(idSubNode in dotNodes)){
                    dotNodes[idSubNode] = this.makeConceptNode(SubConcept.Concept, idSubNode);
                }

                let idEdge = `sub_${Concept._id}_${posS+1}`;
    
                dotEdges[idEdge] = this.makeEdge(null, dotNodes[idNode], dotNodes[idSubNode], 'dashed','tail',100,18,"dir=back")

            }

        }



// links

        if (isArray(Data.ConceptLinks)){
            ConceptLinks = Data.ConceptLinks;
            for (let posL=0; posL<ConceptLinks.length; posL++){
                const ConceptLink = ConceptLinks[posL];


                switch (ConceptLink.Link.status){
                    case 'deprecated':
                        break;
                    default:
                        if (ConceptLink.Concept){
                            if (ConceptLink.Concept._id == Concept._id){
                                let Inverse = false;
                                let Found = false;

                                if (ConceptLink.Link.idConceptFrom == Concept._id){
                                    Found = true;
                                }
                                if (ConceptLink.Link.idConceptTo == Concept._id){
                                    Found = true;
                                    Inverse = true;
                                }

                                if (Found){
                                    let idConceptNode = null;
                                    if (Inverse){
                                        idConceptNode = `concept_${ConceptLink.Link.ConceptFrom._id}`;
                                        if (!(idConceptNode in dotNodes)){
                                            dotNodes[idConceptNode] = this.makeConceptNode(ConceptLink.Link.ConceptFrom, idConceptNode);
                                        }                                
                                    }
                                    else{
                                        idConceptNode = `concept_${ConceptLink.Link.ConceptTo._id}`;
                                        if (!(idConceptNode in dotNodes)){
                                            dotNodes[idConceptNode] = this.makeConceptNode(ConceptLink.Link.ConceptTo, idConceptNode);
                                        }

                                    }
                                    let LinkLabel = ConceptLink.Link.label;
                                    let idEdge = `link_${ConceptLink.Link._id}`;
                                    if (Inverse){
                                        dotEdges[idEdge] = this.makeEdge(LinkLabel, dotNodes[idConceptNode], dotNodes[idNode], 'solid', 'centre', 100, 18);
                                    } else {
                                        dotEdges[idEdge] = this.makeEdge(LinkLabel,dotNodes[idNode], dotNodes[idConceptNode], 'solid', 'centre', 100, 18);
                                    }
                                }

                            }
                        }
                        break;
                }
            }
        }

    }

    let dot = '';

    let Key = {};

    dot += `digraph  { 
        graph [overlap=false splines=true nodesep=0.6 ranksep=0.8];
        node [color=black fillcolor=wheat fontname=Arial fontsize=7];
        edge [fontname=Arial fontsize=7 labelfontname=Arial labelfontsize=7 labeldistance=5];
    
        `



    let ConceptModels = {};
    for (let idNode in dotNodes){
        let dotNode = dotNodes[idNode]
        if (dotNode.Data){
            if (!dotNode.Data.ConceptModel){
                dotNode.Data.ConceptModel = Data.ConceptModel;
            }
            ConceptModels[dotNode.Data.ConceptModel._id] = dotNode.Data.ConceptModel;
        }
    }
    
// create clusters for each concept models

    for (let idClusterConceptModel in ConceptModels){
        const ClusterConceptModel = ConceptModels[idClusterConceptModel];
        dot += `
        subgraph cluster_${idClusterConceptModel} {
            style=filled;
            color=grey92;
            node [style=filled];
            label = "${ClusterConceptModel.name}";
            fontname="Arial";
            fontsize=7;
        `
        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode]
            if (dotNode.Data.ConceptModel._id == idClusterConceptModel){
                dot += `
            ${this.makeNodeDot(dotNode)}`;
            }
        }

        dot += `
        }            
        `

    }


    for (let idEdge in dotEdges){
        let Edge = dotEdges[idEdge];
        dot += `
        ${Edge.dot}`;
    }



    dot += `}
        `; 

    return {"dot":dot,"nodes":dotNodes};

}

// ======= revision =========  

    addRevision( idAbout, Data = {}, nameIdVar = "idRevision", nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        Data.idAbout = idAbout;
        let payload = this.payloadRevision(Data)

        this.httpConfig.post(`/revisions`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsUpdatedVar] = true;
                StateChange[nameIdVar] = response.data._id;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsErrorVar] = true;
                StateChange[nameErrorMessageVar] = e.message;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;
    }


    editRevision(id = null, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){


        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        let payload = this.payloadRevision(Data);

        this.httpConfig.put(`/revisions/${id}`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsUpdatedVar] = true;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsErrorVar] = true;
                StateChange[nameErrorMessageVar] = e.message;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return true;
    };



    payloadRevision(data){

        let payload = {}
        payload.idAbout = data.idAbout;
        payload.description = data.description;
        if (data.report != 'undefined'){
            payload.report = data.report;
        }

        return payload;

    }

    getRevision(id=null,  nameStateVar = "Revision", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        this.httpConfig.get(`/revisions/${id}`, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsLoadedVar] = true;
                StateChange[nameStateVar] = this.dataRevision(response.data);
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsFailedVar] = true;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;

    };



    listRevisions(Filters={}, nameStateVar = "Revisions", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        let urlAPI = "/revisions"
        if (Object.keys(Filters).length > 0){
            urlAPI += "?"
        }
        
        if (Filters.idUser){
            urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
        }

        if (Filters.idAbout){
            urlAPI += `idAbout=${encodeURIComponent(Filters.idAbout)}&`;
        }

        this.httpConfig.get(urlAPI, headers)
            .then(response => {

                let arr = [];
                for (let pos=0; pos<response.data.length;pos++){
                arr.push(this.dataRevision(response.data[pos]));
                }

                let StateChange = {};
                StateChange[nameIsLoadedVar] = true;
                StateChange[nameStateVar] = arr;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsFailedVar] = true;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;

    };


    dataRevision(data){

        let obj = data;

        return obj;

    }


// === Versions

addVersion( idAbout, Data = {}, nameIdVar = "idVersion", nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    Data.idAbout = idAbout;
    let payload = this.payloadVersion(Data)
    payload.idAbout = idAbout;
    if (Data.Document){
        payload.Document = Data.Document;
    }

    this.httpConfig.post(`/versions`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            StateChange[nameIdVar] = response.data._id;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;
}


editVersion(id = null, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){


    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadVersion(Data);

    this.httpConfig.put(`/versions/${id}`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return true;
};



payloadVersion(data){

    let payload = {}
    payload.name = data.name;
    payload.type = data.type;
    payload.description = data.description;

    return payload;

}

getVersion(id=null,  nameStateVar = "Version", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    this.httpConfig.get(`/versions/${id}`, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = this.dataVersion(response.data);
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



listVersions(Filters={}, nameStateVar = "Versions", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/versions"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idUser){
        urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
    }

    if (Filters.idAbout){
        urlAPI += `idAbout=${encodeURIComponent(Filters.idAbout)}&`;
    }

    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
            arr.push(this.dataVersion(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};


dataVersion(data){

    let obj = data;

    return obj;

}

// === Concepts


    addConceptToModel( idConceptModel, Data = {}, nameIdVar = "idConcept", nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        let payload = this.payloadConcept(Data)

        this.httpConfig.post(`/conceptmodels/${idConceptModel}/concepts`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsUpdatedVar] = true;
                StateChange[nameIdVar] = response.data._id;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsErrorVar] = true;
                StateChange[nameErrorMessageVar] = e.message;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;
    }


    editConcept(id = null, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){


        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        let payload = this.payloadConcept(Data);
        let idConceptModel = null;
        if (Data.ConceptModel) {
            idConceptModel = Data.ConceptModel._id;
        }
    
        this.httpConfig.put(`/conceptmodels/${idConceptModel}/concepts/${id}`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsUpdatedVar] = true;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsErrorVar] = true;
                StateChange[nameErrorMessageVar] = e.message;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return true;
    };

    payloadConcept(data){

        let payload = {}
        payload.name = data.name;
        payload.description = data.description;
        payload.notes = data.notes;
        payload.proposals = data.proposals;
        payload.status = data.status;

        payload.SuperConcepts = [];

        if (data.SuperConcepts){
            payload.SuperConcepts = data.SuperConcepts;
        }

        return payload;

    }

    getConcept(id=null,  nameStateVar = "Concept", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        this.httpConfig.get(`/concepts/${id}`, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsLoadedVar] = true;
                StateChange[nameStateVar] = this.dataConcept(response.data);
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsFailedVar] = true;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;

    };



    listConcepts(Filters={}, nameStateVar = "Concepts", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        let urlAPI = "/concepts"
        if (Object.keys(Filters).length > 0){
            urlAPI += "?"
        }
        
        if (Filters.idUser){
            urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
        }

        if (Filters.status){
            urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
        }

        if (Filters.idConceptModel){
            urlAPI += `idConceptModel=${encodeURIComponent(Filters.idConceptModel)}&`;
        }

        if (Filters.idSubConcept){
            urlAPI += `idSubConcept=${encodeURIComponent(Filters.idSubConcept)}&`;
        }

        if (Filters.idNarrative){
            urlAPI += `idNarrative=${encodeURIComponent(Filters.idNarrative)}&`;
        }


        this.httpConfig.get(urlAPI, headers)
            .then(response => {

                let arr = [];
                for (let pos=0; pos<response.data.length;pos++){
                arr.push(this.dataConcept(response.data[pos]));
                }

                let StateChange = {};
                StateChange[nameIsLoadedVar] = true;
                StateChange[nameStateVar] = arr;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsFailedVar] = true;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;

    };



    dataConcept(data){

        let obj = data;

        return obj;

    }



// === Links =====


vizLink(Data={}, Options = {}, engine = "dot" ){

    let dotNodes = {};
    let dotEdges = {};

    let Link = {};


    if (Data.Link){
        Link = cloneObject(Data.Link);

        let idNodeFrom = null;
        let idNodeTo = null;

        if (Link.ConceptFrom){
            idNodeFrom = `concept_${Link.ConceptFrom._id}`;
            if (!(idNodeFrom in dotNodes)){
                dotNodes[idNodeFrom] = this.makeConceptNode(Link.ConceptFrom, idNodeFrom);
            }
        }

        if (Link.ConceptTo){
            idNodeTo = `concept_${Link.ConceptTo._id}`;
            if (!(idNodeTo in dotNodes)){
                dotNodes[idNodeTo] = this.makeConceptNode(Link.ConceptTo, idNodeTo);
            }
        }


        if (idNodeFrom && idNodeTo){
            let LinkLabel = Link.label;
            let idEdge = `link_${Link._id}`;
            dotEdges[idEdge] = this.makeEdge(LinkLabel,dotNodes[idNodeFrom], dotNodes[idNodeTo], 'solid', 'centre', 100, 18);
        }

    }

    let dot = '';

    let Key = {};

    dot += `digraph  { 
        graph [overlap=false splines=true nodesep=0.6 ranksep=0.8];
        node [fixedsize=true color=black fillcolor=wheat fontname=Arial fontsize=7];
        edge [fontname=Arial fontsize=7 labelfontname=Arial labelfontsize=7 labeldistance=5];
    
        `



    let ConceptModels = {};
    for (let idNode in dotNodes){
        let dotNode = dotNodes[idNode]
        if (dotNode.Data){
            if (!dotNode.Data.ConceptModel){
                dotNode.Data.ConceptModel = Data.ConceptModel;
            }
            ConceptModels[dotNode.Data.ConceptModel._id] = dotNode.Data.ConceptModel;
        }
    }
    
// create clusters for each concept models

    for (let idClusterConceptModel in ConceptModels){
        const ClusterConceptModel = ConceptModels[idClusterConceptModel];
        dot += `
        subgraph cluster_${idClusterConceptModel} {
            style=filled;
            color=grey92;
            node [style=filled];
            label = "${ClusterConceptModel.name}";
            fontname="Arial";
            fontsize=7;
        `
        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode]
            if (dotNode.Data.ConceptModel._id == idClusterConceptModel){
                dot += `
            ${this.makeNodeDot(dotNode)}`;
            }
        }

        dot += `
        }            
        `

    }


    for (let idEdge in dotEdges){
        let Edge = dotEdges[idEdge];
        dot += `
        ${Edge.dot}`;
    }



    dot += `}
        `; 

    return {"dot":dot,"nodes":dotNodes};

}



    addLinkToModel( idConceptModel, Data = {}, nameIdVar = "idLink", nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        let payload = this.payloadLink(Data)

        this.httpConfig.post(`/conceptmodels/${idConceptModel}/links`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsUpdatedVar] = true;
                StateChange[nameIdVar] = response.data._id;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsErrorVar] = true;
                StateChange[nameErrorMessageVar] = e.message;
                if (e.response.data){
                    if (e.response.data.message){
                        StateChange[nameErrorMessageVar] = e.response.data.message;
                    }
                }
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;
    }


    editLink(id = null, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){


        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        let payload = this.payloadLink(Data);
        let idConceptModel = null;
        if (Data.ConceptModel) {
            idConceptModel = Data.ConceptModel._id;
        }
    
        this.httpConfig.put(`/conceptmodels/${idConceptModel}/links/${id}`, payload, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsUpdatedVar] = true;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsErrorVar] = true;
                StateChange[nameErrorMessageVar] = e.message;
                if (e.response.data){
                    if (e.response.data.message){
                        StateChange[nameErrorMessageVar] = e.response.data.message;
                    }
                }
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return true;
    };

    payloadLink(data){

        let payload = {}
        payload.name = data.name;

        payload.label = data.label;
        payload.inverseLabel = data.inverseLabel;

        payload.idConceptFrom = data.idConceptFrom;
        payload.idConceptTo = data.idConceptTo;

        payload.description = data.description;
        payload.notes = data.notes;
        payload.proposals = data.proposals;
        payload.status = data.status;

        return payload;

    }

    getLink(id=null,  nameStateVar = "Link", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        this.httpConfig.get(`/links/${id}`, headers)
            .then(response => {
                let StateChange = {};
                StateChange[nameIsLoadedVar] = true;
                StateChange[nameStateVar] = this.dataLink(response.data);
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsFailedVar] = true;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;

    };



    listLinks(Filters={}, nameStateVar = "Links", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

        let headers = {
            headers: 
                {
                    "Content-type": "application/json"
                }
            }

        let authHeader = this.authHeader();
        if (authHeader){
            headers.headers.Authorization = authHeader.Authorization;
        }

        let urlAPI = "/links"
        if (Object.keys(Filters).length > 0){
            urlAPI += "?"
        }
        
        if (Filters.idUser){
            urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
        }

        if (Filters.status){
            urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
        }

        if (Filters.idConceptModel){
            urlAPI += `idConceptModel=${encodeURIComponent(Filters.idConceptModel)}&`;
        }


        if (Filters.idConcept){
            urlAPI += `idConcept=${encodeURIComponent(Filters.idConcept)}&`;
        }


        this.httpConfig.get(urlAPI, headers)
            .then(response => {

                let arr = [];
                for (let pos=0; pos<response.data.length;pos++){
                arr.push(this.dataLink(response.data[pos]));
                }

                let StateChange = {};
                StateChange[nameIsLoadedVar] = true;
                StateChange[nameStateVar] = arr;
                this.StateObject.setState(StateChange);
            })
            .catch(e => {
                let StateChange = {};
                StateChange[nameIsFailedVar] = true;
                this.StateObject.setState(StateChange);
                console.log(e);
            });

        return;

    };



    dataLink(data){

        let obj = data;

        return obj;

    }


// === Concept Links ====

listConceptLinks(Filters={}, nameStateVar = "ConceptLinks", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/conceptlinks"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idConceptModel){
        urlAPI += `idConceptModel=${encodeURIComponent(Filters.idConceptModel)}&`;
    }

    if (Filters.idConcept){
        urlAPI += `idConcept=${encodeURIComponent(Filters.idConcept)}&`;
    }

    if (Filters.idLink){
        urlAPI += `idLink=${encodeURIComponent(Filters.idLink)}&`;
    }


    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
            arr.push(this.dataConceptLink(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};

dataConceptLink(data){

    let obj = data;

    return obj;

}



// ==== Logical Models



vizLogicalModel(Data={}, Options = {}, engine = "dot" ){

    let dotNodes = {};
    let dotEdges = {};

    let LogicalModel = {};
    let Entities = [];
    let Relationships = [];

    if (Data.LogicalModel){
        LogicalModel = cloneObject(Data.LogicalModel);

        if (Data.LogicalModel.Entities){
            Entities = Data.LogicalModel.Entities;            
        }
        if (Data.LogicalModel.Relationships){
            Relationships = Data.LogicalModel.Relationships;
        }
    }


    for (let posE=0; posE<Entities.length; posE++){
        const Entity = Entities[posE];

        switch (Entity.status){
            case 'deprecated':
                break;
            default:

                let idNode = `entity_${Entity._id}`;
                if (!(idNode in dotNodes)){
                    dotNodes[idNode] = this.makeEntityNode(Entity, idNode);

                    if ('vizStructures' in Options){
                        if (Options.vizStructures){
                            
                            if (Entity.Attributes){
                                for (let posA=0; posA<Entity.Attributes.length; posA++){
                                    const Attribute = Entity.Attributes[posA];

  

                                    if (Attribute.type == 'structure'){
                                        this.makeStructureNodes(Attribute.Structure, idNode, `attribute_${Attribute._id}` ,dotNodes,dotEdges);
                                    } 

                                }
                            }
                            
                        }
                    }
                }
                break;
        }
    }

    for (let posR=0; posR<Relationships.length; posR++){
        const Relationship = Relationships[posR];
        switch (Relationship.status){
            case 'deprecated':
                break;
            default:
                let idEdge = `relationship_${Relationship._id}`;
                let idNodeFrom = null;
                let idNodeTo = null;
                if (Relationship.idEntityFrom){
                    idNodeFrom = `entity_${Relationship.idEntityFrom}`;
                    if (idNodeFrom in dotNodes){
                        if (Relationship.idEntityTo){
                            idNodeTo = `entity_${Relationship.idEntityTo}`;
                            if (idNodeTo in dotNodes){
                                if (!(idEdge in dotEdges)){
                                    let Label = ""
                                    let EdgeAtts = "";
                                    if (Relationship.Link){
                                        Label = Relationship.Link.label;
                                        if (Relationship.inverse){
                                            Label = Relationship.Link.inverseLabel;
                                        }
                                    }

                                    switch (Relationship.cardinality){
                                        case "many to many":
                                            EdgeAtts = `arrowhead=crow arrowtail=crow dir=both`
                                            break;
                                        case "one to many":
                                            EdgeAtts = `arrowhead=crow arrowtail=none`
                                            break;
                                        case "one to many":
                                        default:
                                            EdgeAtts = `arrowhead=none arrowtail=none`
                                            break;
                                    }


                                    dotEdges[idEdge] = this.makeEdge(Label,dotNodes[idNodeFrom], dotNodes[idNodeTo], 'solid', 'centre', 100, 10, EdgeAtts);

                                }
                            }
                        }
                    }
                }
                break;
        }
    }

    let dot = '';

    let Key = {};

    dot += `digraph  { 
        graph [overlap=false splines=true nodesep=0.6 ranksep=0.8];
        node [color=black fillcolor=wheat fontname=Arial fontsize=7];
        edge [fontname=Arial fontsize=7 labelfontname=Arial labelfontsize=7 labeldistance=5];
    
        `

    let LogicalModels = {};
    LogicalModels[LogicalModel._id] = LogicalModel;

    for (let idNode in dotNodes){
        let dotNode = dotNodes[idNode]
        if (dotNode.Data){
            if (dotNode.Data.LogicalModel){
                LogicalModels[dotNode.Data.LogicalModel._id] = dotNode.Data.LogicalModel;
            }
        }
    }

// create clusters for each logical model

    for (let idClusterLogicalModel in LogicalModels){
        const ClusterLogicalModel = LogicalModels[idClusterLogicalModel];
        dot += `
        subgraph cluster_${idClusterLogicalModel} {
            style=filled;
            color=grey92;
            label = "${ClusterLogicalModel.name}";
            fontname="Arial";
            fontsize=7;
        `
        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode]
                dot += `
            ${this.makeNodeDot(dotNode)}`;
        }

        dot += `
        }            
        `

    }



    for (let idEdge in dotEdges){
        let Edge = dotEdges[idEdge];
        dot += `
        ${Edge.dot}`;
    }


    dot += `}
        `; 

    return {"dot":dot,"nodes":dotNodes};

}

makeStructureNodes(Structure, idNodeParent, rowport, dotNodes, dotEdges){

    let idStructureNode = `structure_${Structure._id}`

    if(idNodeParent){
        idStructureNode = `${idNodeParent}_${idStructureNode}`
    }
    if (!(idStructureNode in dotNodes)){
        dotNodes[idStructureNode] = this.makeStructureNode(Structure, idStructureNode);
    }
    let EdgeAtts = `arrowhead=none arrowtail=none`
    dotEdges[idStructureNode] = this.makeEdge("",dotNodes[idNodeParent], dotNodes[idStructureNode], 'solid', 'centre', 100, 18, EdgeAtts, rowport);

    if (Structure.Attributes){
        for (let posA=0; posA<Structure.Attributes.length; posA++){
            const Attribute = Structure.Attributes[posA];

            if (Attribute.type == 'structure'){
                if (Attribute.Structure){
                    this.makeStructureNodes(Attribute.Structure,idStructureNode,`attribute_${Attribute._id}`,dotNodes, dotEdges);
                }
            }
        }
    }


    return;

}

makeEntityNode(Entity, idNode=null, Key=null){

    let Node = {};

    Node.type = 'entity';
    Node.Data = Entity;
    Node.idNode = idNode;
    if (!Node.idNode){
        Node.idNode = `${Node.type}_${Entity._id}`;
    }

    Node.Edges = [];

    Node.color = null;

    if (Key){
        if (Key.color){
            Node.color = Key.color;
        }
    }


    Node.style = null;

    Node.height= null;
    Node.width= null;

    Node.shape = "plaintext";

    Node.label = `
        <<table border='0' cellborder='1' cellspacing='0'>
            <tr>
                <td colspan='3' bgcolor='wheat'> ${Entity.name}</td></tr>`;
    if (Entity.Attributes){
        if (Entity.Attributes.length > 0){
            Node.label += `
                    <tr><td bgcolor='wheat'>Attribute</td><td bgcolor='wheat'>Data Type</td><td bgcolor='wheat'>Occurs</td></tr>`;
            for (let posA=0; posA<Entity.Attributes.length;posA++){
                const Attribute = Entity.Attributes[posA];
                let DataType = '';
                if (Attribute.dataType){
                    DataType = Attribute.dataType;
                }
                if (Attribute.type == 'structure'){
                    if (Attribute.Structure){
                        DataType = Attribute.Structure.name;
                    }
                }
                Node.label += `
                        <tr><td align='left' balign='left' valign='top'>${Attribute.name}</td><td align='left' balign='left' valign='top'>${DataType}</td><td PORT='attribute_${Attribute._id}' align='left' balign='left' valign='top'>${Attribute.occurs}</td></tr>`;
            }
        }
    }

    Node.label += `
        </table>> 
    `;

    return Node;

}


makeStructureNode(Structure, idNode=null, Key=null){

    let Node = {};

    Node.type = 'structure';
    Node.Data = Structure;
    Node.idNode = idNode;
    if (!Node.idNode){
        Node.idNode = `${Node.type}_${Structure._id}`;
    }

    Node.Edges = [];

    Node.color = 'grey';

    if (Key){
        if (Key.color){
            Node.color = Key.color;
        }
    }


    Node.style = null;

    Node.height= null;
    Node.width= null;

    Node.shape = "plaintext";

    Node.label = `
        <<table border='0' cellborder='1' cellspacing='0'>
            <tr><td bgcolor='${Node.color}'>Attribute</td><td bgcolor='${Node.color}'>Data Type</td><td bgcolor='${Node.color}'>Occurs</td></tr>`;


    if (Structure.Attributes){
        if (Structure.Attributes.length > 0){
            for (let posA=0; posA<Structure.Attributes.length;posA++){
                const Attribute = Structure.Attributes[posA];
                let DataType = '';
                if (Attribute.dataType){
                    DataType = Attribute.dataType;
                }
                if (Attribute.type == 'structure'){
                    if (Attribute.Structure){
                        DataType = Attribute.Structure.name;
                    }
                }
                Node.label += `
                        <tr><td align='left' balign='left' valign='top'>${Attribute.name}</td><td align='left' balign='left' valign='top'>${DataType}</td><td PORT='attribute_${Attribute._id}' align='left' balign='left' valign='top'>${Attribute.occurs}</td></tr>`;
            }
        }
    }





    Node.label += `
        </table>> 
    `;

    return Node;

}

makeRelationshipEdge(Relationship, idEdge=null){

    let Edge = {};

    Edge.type = 'relationship';
    Edge.Data = Relationship;
    Edge.idEdge = idEdge;
    if (!Edge.idEdge){
        Edge.idEdge = `${Edge.type}_${Relationship._id}`;
    }

    Edge.NodeFrom = `entity_${Relationship.EntityFrom._id}`;
    Edge.NodeTo = `entity_${Relationship.EntityTo._id}`;

    Edge.color = "black";
    Edge.style = null;

    let LabelLength = 100;
    let LineLength = 20

    Edge.label = Relationship.label;

    if (Edge.label){
        if (Edge.label.length > LabelLength){
            Edge.label = Edge.label.substring(1, LabelLength) + '...';
        }
    }

    Edge.label = wrap(Edge.label,LineLength,'\\l') + '\\l';

    return Edge;

}

addLogicalModel( Data = {}){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadLogicalModel(Data)

    this.httpConfig.post(`/logicalmodels`, payload, headers)
         .then(response => {
            let StateChange = {};
            StateChange.isUpdated = true;
            StateChange.idLogicalModel = response.data._id;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            this.StateObject.setState({ isFailed: true });
            if (e.response){
                if (e.response.data){
                    if (e.response.data.fields){
                        this.StateObject.setState({ invalidFields:e.response.data.fields});
                    }
                }
            }
            console.log(e);
        });

    return;
}


editLogicalModel(id = null, Data = {}){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }


    let payload = this.payloadLogicalModel(Data)

    this.httpConfig.put(`/logicalmodels/${id}`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange.isUpdated = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            this.StateObject.setState({ isFailed: true });
            if (e.response){
                if (e.response.data){
                    if (e.response.data.fields){
                        this.StateObject.setState({ invalidFields:e.response.data.fields});
                    }
                }
            }
            console.log(e);
        });

    return true;
};

payloadLogicalModel(data){

    let payload = {}
    payload.name = data.name;
    payload.description = data.description;
    payload.namespace = data.namespace;
    payload.prefix = data.prefix;
    payload.proposals = data.proposals;
    payload.status = data.status;

    return payload;

}

getLogicalModel(id=null,  nameStateVar = "LogicalModel", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    this.httpConfig.get(`/logicalmodels/${id}`, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = this.dataLogicalModel(response.data);
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};


listLogicalModels(Filters={}, nameStateVar = "LogicalModels", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/logicalmodels"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idUser){
        urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
    }

    if (Filters.scope){
        urlAPI += `scope=${encodeURIComponent(Filters.scope)}&`;
    }

    if (Filters.status){
        urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
    }

    if (Filters.search){
        urlAPI += `search=${encodeURIComponent(Filters.search)}&`;
    }

    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
              arr.push(this.dataLogicalModel(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
          })
          .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
          });

    return;

};



dataLogicalModel(data){

    let obj = data;

    return obj;

}



// === Entities =====


vizEntity(Data={}, Options = {}, engine = "dot" ){

    let dotNodes = {};
    let dotEdges = {};

    let Entity = {};
    let EntityLinks = [];


    if (Data.Entity){
        Entity = cloneObject(Data.Entity);
        let idNode = `entity_${Entity._id}`;
        if (!(idNode in dotNodes)){
            dotNodes[idNode] = this.makeEntityNode(Entity, idNode);

            if (Entity.Attributes){
                for (let posA=0; posA<Entity.Attributes.length; posA++){
                    const Attribute = Entity.Attributes[posA];


                    if (Attribute.type == 'structure'){
                        this.makeStructureNodes(Attribute.Structure, idNode, `attribute_${Attribute._id}` ,dotNodes,dotEdges);
                    } 
                }
            }
        }

// links

        if (isArray(Data.EntityLinks)){
            EntityLinks = Data.EntityLinks;
            for (let posL=0; posL<EntityLinks.length; posL++){
                const EntityLink = EntityLinks[posL];
                if (EntityLink.Entity){
                    if (EntityLink.Entity._id == Entity._id){

                        if (EntityLink.Link){
                            switch (EntityLink.Link.status){
                                case 'deprecated':
                                    break;
                                default:

                                    let Inverse = false;
                                    let Found = false;
                                    for (let posC=0;posC<EntityLink.Entity.Concepts.length;posC++){
                                        let EntityConcept = EntityLink.Entity.Concepts[posC];
                                        if (EntityLink.Link.idConceptFrom == EntityConcept.idConcept){
                                            Found = true;
                                        }
                                        if (EntityLink.Link.idConceptTo == EntityConcept.idConcept){
                                            Found = true;
                                            Inverse = true;
                                        }                            
                                    }

                                    if (Found){
                                        let idConceptNode = null;
                                        if (Inverse){
                                            idConceptNode = `concept_${EntityLink.Link.ConceptFrom._id}`;
                                            if (!(idConceptNode in dotNodes)){
                                                dotNodes[idConceptNode] = this.makeConceptNode(EntityLink.Link.ConceptFrom, idConceptNode);
                                            }                                
                                        }
                                        else{
                                            idConceptNode = `concept_${EntityLink.Link.ConceptTo._id}`;
                                            if (!(idConceptNode in dotNodes)){
                                                dotNodes[idConceptNode] = this.makeConceptNode(EntityLink.Link.ConceptTo, idConceptNode);
                                            }

                                        }

                                        let LinkLabel = EntityLink.Link.label;
                                        let idEdge = `link_${EntityLink.Link._id}`;
                                        if (Inverse){
                                            dotEdges[idEdge] = this.makeEdge(LinkLabel, dotNodes[idConceptNode], dotNodes[idNode], 'solid', 'centre', 100, 18);
                                        } else {
                                            dotEdges[idEdge] = this.makeEdge(LinkLabel,dotNodes[idNode], dotNodes[idConceptNode], 'solid', 'centre', 100, 18);
                                        }
                
                                    }
                                    break;
                            }
                        }

                    }
                }
            }
        }

    }

    let dot = '';

    let Key = {};

    dot += `digraph  { 
        graph [overlap=false splines=true nodesep=0.6 ranksep=0.8]
        node [color=black fillcolor=wheat fontname=Arial fontsize=7]
        edge [fontname=Arial fontsize=7 labelfontname=Arial labelfontsize=7 labeldistance=5]
    
        `
    for (let idNode in dotNodes){
        let dotNode = dotNodes[idNode]
            dot += `
        ${this.makeNodeDot(dotNode)}`;
    }

    for (let idEdge in dotEdges){
        let Edge = dotEdges[idEdge];
        dot += `
        ${Edge.dot}`;
    }



    dot += `}
        `; 

    return {"dot":dot,"nodes":dotNodes};

}




addEntityToModel( idLogicalModel, Data = {}, nameIdVar = "idEntity", nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadEntity(Data)

    this.httpConfig.post(`/logicalmodels/${idLogicalModel}/entities`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            StateChange[nameIdVar] = response.data._id;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;
}


editEntity(id = null, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){


    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }


    let payload = this.payloadEntity(Data)
    let idLogicalModel = null;
    if (Data.LogicalModel) {
        idLogicalModel = Data.LogicalModel._id;
    }

    this.httpConfig.put(`/logicalmodels/${idLogicalModel}/entities/${id}`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return true;
};

payloadEntity(inData){

    let data = cloneObject(inData)

    let payload = {}
    payload.name = data.name;
    payload.description = data.description;
    payload.proposals = data.proposals;
    payload.status = data.status;

    payload.Concepts = [];
    if (data.Concepts){
        payload.Concepts = data.Concepts;
    }

    payload.Attributes = [];
    if (data.Attributes){
        payload.Attributes = data.Attributes;
    }

    return payload;

}

getEntity(id=null,  nameStateVar = "Entity", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    this.httpConfig.get(`/entities/${id}`, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = this.dataEntity(response.data);
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



listEntities(Filters={}, nameStateVar = "Entities", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/entities"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idUser){
        urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
    }

    if (Filters.status){
        urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
    }

    if (Filters.idLogicalModel){
        urlAPI += `idLogicalModel=${encodeURIComponent(Filters.idLogicalModel)}&`;
    }

    if (Filters.idSubEntity){
        urlAPI += `idSubEntity=${encodeURIComponent(Filters.idSubEntity)}&`;
    }

    if (Filters.idNarrative){
        urlAPI += `idNarrative=${encodeURIComponent(Filters.idNarrative)}&`;
    }


    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
            arr.push(this.dataEntity(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



dataEntity(data){

    let obj = data;

    return obj;

}

// === Entity Links ====

listEntityLinks(Filters={}, nameStateVar = "EntityLinks", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/entitylinks"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idLogicalModel){
        urlAPI += `idLogicalModel=${encodeURIComponent(Filters.idLogicalModel)}&`;
    }

    if (Filters.idEntity){
        urlAPI += `idEntity=${encodeURIComponent(Filters.idEntity)}&`;
    }

    if (Filters.idLink){
        urlAPI += `idLink=${encodeURIComponent(Filters.idLink)}&`;
    }


    if (Filters.idConceptModel){
        urlAPI += `idConceptModel=${encodeURIComponent(Filters.idConceptModel)}&`;
    }


    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
            arr.push(this.dataEntityLink(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};

dataEntityLink(data){

    let obj = data;

    return obj;

}

// === Relationships =====



addRelationshipToModel( idLogicalModel, Data = {}, nameIdVar = "idRelationship", nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadRelationship(Data)

    this.httpConfig.post(`/logicalmodels/${idLogicalModel}/relationships`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            StateChange[nameIdVar] = response.data._id;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            if (e.response.data.message){
                if (e.response.data.message){
                    StateChange[nameErrorMessageVar] = e.response.data.message;
                }
            }
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;
}


editRelationship(id = null, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){


    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }


    let payload = this.payloadRelationship(Data)

    let idLogicalModel = null;
    if (Data.LogicalModel) {
        idLogicalModel = Data.LogicalModel._id;
    }

    this.httpConfig.put(`/logicalmodels/${idLogicalModel}/relationships/${id}`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            if (e.response.data){
                if (e.response.data.message){
                    StateChange[nameErrorMessageVar] = e.response.data.message;
                }
            }
            this.StateObject.setState(StateChange);
            console.log(e);
        });


    return true;
};

payloadRelationship(data){

    let payload = {}
    payload.idEntityFrom = data.idEntityFrom;
    payload.idEntityTo = data.idEntityTo;
    payload.idLink = data.idLink;
    payload.inverse = data.inverse;
    payload.cardinality = data.cardinality;

    payload.description = data.description;
    payload.proposals = data.proposals;
    payload.status = data.status;

    return payload;

}

getRelationship(id=null,  nameStateVar = "Relationship", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    this.httpConfig.get(`/relationships/${id}`, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = this.dataRelationship(response.data);
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



listRelationships(Filters={}, nameStateVar = "Relationships", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/relationships"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idUser){
        urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
    }

    if (Filters.status){
        urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
    }

    if (Filters.idLogicalModel){
        urlAPI += `idLogicalModel=${encodeURIComponent(Filters.idLogicalModel)}&`;
    }


    if (Filters.idEntity){
        urlAPI += `idEntity=${encodeURIComponent(Filters.idEntity)}&`;
    }


    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
            arr.push(this.dataRelationship(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



dataRelationship(data){

    let obj = data;

    return obj;

}

// === packages =====

vizPackage(Data={}, Options = {}, engine = "dot" ){

    let dotNodes = {};
    let dotEdges = {};

    let Package = {};
    let Actors = [];
    let Systems = [];
    let UseCases = [];
    let Associations = [];

    if (Data.Package){
        Package = cloneObject(Data.Package);

        if (Data.Package.Actors){
            Actors = Data.Package.Actors;            
        }
        if (Data.Package.Systems){
            Systems = Data.Package.Systems;            
        }
        if (Data.Package.UseCases){
            UseCases = Data.Package.UseCases;            
        }
        if (Data.Package.Associations){
            Associations = Data.Package.Associations;
        }

    }


    for (let posA=0; posA<Actors.length; posA++){
        const Actor = Actors[posA];

        switch (Actor.status){
            case 'deprecated':
                break;
            default:

                let idNode = `actor_${Actor._id}`;
                if (!(idNode in dotNodes)){
                    dotNodes[idNode] = this.makeActorNode(Actor, idNode);
                }
                break;
        }
    }



    for (let posU=0; posU<UseCases.length; posU++){
        const UseCase = UseCases[posU];

        switch (UseCase.status){
            case 'deprecated':
                break;
            default:

                let idNode = `usecase_${UseCase._id}`;
                if (!(idNode in dotNodes)){
                    dotNodes[idNode] = this.makeUseCaseNode(UseCase, idNode);
                }
                break;
        }
    }


    for (let posC=0; posC<Associations.length; posC++){
        const Association = Associations[posC];

        switch (Association.status){
            case 'deprecated':
                break;
            default:
                if (Association.idUseCase){
                    if (`usecase_${Association.idUseCase}` in dotNodes){
                        let nodeUseCase = dotNodes[`usecase_${Association.idUseCase}`]
                        if (Association.idActor){
                            if (`actor_${Association.idActor}` in dotNodes){
                                let nodeActor = dotNodes[`actor_${Association.idActor}`]
                                let idEdge = `association_${Association._id}`
                                dotEdges[idEdge] = this.makeEdge(Association.name, nodeActor, nodeUseCase);
                            }
                        }
                    }
                }                
                break;
        }
    }



    let dot = '';

    let Key = {};

    dot += `digraph  { 
        rankdir=LR;
        graph [overlap=false splines=true nodesep=0.6 ranksep=0.8 ];
        node [color=black fillcolor=wheat fontname=Arial fontsize=7];
        edge [fontname=Arial fontsize=7 labelfontname=Arial labelfontsize=7 labeldistance=5];
        
    
        `

    let Packages = {};
    Packages[Package._id] = Package;

    for (let idNode in dotNodes){
        let dotNode = dotNodes[idNode]
        if (dotNode.Data){
            if (dotNode.Data.Package){
                Packages[dotNode.Data.Package._id] = dotNode.Data.Package;
            }
        }
    }

// create clusters for each package

    for (let idClusterPackage in Packages){
        const ClusterPackage = Packages[idClusterPackage];
        dot += `
        subgraph cluster_${idClusterPackage} {
            style=filled;
            color=grey92;
            label = "${ClusterPackage.name}";
            fontname="Arial";
            fontsize=7;
        `
       
        for (let posS=0; posS<Systems.length; posS++){
            const System = Systems[posS];
    
            switch (System.status){
                case 'deprecated':
                    break;
                default:

                    dot += `
                    subgraph cluster_system_${System._id} {
                        style=filled;
                        color=seagreen2;
                        label = "${dotField(System.name)}";
                        fontname="Arial";
                        fontsize=7;
                        URL="system_${System._id}";
                    `

                    dot += `
                        {
                            rank=same;
                    `;

                    let boolSystemHasUseCase = false;
                    for (let idNode in dotNodes){
                        let dotNode = dotNodes[idNode]
                        if (dotNode.Data){
                            if (dotNode.Data.idSystem){
                                if (dotNode.Data.idSystem == System._id){
                                    boolSystemHasUseCase = true;
                                    dot += `
                            ${dotNode.idNode}
                                    `

                                }
                            }
                        }
                    }

                    if (!boolSystemHasUseCase){
                        dot += `
                        inviz_${System._id} [style=invis, height=0, width=0];
                        `
                    }

                    dot += `
                        }
                    `;




                    dot += `
                    }
                    `
                    break;

            }
        }
    
       
        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode]
                dot += `
            ${this.makeNodeDot(dotNode)}`;
        }

        dot += `
        }            
        `

    }

    for (let idEdge in dotEdges){
        let Edge = dotEdges[idEdge];
        dot += `
        ${Edge.dot}`;
    }


    dot += `
    }
        `; 

    return {"dot":dot,"nodes":dotNodes};

}

makeActorNode(Actor, idNode=null, Key=null){

    let Node = {};

    Node.type = 'actor';
    Node.Data = Actor;
    Node.idNode = idNode;
    if (!Node.idNode){
        Node.idNode = `${Node.type}_${Actor._id}`;
    }

    Node.Edges = [];

    Node.color = null;

    if (Key){
        if (Key.color){
            Node.color = Key.color;
        }
    }


    Node.style = null;

    Node.height= null;
    Node.width= null;

    Node.image = "images/stick.png";

    if (Actor.type){
        if (Actor.type in this.config.ActorTypes){
            Node.image = `images/${this.config.ActorTypes[Actor.type].image}`
        }
    }

    Node.label = Node.Data.name;

    Node.dot = `
            subgraph cluster_${Node.idNode} {label="${Node.label}"; labelloc="b"; peripheries=0; ${Node.idNode}};
            ${Node.idNode} [image="${Node.image}", peripheries=0, label="", URL="${Node.idNode}" ];
    `



    return Node;

}

makeUseCaseNode(UseCase, idNode=null, Key=null){

    let Node = {};

    Node.type = 'usecase';
    Node.Data = UseCase;
    Node.idNode = idNode;
    if (!Node.idNode){
        Node.idNode = `${Node.type}_${UseCase._id}`;
    }

    Node.Edges = [];

    Node.color = "wheat";

    if (Key){
        if (Key.color){
            Node.color = Key.color;
        }
    }


    Node.style = "filled,rounded";

    Node.height= "0.5";
    Node.width= "2";
    Node.fixedsize = true;

    let LabelLength = 400;
    let LineLength = 40;

    Node.shape = "oval";

    Node.label = UseCase.name;

    if (Node.label){
        if (Node.label.length > LabelLength){
            Node.label = Node.label.substring(1, LabelLength) + '...';
        }
    }

    Node.label = wrap(Node.label,LineLength,'\n');

    return Node;

}


vizActor(Data={}, Options = {}, engine = "dot" ){

    let dotNodes = {};
    let dotEdges = {};

    let Actor = {};
    let Package = {};
    let Systems = {};
    let Associations = [];

    if (Data.Actor){
        Actor = cloneObject(Data.Actor);

        if (Data.Actor.Package){
            Package = Data.Actor.Package;
        }
        if (Data.Actor.Associations){
            Associations = Data.Actor.Associations;
        }
    }

    if (Actor){
        let idNode = `actor_${Actor._id}`;
        if (!(idNode in dotNodes)){
            dotNodes[idNode] = this.makeActorNode(Actor, idNode);
        }
    }

    for (let pos=0; pos<Associations.length; pos++){
        const Association = Associations[pos];

        switch (Association.status){
            case 'deprecated':
                break;
            default:
                if (Association.UseCase){

                    let idNodeActor = `actor_${Association.Actor._id}`;
                    if (!(idNodeActor in dotNodes)){
                        dotNodes[idNodeActor] = this.makeActorNode(Association.Actor, idNodeActor);
                    }


                    let idNodeUseCase = `usecase_${Association.UseCase._id}`;
                    if (!(idNodeUseCase in dotNodes)){
                        dotNodes[idNodeUseCase] = this.makeUseCaseNode(Association.UseCase, idNodeUseCase);
                        if (Association.UseCase.System){
                            Systems[Association.UseCase.System._id] = Association.UseCase.System;
                        }
                    }

                    const idEdge = `association_${Association._id}`;
                    dotEdges[idEdge] = this.makeEdge(Association.name, dotNodes[idNodeActor], dotNodes[idNodeUseCase]);

                }                
                break;
        }
    }



    let dot = '';

    let Key = {};

    dot += `digraph  { 
        rankdir=LR;
        graph [overlap=false splines=true nodesep=0.6 ranksep=0.8 ];
        node [color=black fillcolor=wheat fontname=Arial fontsize=7];
        edge [fontname=Arial fontsize=7 labelfontname=Arial labelfontsize=7 labeldistance=5];
        
    
        `

    let Packages = {};
    Packages[Package._id] = Package;

    for (let idNode in dotNodes){
        let dotNode = dotNodes[idNode]
        if (dotNode.Data){
            if (dotNode.Data.Package){
                Packages[dotNode.Data.Package._id] = dotNode.Data.Package;
            }
        }
    }

// create clusters for each package

    for (let idClusterPackage in Packages){
        const ClusterPackage = Packages[idClusterPackage];
        dot += `
        subgraph cluster_${idClusterPackage} {
            style=filled;
            color=grey92;
            label = "${ClusterPackage.name}";
            fontname="Arial";
            fontsize=7;
        `
       
        for (let idSystem in Systems){
            const System = Systems[idSystem];
    
            switch (System.status){
                case 'deprecated':
                    break;
                default:

                    dot += `
                    subgraph cluster_system_${System._id} {
                        style=filled;
                        color=seagreen2;
                        label = "${dotField(System.name)}";
                        fontname="Arial";
                        fontsize=7;
                        URL="system_${System._id}";
                    `

                    dot += `
                        {
                            rank=same;
                    `;

                    let boolSystemHasUseCase = false;
                    for (let idNode in dotNodes){
                        let dotNode = dotNodes[idNode]
                        if (dotNode.Data){
                            if (dotNode.Data.idSystem){
                                if (dotNode.Data.idSystem == System._id){
                                    boolSystemHasUseCase = true;
                                    dot += `
                            ${dotNode.idNode}
                                    `

                                }
                            }
                        }
                    }

                    if (!boolSystemHasUseCase){
                        dot += `
                        inviz_${System._id} [style=invis, height=0, width=0];
                        `
                    }

                    dot += `
                        }
                    `;




                    dot += `
                    }
                    `
                    break;

            }
        }
    
       
        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode]
                dot += `
            ${this.makeNodeDot(dotNode)}`;
        }

        dot += `
        }            
        `

    }

    for (let idEdge in dotEdges){
        let Edge = dotEdges[idEdge];
        dot += `
        ${Edge.dot}`;
    }


    dot += `}
        `; 

    return {"dot":dot,"nodes":dotNodes};

}


vizUseCase(Data={}, Options = {}, engine = "dot" ){

    let dotNodes = {};
    let dotEdges = {};

    let UseCase = {};
    let Package = {};
    let Actors = {};
    let Systems = {};
    let Associations = [];

    if (Data.UseCase){
        UseCase = cloneObject(Data.UseCase);

        if (Data.UseCase.Package){
            Package = Data.UseCase.Package;
        }
        if (Data.UseCase.Associations){
            Associations = Data.UseCase.Associations;
        }
    }

    if (UseCase){
        let idNode = `usecase_${UseCase._id}`;
        if (!(idNode in dotNodes)){
            dotNodes[idNode] = this.makeUseCaseNode(UseCase, idNode);
            if (UseCase.System){
                Systems[UseCase.System._id] = UseCase.System;
            }
        }
    }

    for (let pos=0; pos<Associations.length; pos++){
        const Association = Associations[pos];

        switch (Association.status){
            case 'deprecated':
                break;
            default:
                if (Association.Actor){

                    let idNodeActor = `actor_${Association.Actor._id}`;
                    if (!(idNodeActor in dotNodes)){
                        dotNodes[idNodeActor] = this.makeActorNode(Association.Actor, idNodeActor);
                    }


                    let idNodeUseCase = `usecase_${Association.UseCase._id}`;
                    if (!(idNodeUseCase in dotNodes)){
                        dotNodes[idNodeUseCase] = this.makeUseCaseNode(Association.UseCase, idNodeUseCase);
                        if (Association.UseCase.System){
                            Systems[Association.UseCase.System._id] = Association.UseCase.System;
                        }
                    }

                    const idEdge = `association_${Association._id}`;
                    dotEdges[idEdge] = this.makeEdge(Association.name, dotNodes[idNodeActor], dotNodes[idNodeUseCase]);

                }                
                break;
        }
    }



    let dot = '';

    let Key = {};

    dot += `digraph  { 
        rankdir=LR;
        graph [overlap=false splines=true nodesep=0.6 ranksep=0.8 ];
        node [color=black fillcolor=wheat fontname=Arial fontsize=7];
        edge [fontname=Arial fontsize=7 labelfontname=Arial labelfontsize=7 labeldistance=5];
        
    
        `

    let Packages = {};
    Packages[Package._id] = Package;

    for (let idNode in dotNodes){
        let dotNode = dotNodes[idNode]
        if (dotNode.Data){
            if (dotNode.Data.Package){
                Packages[dotNode.Data.Package._id] = dotNode.Data.Package;
            }
        }
    }

// create clusters for each package

    for (let idClusterPackage in Packages){
        const ClusterPackage = Packages[idClusterPackage];
        dot += `
        subgraph cluster_${idClusterPackage} {
            style=filled;
            color=grey92;
            label = "${ClusterPackage.name}";
            fontname="Arial";
            fontsize=7;
        `
       
        for (let idSystem in Systems){
            const System = Systems[idSystem];
    
            switch (System.status){
                case 'deprecated':
                    break;
                default:

                    dot += `
                    subgraph cluster_system_${System._id} {
                        style=filled;
                        color=seagreen2;
                        label = "${dotField(System.name)}";
                        fontname="Arial";
                        fontsize=7;
                        URL="system_${System._id}";
                    `

                    dot += `
                        {
                            rank=same;
                    `;

                    let boolSystemHasUseCase = false;
                    for (let idNode in dotNodes){
                        let dotNode = dotNodes[idNode]
                        if (dotNode.Data){
                            if (dotNode.Data.idSystem){
                                if (dotNode.Data.idSystem == System._id){
                                    boolSystemHasUseCase = true;
                                    dot += `
                            ${dotNode.idNode}
                                    `

                                }
                            }
                        }
                    }

                    if (!boolSystemHasUseCase){
                        dot += `
                        inviz_${System._id} [style=invis, height=0, width=0];
                        `
                    }

                    dot += `
                        }
                    `;




                    dot += `
                    }
                    `
                    break;

            }
        }
    
       
        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode]
                dot += `
            ${this.makeNodeDot(dotNode)}`;
        }

        dot += `
        }            
        `

    }

    for (let idEdge in dotEdges){
        let Edge = dotEdges[idEdge];
        dot += `
        ${Edge.dot}`;
    }


    dot += `}
        `; 

    return {"dot":dot,"nodes":dotNodes};

}




vizSystem(Data={}, Options = {}, engine = "dot" ){

    let dotNodes = {};
    let dotEdges = {};

    let System = {};
    let Package = {};
    let Systems = {};
    let Actors = {};
    let UseCases = {};
    let Associations = [];

    if (Data.System){
        System = cloneObject(Data.System);

        if (System.Package){
            Package = System.Package;
        }
        Systems[System._id] = System;
        if (System.UseCases){
            UseCases = System.UseCases;
        }
    }

    for (let pos=0; pos<UseCases.length; pos++){
        const UseCase = UseCases[pos];
        switch (UseCase.status){
            case 'deprecated':
                break;
            default:
                let idNode = `usecase_${UseCase._id}`;
                if (!(idNode in dotNodes)){
                    dotNodes[idNode] = this.makeUseCaseNode(UseCase, idNode);
                    if (UseCase.System){
                        Systems[UseCase.System._id] = UseCase.System;
                    }
                }
                if (UseCase.Associations){

                    for (let posA=0; posA<UseCase.Associations.length; posA++){
                        const Association = UseCase.Associations[posA];

                        switch (Association.status){
                            case 'deprecated':
                                break;
                            default:
                                if (Association.Actor){

                                    let idNodeActor = `actor_${Association.Actor._id}`;
                                    if (!(idNodeActor in dotNodes)){
                                        dotNodes[idNodeActor] = this.makeActorNode(Association.Actor, idNodeActor);
                                    }

                                    let idNodeUseCase = `usecase_${Association.UseCase._id}`;
                                    if (!(idNodeUseCase in dotNodes)){
                                        dotNodes[idNodeUseCase] = this.makeUseCaseNode(Association.UseCase, idNodeUseCase);
                                        if (Association.UseCase.System){
                                            Systems[Association.UseCase.System._id] = Association.UseCase.System;
                                        }
                                    }

                                    const idEdge = `association_${Association._id}`;
                                    dotEdges[idEdge] = this.makeEdge(Association.name, dotNodes[idNodeActor], dotNodes[idNodeUseCase]);

                                }                
                                break;
                        }
                    }
                }
        }
    }


    let dot = '';

    let Key = {};

    dot += `digraph  { 
        rankdir=LR;
        graph [overlap=false splines=true nodesep=0.6 ranksep=0.8 ];
        node [color=black fillcolor=wheat fontname=Arial fontsize=7];
        edge [fontname=Arial fontsize=7 labelfontname=Arial labelfontsize=7 labeldistance=5];
        
    
        `

    let Packages = {};
    Packages[Package._id] = Package;

    for (let idNode in dotNodes){
        let dotNode = dotNodes[idNode]
        if (dotNode.Data){
            if (dotNode.Data.Package){
                Packages[dotNode.Data.Package._id] = dotNode.Data.Package;
            }
        }
    }

// create clusters for each package

    for (let idClusterPackage in Packages){
        const ClusterPackage = Packages[idClusterPackage];
        dot += `
        subgraph cluster_${idClusterPackage} {
            style=filled;
            color=grey92;
            label = "${ClusterPackage.name}";
            fontname="Arial";
            fontsize=7;
        `
       
        for (let idSystem in Systems){
            const System = Systems[idSystem];
    
            switch (System.status){
                case 'deprecated':
                    break;
                default:

                    dot += `
                    subgraph cluster_system_${System._id} {
                        style=filled;
                        color=seagreen2;
                        label = "${dotField(System.name)}";
                        fontname="Arial";
                        fontsize=7;
                        URL="system_${System._id}";
                    `

                    dot += `
                        {
                            rank=same;
                    `;

                    let boolSystemHasUseCase = false;
                    for (let idNode in dotNodes){
                        let dotNode = dotNodes[idNode]
                        if (dotNode.Data){
                            if (dotNode.Data.idSystem){
                                if (dotNode.Data.idSystem == System._id){
                                    boolSystemHasUseCase = true;
                                    dot += `
                            ${dotNode.idNode}
                                    `

                                }
                            }
                        }
                    }

                    if (!boolSystemHasUseCase){
                        dot += `
                        inviz_${System._id} [style=invis, height=0, width=0];
                        `
                    }

                    dot += `
                        }
                    `;




                    dot += `
                    }
                    `
                    break;

            }
        }
    
       
        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode]
                dot += `
            ${this.makeNodeDot(dotNode)}`;
        }

        dot += `
        }            
        `

    }

    for (let idEdge in dotEdges){
        let Edge = dotEdges[idEdge];
        dot += `
        ${Edge.dot}`;
    }


    dot += `}
        `; 

    return {"dot":dot,"nodes":dotNodes};

}


addPackage( Data = {}){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadPackage(Data)

    this.httpConfig.post(`/packages`, payload, headers)
         .then(response => {
            let StateChange = {};
            StateChange.isUpdated = true;
            StateChange.idPackage = response.data._id;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            this.StateObject.setState({ isFailed: true });
            if (e.response){
                if (e.response.data){
                    if (e.response.data.fields){
                        this.StateObject.setState({ invalidFields:e.response.data.fields});
                    }
                }
            }
            console.log(e);
        });

    return;
}


editPackage(id = null, Data = {}){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }


    let payload = this.payloadPackage(Data)

    this.httpConfig.put(`/packages/${id}`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange.isUpdated = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            this.StateObject.setState({ isFailed: true });
            if (e.response){
                if (e.response.data){
                    if (e.response.data.fields){
                        this.StateObject.setState({ invalidFields:e.response.data.fields});
                    }
                }
            }
            console.log(e);
        });

    return true;
};

payloadPackage(data){

    let payload = {}
    payload.name = data.name;
    payload.description = data.description;
    payload.namespace = data.namespace;
    payload.prefix = data.prefix;
    payload.proposals = data.proposals;
    payload.status = data.status;

    return payload;

}

getPackage(id=null,  nameStateVar = "Package", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    this.httpConfig.get(`/packages/${id}`, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = this.dataPackage(response.data);
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};


listPackages(Filters={}, nameStateVar = "Packages", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/packages"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idUser){
        urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
    }

    if (Filters.scope){
        urlAPI += `scope=${encodeURIComponent(Filters.scope)}&`;
    }

    if (Filters.status){
        urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
    }

    if (Filters.search){
        urlAPI += `search=${encodeURIComponent(Filters.search)}&`;
    }

    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
              arr.push(this.dataPackage(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
          })
          .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
          });

    return;

};



dataPackage(data){

    let obj = data;

    return obj;

}


// ==== Actors ====

addActorToPackage( idPackage, Data = {}, nameIdVar = "idActor", nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadActor(Data)

    this.httpConfig.post(`/packages/${idPackage}/actors`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            StateChange[nameIdVar] = response.data._id;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;
}


editActor(id = null, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){


    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }


    let payload = this.payloadActor(Data)
    let idPackage = null;
    if (Data.Package) {
        idPackage = Data.Package._id;
    }

    this.httpConfig.put(`/packages/${idPackage}/actors/${id}`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return true;
};

payloadActor(inData){

    let data = cloneObject(inData)

    let payload = {}
    payload.name = data.name;
    payload.description = data.description;
    payload.proposals = data.proposals;
    payload.type = data.type;
    payload.status = data.status;

    return payload;

}

getActor(id=null,  nameStateVar = "Actor", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    this.httpConfig.get(`/actors/${id}`, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = this.dataActor(response.data);
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



listActors(Filters={}, nameStateVar = "Actors", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/actors"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idUser){
        urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
    }

    if (Filters.status){
        urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
    }

    if (Filters.idPackage){
        urlAPI += `idPackage=${encodeURIComponent(Filters.idPackage)}&`;
    }

    if (Filters.idNarrative){
        urlAPI += `idNarrative=${encodeURIComponent(Filters.idNarrative)}&`;
    }


    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
                arr.push(this.dataActor(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



dataActor(data){

    let obj = data;

    return obj;

}


// ==== Systems ====

addSystemToPackage( idPackage, Data = {}, nameIdVar = "idSystem", nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadSystem(Data)

    this.httpConfig.post(`/packages/${idPackage}/systems`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            StateChange[nameIdVar] = response.data._id;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;
}


editSystem(id = null, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){


    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }


    let payload = this.payloadSystem(Data)
    let idPackage = null;
    if (Data.Package) {
        idPackage = Data.Package._id;
    }

    this.httpConfig.put(`/packages/${idPackage}/systems/${id}`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return true;
};

payloadSystem(inData){

    let data = cloneObject(inData)

    let payload = {}
    payload.name = data.name;
    payload.description = data.description;
    payload.proposals = data.proposals;
    payload.status = data.status;

    return payload;

}

getSystem(id=null,  nameStateVar = "System", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    this.httpConfig.get(`/systems/${id}`, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = this.dataSystem(response.data);
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



listSystems(Filters={}, nameStateVar = "Systems", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/systems"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idUser){
        urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
    }

    if (Filters.status){
        urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
    }

    if (Filters.idPackage){
        urlAPI += `idPackage=${encodeURIComponent(Filters.idPackage)}&`;
    }

    if (Filters.idNarrative){
        urlAPI += `idNarrative=${encodeURIComponent(Filters.idNarrative)}&`;
    }


    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
                arr.push(this.dataSystem(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



dataSystem(data){

    let obj = data;

    return obj;

}

// ==== Use Cases ====

addUseCaseToPackage( idPackage, Data = {}, nameIdVar = "idUseCase", nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadUseCase(Data)

    this.httpConfig.post(`/packages/${idPackage}/usecases`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            StateChange[nameIdVar] = response.data._id;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;
}


editUseCase(id = null, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){


    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }


    let payload = this.payloadUseCase(Data)
    let idPackage = null;
    if (Data.Package) {
        idPackage = Data.Package._id;
    }

    this.httpConfig.put(`/packages/${idPackage}/usecases/${id}`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return true;
};

payloadUseCase(inData){

    let data = cloneObject(inData)

    let payload = {}
    payload.name = data.name;
    payload.description = data.description;
    payload.proposals = data.proposals;
    if (data.idSystem){
        payload.idSystem = data.idSystem;
    }
    payload.status = data.status;

    return payload;

}

getUseCase(id=null,  nameStateVar = "UseCase", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    this.httpConfig.get(`/usecases/${id}`, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = this.dataUseCase(response.data);
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



listUseCases(Filters={}, nameStateVar = "UseCases", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/usecases"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idUser){
        urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
    }

    if (Filters.status){
        urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
    }

    if (Filters.idPackage){
        urlAPI += `idPackage=${encodeURIComponent(Filters.idPackage)}&`;
    }

    if (Filters.idSystem){
        urlAPI += `idSystem=${encodeURIComponent(Filters.idSystem)}&`;
    }


    if (Filters.idNarrative){
        urlAPI += `idNarrative=${encodeURIComponent(Filters.idNarrative)}&`;
    }


    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
                arr.push(this.dataUseCase(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



dataUseCase(data){

    let obj = data;

    return obj;

}

// ==== Associations ====

addAssociationToPackage( idPackage, Data = {}, nameIdVar = "idAssociation", nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadAssociation(Data)

    this.httpConfig.post(`/packages/${idPackage}/associations`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            StateChange[nameIdVar] = response.data._id;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            if (e.response){
                if (e.response.data){
                    if (e.response.data.fields){
                        this.StateObject.setState({ invalidFields:e.response.data.fields});
                    }
                }
            }
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;
}


editAssociation(id = null, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){


    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }


    let payload = this.payloadAssociation(Data)
    let idPackage = null;
    if (Data.Package) {
        idPackage = Data.Package._id;
    }

    this.httpConfig.put(`/packages/${idPackage}/associations/${id}`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return true;
};

payloadAssociation(inData){

    let data = cloneObject(inData)

    let payload = {}
    payload.name = data.name;
    payload.type = data.type;
    payload.description = data.description;
    payload.proposals = data.proposals;
    payload.status = data.status;
    payload.idUseCase = data.idUseCase;
    payload.idActor = data.idActor;

    return payload;

}

getAssociation(id=null,  nameStateVar = "Association", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    this.httpConfig.get(`/associations/${id}`, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = this.dataAssociation(response.data);
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



listAssociations(Filters={}, nameStateVar = "Associations", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/associations"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idUser){
        urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
    }

    if (Filters.status){
        urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
    }

    if (Filters.idPackage){
        urlAPI += `idPackage=${encodeURIComponent(Filters.idPackage)}&`;
    }

    if (Filters.idActor){
        urlAPI += `idActor=${encodeURIComponent(Filters.idActor)}&`;
    }

    if (Filters.idUseCase){
        urlAPI += `idUseCase=${encodeURIComponent(Filters.idUseCase)}&`;
    }

    if (Filters.idNarrative){
        urlAPI += `idNarrative=${encodeURIComponent(Filters.idNarrative)}&`;
    }


    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
                arr.push(this.dataAssociation(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



dataAssociation(data){

    let obj = data;

    return obj;

}




// ==== Narratives ====

addNarrative( Data = {}){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadNarrative(Data)

    this.httpConfig.post(`/narratives`, payload, headers)
         .then(response => {
            let StateChange = {};
            StateChange.isUpdated = true;
            StateChange.idNarrative = response.data._id;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            this.StateObject.setState({ isFailed: true });
            console.log(e);
        });

    return;
}


editNarrative(id = null, Data = {}){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }


    let payload = this.payloadNarrative(Data)

    this.httpConfig.put(`/narratives/${id}`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange.isUpdated = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            this.StateObject.setState({ isFailed: true });
            console.log(e);
        });

    return true;
};

payloadNarrative(data){

    let payload = {}
    payload.name = data.name;
    payload.description = data.description;
    payload.proposals = data.proposals;
    payload.status = data.status;

    payload.Concepts = [];

    if (data.Keys){
        payload.Keys = data.Keys;
    }
    if (data.Concepts){
        payload.Concepts = data.Concepts;
    }
    if (data.Links){
        payload.Links = data.Links;
    }


    return payload;

}

getNarrative(id=null,  nameStateVar = "Narrative", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    this.httpConfig.get(`/narratives/${id}`, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = this.dataNarrative(response.data);
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};


listNarratives(Filters={}, nameStateVar = "Narratives", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/narratives"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idUser){
        urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
    }

    if (Filters.scope){
        urlAPI += `scope=${encodeURIComponent(Filters.scope)}&`;
    }

    if (Filters.status){
        urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
    }

    if (Filters.search){
        urlAPI += `search=${encodeURIComponent(Filters.search)}&`;
    }
    
    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
              arr.push(this.dataNarrative(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
          })
          .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
          });

    return;

};



dataNarrative(data){

    let obj = data;

    return obj;

}



NarrativeAddConcept( idNarrative, idConcept, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadNarrativeConcept(Data)
    payload.idConcept = idConcept

    this.httpConfig.post(`/narratives/${idNarrative}/concepts`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            if (e.response.data){
                if (e.response.data.message){
                    StateChange[nameErrorMessageVar] = e.response.data.message;
                }
            }
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;
}

payloadNarrativeConcept(data){

    let payload = {}

    return payload;

}




vizNarrative(Data={}, Options = {}, engine = "dot" ){

    let dotNodes = {};
    let dotEdges = {};

    let Narrative = {};
    let Links = {};

    if (Data.Narrative){
        Narrative = cloneObject(Data.Narrative);
    }


    for (let posC=0; posC<Narrative.Concepts.length; posC++){
        if (Narrative.Concepts[posC].Concept){
            const Concept = Narrative.Concepts[posC].Concept;
            let idNode = `concept_${Concept._id}`;
// find the key if present

            let Key = null;
            if (Narrative.Keys){
                if (Narrative.Concepts[posC].seqKey){
                    if (Narrative.Concepts[posC].seqKey <= Narrative.Keys.length){
                        Key = Narrative.Keys[Narrative.Concepts[posC].seqKey-1]
                    }
                }
            }

            if (!(idNode in dotNodes)){
                dotNodes[idNode] = this.makeConceptNode(Concept, idNode, Key);
            }
        }
    }

// super concepts
    for (const idNode in dotNodes){
        const dotNode = dotNodes[idNode];
        if (dotNode.Data){
            if (dotNode.Data.SuperConcepts){

                for (let posS=0; posS<dotNode.Data.SuperConcepts.length; posS++){
                    const SuperConcept = dotNode.Data.SuperConcepts[posS];
                    if (!SuperConcept.idConcept){
                        continue;
                    }
                    let idSuperNode = `concept_${SuperConcept.idConcept}`;
                    if (!(idSuperNode in dotNodes)){
                        continue;
                    }

                    let idEdge = `super_${dotNode.Data._id}_${posS+1}`;

                    dotEdges[idEdge] = this.makeEdge(null,dotNode, dotNodes[idSuperNode], 'dashed')

                }
            }
        }
    }


    for (let posR=0; posR<Narrative.Links.length; posR++){
        if (Narrative.Links[posR].Link){
            const Link = Narrative.Links[posR].Link;
            let idEdge = `link_${Link._id}`;
            let idNodeFrom = null;
            let idNodeTo = null;
            if (Link.idConceptFrom){
                idNodeFrom = `concept_${Link.idConceptFrom}`;
                if (!(idNodeFrom in dotNodes)){
                    continue;
                }    
            }
            if (Link.idConceptTo){
                idNodeTo = `concept_${Link.idConceptTo}`;
                if (!(idNodeTo in dotNodes)){
                    continue;
                }    
            }

            if (!(idEdge in dotEdges)){
                switch (Narrative.Links[posR].inverse){
                    case true:
                        dotEdges[idEdge] = this.makeEdge(Link.inverseLabel,dotNodes[idNodeTo], dotNodes[idNodeFrom])
                        break;
                    default:
                        dotEdges[idEdge] = this.makeEdge(Link.label,dotNodes[idNodeFrom], dotNodes[idNodeTo])
                        break;
                }
            }
        }
    }



    let dot = '';

    let Key = {};

    dot += `digraph  { 
        overlap=false; 
        splines=true; 
        newrank=true;
        nodesep=0.6;
        ranksep=0.8;

        node [fixedsize=true height=0.8 style=filled width=1.0 color=black fillcolor=lightblue fontname=Arial fontsize=7];
        edge [fontname=Arial fontsize=7 labelfontname=Arial labelfontsize=7 labeldistance=5];
        `


    let ConceptModels = {};
    for (let idNode in dotNodes){
        let dotNode = dotNodes[idNode]
        if (dotNode.Data){
            if (dotNode.Data.ConceptModel){
                ConceptModels[dotNode.Data.ConceptModel._id] = dotNode.Data.ConceptModel;
            }
        }
    }

// create clusters for each concept models

    for (let idClusterConceptModel in ConceptModels){
        const ClusterConceptModel = ConceptModels[idClusterConceptModel];
        dot += `
        subgraph cluster_${idClusterConceptModel} {
            style=filled;
            color=grey92;
            node [style=filled];
            label = "${ClusterConceptModel.name}";
            fontname="Arial";
            fontsize=7;
        `
        for (let idNode in dotNodes){
            let dotNode = dotNodes[idNode]
            if (dotNode.Data.ConceptModel._id == idClusterConceptModel){
                dot += `
            ${this.makeNodeDot(dotNode)}`;
            }
        }

        dot += `
        }            
        `

    }



    for (let idEdge in dotEdges){
        let Edge = dotEdges[idEdge];
        dot += `
        ${Edge.dot}`;
    }



    if (Narrative.Keys){
        if (Narrative.Keys.length){
            dot += `
        subgraph cluster_key { rank="min"; labeljust=l; label = <<B>Key</B>> ;fontname=Arial; fontcolor=black; fontsize=7; 
            key [  fillcolor=white, shape=plaintext,  label=<
                <table border='0' cellpadding = '2' cellborder='0' cellspacing='0'>`
            for (let posKey=0; posKey<Narrative.Keys.length; posKey++){
                const Key = Narrative.Keys[posKey];
                dot += `
                        <tr><td align='left' balign='left' valign='top' bgcolor='${Key.color}'> </td><td align='left' balign='left' valign='top'>${Key.legend}</td></tr>
                `
            }
            dot += `
                    </table>
                >];                 
        }
        `
        }
    }



    dot += `
    }`; 

    return {"dot":dot,"nodes":dotNodes};

}

// data dictionaries

addDataDictionary( Data = {}){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadDataDictionary(Data)

    this.httpConfig.post(`/datadictionaries`, payload, headers)
         .then(response => {
            let StateChange = {};
            StateChange.isUpdated = true;
            StateChange.idDataDictionary = response.data._id;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            this.StateObject.setState({ isFailed: true });
            console.log(e);
        });

    return;
}


editDataDictionary(id = null, Data = {}){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }


    let payload = this.payloadDataDictionary(Data)

    this.httpConfig.put(`/datadictionaries/${id}`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange.isUpdated = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            this.StateObject.setState({ isFailed: true });
            console.log(e);
        });

    return true;
};

payloadDataDictionary(data){

    let payload = {}
    payload.name = data.name;
    payload.description = data.description;
    payload.namespace = data.namespace;
    payload.prefix = data.prefix;
    payload.proposals = data.proposals;
    payload.status = data.status;

    return payload;

}

getDataDictionary(id=null,  nameStateVar = "DataDictionary", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    this.httpConfig.get(`/datadictionaries/${id}`, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = this.dataDataDictionary(response.data);
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};


listDataDictionaries(Filters={}, nameStateVar = "DataDictionaries", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/datadictionaries"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idUser){
        urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
    }

    if (Filters.scope){
        urlAPI += `scope=${encodeURIComponent(Filters.scope)}&`;
    }

    if (Filters.status){
        urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
    }

    if (Filters.search){
        urlAPI += `search=${encodeURIComponent(Filters.search)}&`;
    }
    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
              arr.push(this.dataDataDictionary(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
          })
          .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
          });

    return;

};



dataDataDictionary(data){

    let obj = data;

    return obj;

}

// Structures


vizStructure(Data={}, Options = {}, engine = "dot" ){

    let dotNodes = {};
    let dotEdges = {};

    let Structure = {};

    if (Data.Structure){
        Structure = cloneObject(Data.Structure);
        let idNode = `structure_${Structure._id}`;
        if (!(idNode in dotNodes)){
            dotNodes[idNode] = this.makeStructureNode(Structure, idNode);

            if (Structure.Attributes){
                for (let posA=0; posA<Structure.Attributes.length; posA++){
                    const Attribute = Structure.Attributes[posA];
                    if (Attribute.type == 'structure'){
                        this.makeStructureNodes(Attribute.Structure, idNode, `attribute_${Attribute._id}` ,dotNodes,dotEdges);
                    } 
                }
            }
        }


    }

    let dot = '';

    let Key = {};

    dot += `digraph  { 
        graph [overlap=false splines=true nodesep=0.6 ranksep=0.8]
        node [color=black fillcolor=wheat fontname=Arial fontsize=7]
        edge [fontname=Arial fontsize=7 labelfontname=Arial labelfontsize=7 labeldistance=5]
    
        `
    for (let idNode in dotNodes){
        let dotNode = dotNodes[idNode]
            dot += `
        ${this.makeNodeDot(dotNode)}`;
    }

    for (let idEdge in dotEdges){
        let Edge = dotEdges[idEdge];
        dot += `
        ${Edge.dot}`;
    }



    dot += `}
        `; 

    return {"dot":dot,"nodes":dotNodes};

}


addStructureToModel( idDataDictionary, Data = {}, nameIdVar = "idStructure", nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadStructure(Data)

    this.httpConfig.post(`/datadictionaries/${idDataDictionary}/structures`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            StateChange[nameIdVar] = response.data._id;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;
}


editStructure(id = null, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){


    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }


    let payload = this.payloadStructure(Data)
    let idDataDictionary = null;
    if (Data.DataDictionary) {
        idDataDictionary = Data.DataDictionary._id;
    }

    this.httpConfig.put(`/datadictionaries/${idDataDictionary}/structures/${id}`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return true;
};

payloadStructure(data){

    let payload = {}
    payload.name = data.name;
    payload.description = data.description;
    payload.proposals = data.proposals;
    payload.status = data.status;

    payload.Concepts = [];
    if (data.Concepts){
        payload.Concepts = data.Concepts;
    }

    payload.Attributes = [];
    if (data.Attributes){
        payload.Attributes = data.Attributes;
    }

    return payload;

}

getStructure(id=null,  nameStateVar = "Structure", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    this.httpConfig.get(`/structures/${id}`, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = this.dataStructure(response.data);
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



listStructures(Filters={}, nameStateVar = "Structures", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/structures"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idUser){
        urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
    }

    if (Filters.status){
        urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
    }

    if (Filters.idDataDictionary){
        urlAPI += `idDataDictionary=${encodeURIComponent(Filters.idDataDictionary)}&`;
    }

    if (Filters.idSubStructure){
        urlAPI += `idSubStructure=${encodeURIComponent(Filters.idSubStructure)}&`;
    }

    if (Filters.idNarrative){
        urlAPI += `idNarrative=${encodeURIComponent(Filters.idNarrative)}&`;
    }


    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
            arr.push(this.dataStructure(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



dataStructure(data){

    let obj = data;

    return obj;

}





// Term Types

addTermTypeToDictionary( idDataDictionary, Data = {}, nameIdVar = "idTermType", nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let payload = this.payloadTermType(Data)

    this.httpConfig.post(`/datadictionaries/${idDataDictionary}/termtypes`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            StateChange[nameIdVar] = response.data._id;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;
}


editTermType(id = null, Data = {}, nameIsUpdatedVar = "isUpdated", nameIsErrorVar = "isError", nameErrorMessageVar = "errorMessage"){


    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }


    let payload = this.payloadTermType(Data)
    let idDataDictionary = null;
    if (Data.DataDictionary) {
        idDataDictionary = Data.DataDictionary._id;
    }

    this.httpConfig.put(`/datadictionaries/${idDataDictionary}/termtypes/${id}`, payload, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsUpdatedVar] = true;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsErrorVar] = true;
            StateChange[nameErrorMessageVar] = e.message;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return true;
};

payloadTermType(data){

    let payload = {}
    payload.name = data.name;
    payload.description = data.description;
    payload.notes = data.notes;
    payload.proposals = data.proposals;
    payload.status = data.status;
    payload.idStructure = data.idStructure;

    return payload;

}

getTermType(id=null,  nameStateVar = "TermType", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    this.httpConfig.get(`/termtypes/${id}`, headers)
        .then(response => {
            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = this.dataTermType(response.data);
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



listTermTypes(Filters={}, nameStateVar = "TermTypes", nameIsLoadedVar = "isLoaded", nameIsFailedVar = "isFailed", nameErrorMessageVar = "errorMessage"){

    let headers = {
        headers: 
            {
                "Content-type": "application/json"
            }
        }

    let authHeader = this.authHeader();
    if (authHeader){
        headers.headers.Authorization = authHeader.Authorization;
    }

    let urlAPI = "/termtypes"
    if (Object.keys(Filters).length > 0){
        urlAPI += "?"
    }
    
    if (Filters.idUser){
        urlAPI += `idUser=${encodeURIComponent(Filters.idUser)}&`;
    }

    if (Filters.status){
        urlAPI += `status=${encodeURIComponent(Filters.status)}&`;
    }

    if (Filters.idDataDictionary){
        urlAPI += `idDataDictionary=${encodeURIComponent(Filters.idDataDictionary)}&`;
    }

    if (Filters.idNarrative){
        urlAPI += `idNarrative=${encodeURIComponent(Filters.idNarrative)}&`;
    }

    if (Filters.scope){
        urlAPI += `scope=${encodeURIComponent(Filters.scope)}&`;
    }


    this.httpConfig.get(urlAPI, headers)
        .then(response => {

            let arr = [];
            for (let pos=0; pos<response.data.length;pos++){
                arr.push(this.dataTermType(response.data[pos]));
            }

            let StateChange = {};
            StateChange[nameIsLoadedVar] = true;
            StateChange[nameStateVar] = arr;
            this.StateObject.setState(StateChange);
        })
        .catch(e => {
            let StateChange = {};
            StateChange[nameIsFailedVar] = true;
            this.StateObject.setState(StateChange);
            console.log(e);
        });

    return;

};



dataTermType(data){

    let obj = data;

    return obj;

}


};

export default dsd;