import { authHeader } from '../_helpers/auth-header.js';

export const userService = {
    login,
    logout,
    getAll
};

function login(username, password) {

    let urlOrigin = window.location.origin;
    let urlParts = new URL(urlOrigin);

    let urlAPI = `${urlParts.protocol}//${urlParts.hostname}/api/dsd`;

    if (urlParts.hostname == 'localhost'){
        urlAPI = `${urlParts.protocol}//${urlParts.hostname}:5050`;
    }
    if (urlParts.hostname.startsWith('192.168')){
        urlAPI = `${urlParts.protocol}//${urlParts.hostname}:5050`;
    }

/*
//    AWS AL2
    let apiUrl = "http://52.56.166.229/api/dsd";
*/


/*
//    AWS DSD AL2023
    let apiUrl = "http://35.176.188.38/api/dsd";
*/

/*
//    Local Development - client and server different but on the same network
    let apiUrl = "http://192.168.0.110:5005";
*/

// local Development - client and server same m/c
//     let apiUrl = "http://localhost:5050";


    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };



    return fetch(`${urlAPI}/users/authenticate`, requestOptions)

        .then(handleResponse)
        .then(user => {
            // login successful if there's a user in the response
            if (user) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                user.authdata = window.btoa(username + ':' + password);
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });

}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let urlOrigin = window.location.origin;
    let urlParts = new URL(urlOrigin);

    let urlAPI = `${urlParts.protocol}//${urlParts.hostname}/api/dsd`;

    if (urlParts.hostname == 'localhost'){
        urlAPI = `${urlParts.protocol}//${urlParts.hostname}:5050`;
    }
    if (urlParts.hostname.startsWith('192.168')){
        urlAPI = `${urlParts.protocol}//${urlParts.hostname}:5050`;
    }

    return fetch(`${urlAPI}/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        
//        const location = useLocation();

        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();

                window.location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}