import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import {cloneObject, getParam} from '../utils/utils';

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';

import Buffering from "../components/Buffering"; 



class SelectUseCase extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            renderState: null
        };

        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.handleChange = this.handleChange.bind(this);
    

    }

    componentDidMount(){

        this.setup();
 
     }
 
     componentDidUpdate(prevProps, prevState){
 
        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){


            if (this.state.idSelected != prevState.idSelected){
                if (this.state.idSelected){
                    this.state.loadSelectedUseCase = true;
                }
            }

            if (this.state.isLoadedSelectedUseCase){
                if (this.state.SelectedUseCase.Package){
                    StateChange.idPackage = this.state.SelectedUseCase.Package._id;
                }

            }

            if (this.state.idPackage != prevState.idPackage){
                if (this.state.idPackage){
                    StateChange.loadUseCases = true;
                }
            }

            if (this.state.loadPackages){
                this.loadPackages();
            }

            if (this.state.loadUseCases){
                this.loadUseCases();
            }

            if (this.state.loadSelectedUseCase){
                this.loadSelectedUseCase(this.state.idSelected);
                if (this.state.onBlur){
                    this.state.onBlur();
                }
            }


            if (this.state.isFailed){
                StateChange.renderState = "failed";
            }

            if (this.state.renderState == "loading"){
                if (this.state.isLoadedPackages){
                    StateChange.renderState = "loaded";
                }

                if (this.state.isLoadedUseCases){
                    StateChange.renderState = "loaded";
                }
            }
             
            this.setState(StateChange);

        }
        

    
    }
 

    setup(){

        let StateChange = {
            renderState: null,

            loadPackages: false,
            loadUseCases: false,
            loadSelectedUseCase: false,

            selectPackage: false,

            label: "UseCase",
            idSelected: "",
            idPackage: null,

            onChange: null,
            onBlur: null

        }

        StateChange.name = getParam('name', this.props);

        if (getParam('label',this.props)){
            StateChange.label = getParam('label',this.props);
        }

        if (getParam('selectPackage',this.props)){
            StateChange.selectPackage = getParam('selectPackage',this.props);
        }


        StateChange.onChange = getParam('onChange', this.props);
        StateChange.onBlur = getParam('onBlur', this.props);

        StateChange.idPackage = getParam('idPackage', this.props);

        StateChange.idSelected = getParam('value', this.props);


        if (StateChange.selectPackage){
            StateChange.loadPAckages = true;
        }
        else
        {
            StateChange.loadUseCases = true;
        }

        this.setState(StateChange);

    }

    loadSelectedUseCase(idUseCase){

        let StateChange = {};
        StateChange.loadSelectedUseCase = false;
        StateChange.isLoadedSelectedUseCase = false;
        StateChange.SelectedUseCase = null;

        StateChange.renderState = "loading";
        this.dsd.getUseCase(idUseCase,"SelectedUseCase","isLoadedSelectedUseCase")
        
        this.setState(StateChange);

        return;
        
    };



    loadUseCases(){

        let StateChange = {};
        StateChange.loadUseCases = false;
        StateChange.isLoadedUseCases = false;
        StateChange.UseCases = null;

        StateChange.renderState = "loading";
        this.dsd.listUseCases({idPackage:this.state.idPackage},"UseCases","isLoadedUseCases")
        
        this.setState(StateChange);

        return;
        
    };


    loadPackages(){

        let StateChange = {};
        StateChange.loadPackages = false;
        StateChange.isLoadedPackages = false;
        StateChange.Packages = null;

        StateChange.renderState = "loading";
        this.dsd.listPackages({},"Packages","isLoadedPackages");
        
        this.setState(StateChange);

        return;
        
    };



    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        switch (FormFieldName){
            case "idPackage":
                this.setState({"idPackage":FormFieldValue})
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;
                this.setState({"FormData": FormData});
                break;
        };

    };


    render() {

        switch (this.state.renderState){
            case "invalid":
            case "failed":
            case "denied":
                let Message = "An error has occured";
                if (this.state.errorMessage){
                    Message = this.state.errorMessage;
                }
                return <PD.ErrorBox>{Message}</PD.ErrorBox>
                break;
            case "loading":
                return <Buffering/>
                break;
            case "cancelled":
                return <p>Cancelled</p>
                break;
            case "loaded":

                return(
                    <>                    

                        {this.state.selectPackage && this.state.isLoadedPackages &&
                            <PD.Select label='Select a Package' name='idPackage' onChange={this.handleChange} value={this.state.idPackage}>
                                {
                                    this.state.Packages.map(
                                        (Package, pos) => 
                                            {
                                                return(
                                                    <option key={pos} value={Package._id}>{Package.name}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>
                        }


                        {this.state.isLoadedUseCases &&

                            <PD.Select label={this.state.label} name={this.state.name} onChange={this.state.onChange} onBlur={this.state.onBlur} value={this.state.idSelected}>
                                {
                                    this.state.UseCases.map(
                                        (UseCase, pos) => 
                                            {
                                                return(
                                                    <option key={pos} value={UseCase._id}>{UseCase.name}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>
                        }

                    </>
                )
        }

    }
}


export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <SelectUseCase {...props} navigation={navigation} location={location} params={params} />;
  }
