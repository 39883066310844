/*
    implements HTML Elements as
    * PD
    * GDS

    version 1 - 24/06/2023
        from V4 of pdStyle from xPD

        12/09/2024 ... allow forceRender on TabPanel
                   ... fix textAlign on TD
*/

import React from "react"
import { NavLink } from "react-router-dom";

import uniqueId from 'lodash.uniqueid';

import * as RT from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { isObject } from "lodash";

import { Editor as SimpleWysiwig, EditorProvider } from 'react-simple-wysiwyg';
import { 
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  HtmlButton,
  Separator,
  Toolbar
} from 'react-simple-wysiwyg';


class Heading extends React.Component {

    constructor(props) {
        super(props);

        this.level = 1;

        if (this.props.hasOwnProperty('level')){
            this.level = parseInt(this.props.level);
        }


    }


    element(){
        switch (window.Preferences.style){
            case 'pd':
            default:
                switch (this.level){
                    case 1:
                        return <h1>{this.props.children}</h1>
                    case 2:
                        return <h2>{this.props.children}</h2>
                    case 3:
                        return <h3>{this.props.children}</h3>
                    case 4:
                        return <h4>{this.props.children}</h4>
                    case 5:
                        return <h5>{this.props.children}</h5>
                    default:
                        return <h1>{this.props.children}</h1>
                }
        }                
    }

    render() {

            return (this.element())
    };    
};

class PdNavLink extends React.Component {

    constructor(props) {
        super(props);

    }


    element(){

        let className = "";

        switch (window.Preferences.style){
            case 'pd':
            default:
                break;
        }                

        return <NavLink className={className} {...this.props}>{this.props.children}</NavLink>

    }

    render() {
            return (this.element())
    };    
};


class P extends React.Component {

    constructor(props) {
        super(props);
    }


    element(){

        let className = "";

        switch (window.Preferences.style){
            case 'pd':
            default:
                break;
        }                

        return <p className={className}>{this.props.children}</p>

    }

    render() {

            return (this.element())
    };    
};



class Input extends React.Component {

    constructor(props) {
        super(props);
        this.id = uniqueId("pdstyle-");
    }


    element(){

        const id = this.id;

        let name = id;
        if (this.props.hasOwnProperty('name')){
            name = this.props.name;
        }

        let type = 'line';
        if (this.props.hasOwnProperty('type')){
            type = this.props.type;
        }


        let onChange = null;
        if (this.props.hasOwnProperty('onChange')){
            onChange = this.props.onChange;
        }

        let onBlur = null;
        if (this.props.hasOwnProperty('onBlur')){
            onBlur = this.props.onBlur;
        }


        let value = '';
        if (this.props.hasOwnProperty('value')){
            value = this.props.value;
        }

        let width = '';
        if (this.props.hasOwnProperty('width')){
            width = this.props.width;
        }

        switch (window.Preferences.style){
            case 'pd':
            default:

                let htmlLabel;
                if (this.props.hasOwnProperty('label')){
                    htmlLabel = <label htmlFor={id}>{this.props.label}</label>
                }

                switch (type){
                    case 'text':
                        return null;
                    case 'password':
                        return (

                            <div className="field-row">
                                <label className="field-legend">{htmlLabel}</label>
                                <input
                                    id={id} 
                                    name={name} 
                                    type="password"
                                    defaultValue={value}

                                    {...(onChange && { onChange:onChange})}

                                    {...(onBlur && { onBlur:onBlur})}
    
                                    {...this.props}                                    
                                />
                            </div>
                        )

                    
                    case 'line':
                    default:
                        return (
                            <div className="field-row">
                                <label className="field-legend">{htmlLabel}</label>
                                <input
                                    className="field"
                                    id={id}
                                    name={name}
                                    type="text"
                                    defaultValue={value}
                                    size={width}

                                    {...(onChange && { onChange:onChange})}

                                    {...(onBlur && { onBlur:onBlur})}
    
                                />
                            </div>
                        )
                }
        }                
    }

    render() {

        return (this.element())
    };    
};


class Select extends React.Component {

    constructor(props) {
        super(props);
        this.id = uniqueId("pdstyle-");
    }


    element(){

        const id = this.id;

        let name = id;
        if (this.props.hasOwnProperty('name')){
            name = this.props.name;
        }

        let onChange = '';
        if (this.props.hasOwnProperty('onChange')){
            onChange = this.props.onChange;
        }

        let value = '';
        if (this.props.hasOwnProperty('value')){
            value = this.props.value;
        }

        switch (window.Preferences.style){
            case 'pd':
            default:
                return (
                    <div className="field-row">
                        <label className="field-legend">{this.props.label}</label>
                        <select className="field" value={value} id={id} name={name} defaultValue={value} onChange={onChange} {...this.props} >
                            <option/>
                            {this.props.children}
                        </select>
                    </div>
                )
        }                
    }

    render() {

        return (this.element())
    };    
};


class Editor extends React.Component {

    constructor(props) {
        super(props);
        this.id = uniqueId("pdstyle-");
    }


    element(){

        const id = this.id;

        let name = id;
        if (this.props.hasOwnProperty('name')){
            name = this.props.name;
        }

        let onChange = null;
        if (this.props.hasOwnProperty('onChange')){
            onChange = this.props.onChange;
        }

        let onBlur = null;
        if (this.props.hasOwnProperty('onBlur')){
            onBlur = this.props.onBlur;
        }


        let value = '';
        if (this.props.hasOwnProperty('value')){
            value = this.props.value;
        }

        let width = '';
        if (this.props.hasOwnProperty('width')){
            width = this.props.width;
        }

        let labelClass = '';
        let controlClass = '';

        switch (window.Preferences.style){
            case 'pd':
            default:
                return (
                    <div className="field-row">
                        <label className="field-legend">{this.props.label}</label>
                        <EditorProvider>
                            <SimpleWysiwig 

                                className = "field"

                                style={{backgroundColor: "white"}}

                                id={id} 
                                name={name} 
                                value={value}

                                {...(onChange && { onChange:onChange})}

                                {...(onBlur && { onBlur:onBlur})}

                                >

                                <Toolbar>
                                    <BtnUndo />
                                    <BtnRedo />
                                    <Separator />

                                    <BtnBold />
                                    <BtnUnderline />
                                    <BtnStyles />

                                    <Separator />

                                    <BtnBulletList />
                                    <BtnNumberedList />

                                    <Separator />

                                    <BtnLink />

                                    <Separator />

                                    <BtnClearFormatting />
                                    <HtmlButton />


                                </Toolbar>
                            </SimpleWysiwig>
                        </EditorProvider>
                    </div>
                )
        }                
    }

    render() {

        return (this.element())
    };    
};


class Radio extends React.Component {

    constructor(props) {
        super(props);
        this.id = uniqueId("pdstyle-");
    }


    element(){

        const id = this.id;

        let name = id;
        if (this.props.hasOwnProperty('name')){
            name = this.props.name;
        }

        let onChange = '';
        if (this.props.hasOwnProperty('onChange')){
            onChange = this.props.onChange;
        }

        let value = '';
        if (this.props.hasOwnProperty('value')){
            value = this.props.value;
        }

        switch (window.Preferences.style){
            case 'pd':
            default:
                    let htmlLabel;
                    if (this.props.hasOwnProperty('label')){
                        htmlLabel = <label className="govuk-fieldset__legend">{this.props.label}</label>
                    }

                    let children = this.props.children;
                    if (!Array.isArray(children)){
                        if (children.props.children){
                            children = children.props.children;
                        }
                    }


                    return (
                        <div>
                            <fieldset>
                                {htmlLabel}
                                <div>
                                    {React.Children.map(children, child => {
                                        if (child){
                                            if (child.type === 'label'){
                                                let label = child.props.children;
                                                return(
                                                    <div>
                                                        <input name={name} type="radio" value={label} onChange={onChange} checked={value == label} />
                                                        <label>{label}</label>
                                                    </div>
                                                );
                                            };
                                        };
                                        return null;
                                    }
                                    )}
                                </div>
                            </fieldset>
                        </div>
                    )
        }                
    }

    render() {

        return (this.element())
    };    
};





class Checkbox extends React.Component {

    constructor(props) {
        super(props);
        this.id = uniqueId("pdstyle-");
    }


    element(){

        const id = this.id;

        let name = id;
        if (this.props.hasOwnProperty('name')){
            name = this.props.name;
        }

        let label = '';
        if (this.props.hasOwnProperty('label')){
            label = this.props.label;
        }


        let onChange = '';
        if (this.props.hasOwnProperty('onChange')){
            onChange = this.props.onChange;
        }

        let value = '';
        if (this.props.hasOwnProperty('value')){
            value = this.props.value;
        }

        switch (window.Preferences.style){
            case 'pd':
            default:
                return (
                    <div className="field-row">
                        <label  className="field-legend" htmlFor={id}>
                            {label}
                        </label>
                        <input id={id} name={name} type="checkbox" className="field" onChange={onChange} checked={value}  {...this.props}/>
                    </div>
                )
        }                
    }

    render() {

        return (this.element())
    };    
};


class Button extends React.Component {

    constructor(props) {
        super(props);
        this.id = uniqueId("pdstyle-");
    }


    element(){

        const id = this.id;

        let name = id;
        if (this.props.hasOwnProperty('name')){
            name = this.props.name;
        }

        let type = 'submit';
        if (this.props.hasOwnProperty('type')){
            type = this.props.type;
        }


        let onClick = null;
        if (this.props.hasOwnProperty('onClick')){
            onClick = this.props.onClick;
        }

        let value = '';
        if (this.props.hasOwnProperty('value')){
            value = this.props.value;
        }

        let className = "button-submit";
        if (type === "cancel"){
            className += " button-cancel";
        }
        if (type === "warning"){
            className += " button-warning";
        }
        switch (window.Preferences.style){
            case 'pd':
            default:
                return (
                        <button
                            {...this.props}
                            className={className}
                            style={{marginRight: "5px", marginBottom: "5px"}}
                            type="submit"
                            id={id}
                            name={name}
                            value={value}
                            defaultValue={value}

                            {...(onClick && { onClick:onClick})}
                            
                            >


                            {value}
                            {this.props.children}
                        </button>
                )
        }                
    }

    render() {

        return (this.element())
    };    
};




class Table extends React.Component {

    constructor(props) {
        super(props);

        this.type = null;

        if (this.props.hasOwnProperty('type')){
            this.type = parseInt(this.props.type);
        }

    }


    element(){
        let className = "";

        switch (window.Preferences.style){
            case 'pd':
            default:
                switch (this.type){
                    case 'list':
                        className = "list";
                        break;
                    case 'panel':
                        className = "panel";
                        break;
                    default:
                        break;
    
                }
                return <table className={className} {...this.props}>{this.props.children}</table>
        }                
    }

    render() {

            return (this.element())
    };    
};


class Tbody extends React.Component {

    constructor(props) {
        super(props);

    }


    element(){

        switch (window.Preferences.style){
            case 'pd':
            default:
                return <tbody>{this.props.children}</tbody>
        }                
    }

    render() {

            return (this.element())
    };    
};


class Td extends React.Component {

    constructor(props) {
        super(props);

    }


    element(){
        let contentType = 'text';
        if (this.props.hasOwnProperty('contentType')){
            contentType = this.props.contentType;
        }

        switch (window.Preferences.style){
            case 'pd':
            default:
                let style = {};

                if (this.props.style){
                    style=this.props.style;
                }
                else{
                    switch (contentType){
                        case "numeric":
    //                        style = "text-align:right";
                            style.textAlign = 'right';
                            break;
                        default:
                            break;
                    }
                }
                return <td  {...this.props} style={style}>{this.props.children}</td>
        }                
    }

    render() {
            return (this.element())
    };    
};



class Th extends React.Component {

    constructor(props) {
        super(props);

    }


    element(){

        switch (window.Preferences.style){
            case 'pd':
            default:
                return <th {...this.props}>{this.props.children}</th>
        }                
    }

    render() {
            return (this.element())
    };    
};

class Thead extends React.Component {

    constructor(props) {
        super(props);

    }


    element(){

        switch (window.Preferences.style){
            case 'pd':
            default:
                return <thead {...this.props}>{this.props.children}</thead>
        }                
    }

    render() {
            return (this.element())
    };    
};


class Tr extends React.Component {

    constructor(props) {
        super(props);

    }


    element(){

        switch (window.Preferences.style){
            case 'pd':
            default:
                return <tr {...this.props}>{this.props.children}</tr>
        }                
    }

    render() {
            return (this.element())
    };    
};



class PdTab extends React.Component {

    constructor(props) {
        super(props);

        this.id = uniqueId("pdstyle-");

        this.state = { tabIndex: 0 };
        this.setTabIndex = this.setTabIndex.bind(this);
{//        this.handleClick = this.handleClick.bind(this);
}
      }
    
      setTabIndex(newTabIndex) {
        this.setState({
          tabIndex: newTabIndex,
        });
      }
    

    element(){

        switch (window.Preferences.style){
            case 'pd':
            default:
                return(
                    <RT.Tabs>
                        <RT.TabList>
                            {React.Children.map(this.props.children, child => {
                                if (child){
                                    if (child.type === 'div'){
                                        let label = '';
                                        if (child.props.hasOwnProperty("label")){
                                            label = child.props.label;
                                        }
                                        return(
                                            <RT.Tab>
                                                {label}
                                            </RT.Tab>
                                        );
                                    };
                                };
                                return null;
                            }
                            )}
                        </RT.TabList>

                        {React.Children.map(this.props.children, child => {
                            if (child){
                                if (child.type === 'div'){
// props can be forceRender
                                    return(
                                        <RT.TabPanel {...child.props}>{child.props.children}</RT.TabPanel>
                                    );
                                };
                            };
                            return null;
                        }
                        )}

                    </RT.Tabs>
                )
        }                

    }

    render() {
        return (this.element());
    };    
};


class ErrorBox extends React.Component {

    constructor(props) {
        super(props);

    }


    element(){

        let className = "";

        switch (window.Preferences.style){
            case 'pd':
            default:
                return (
                    <div className="errorbox" >
                        <h2>
                            {this.props.children}
                        </h2>
                    </div>
                );
        }                

        return 
        
    }

    render() {

            return (this.element())
    };    
};


class Breadcrumb extends React.Component {

    constructor(props) {
        super(props);
    }

    element(){

        switch (window.Preferences.style){
            case 'pd':
            default:
                return (
                    <div/>
                );
        }                

    }

    render() {
            return (this.element())
    };    
};


export default Heading;
export {Heading,PdNavLink,P,Input,Select,Radio, Checkbox, Button,Table,Tbody,Td,Th,Thead,Tr, PdTab as Tabs,ErrorBox,Breadcrumb, Editor};