import React from 'react';

import { userService as userService } from '../_services/user.service.js';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import * as PD from '../../classes/pdStyle';

import { DesignerLayoutContext } from '../../contexts/DesignerLayoutContext';



class LoginPage extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);

        userService.logout();
/*
        if (window.TopBanner){
            window.TopBanner.setState({
                loggedon:false
            });
        }
*/
        this.state = {
            username: '',
            password: '',
            submitted: false,
            loading: false,
            error: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    componentDidMount(){

        if (this.context.hasLayout){
            this.context.Layout.setUser(null);
        }

    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(e) {
        e.preventDefault();


        switch (e.target.name){
            case 'btnLogin':
                this.setState({ submitted: true });
                const { username, password, returnUrl } = this.state;

                // stop here if form is invalid
                if (!(username && password)) {
                    return;
                }

                this.setState({ loading: true });
                userService.login(username, password)
                    .then(
                        user => {
                            const { from } = this.props.location.state || { from: { pathname: "/" } };
                            this.props.navigation(from);

                            if (this.context.hasLayout){
                                this.context.Layout.setUser(user);
                            }
/*
                            if (window.TopBanner){
                                window.TopBanner.setState({
                                    loggedon:true
                                });
                            }      
*/      
                        },
                        error => this.setState({ error, loading: false })
                    );
                break;
            case 'btnCancel':
                this.props.navigation("/");
                break;
        }
    }

    render() {
        const { username, password, submitted, loading, error } = this.state;
        return (
            
            <div>

                <PD.Heading>DSD</PD.Heading>

                <PD.Heading level="2">Login</PD.Heading>

                <PD.P>To use DSD, you will need to be registered and be given a username and password.</PD.P>

                <form>

                    <PD.Input label="Username" width="30" name="username" onChange={this.handleChange}/> 

                    {submitted && !username &&
                        <PD.ErrorBox>
                            Username is required
                        </PD.ErrorBox>
                    }

                    <PD.Input label="Password" type="password" width="30" name="password" onChange={this.handleChange}/>
                    {submitted && !password &&
                        <PD.ErrorBox>
                            Password is required
                        </PD.ErrorBox>
                    }

                    <PD.Button type="submit" onClick={this.handleSubmit} name="btnLogin" value="Login" disabled={loading}/>    
                    <PD.Button type="cancel" onClick={this.handleSubmit} name="btnCancel" value="Cancel" />    

                    {loading &&
                        <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    {error &&
                        <PD.ErrorBox>
                            <div>{error}</div>
                        </PD.ErrorBox>
                    }
                </form>
            </div>
        );
    }
}

export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <LoginPage {...props} navigation={navigation} location={location} params={params} />;
  }