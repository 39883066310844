import React from "react"

import { isNull, isEqual, isArray } from "lodash";

import {hasProperty, formatText} from '../utils/utils';

import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';
import {SanitizeHTML} from '../utils/utils';



class ViewArtefact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.data = {};
        this.state.Artefact = null;


        this.filters = {};

        this.columns = {
        };

        this.data.id = null;
        if (this.props.id){
            this.data.id = this.props.id; 
        }


    }

    componentDidMount(){

        this.setup();
 
     }

     componentDidUpdate(prevProps, prevState){
 
        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        if (!isEqual(this.state, prevState)){

           let StateChange = {};

           if (this.state.Artefact){
               StateChange.isLoaded = true;
           }

           this.setState(StateChange);

        }

       return;

   };


   setup(){

        let StateChange = {
            isLoaded: false,
            reload: false,

            Artefact: null,
            Resources: null

        }


        if (this.props.Artefact){
            StateChange.Artefact = this.props.Artefact;            
        }

        if (this.props.Resources){
            StateChange.Resources = this.props.Resources; 
        }


        this.setState(StateChange);

    }

//    componentDidMount() {

//        if (this.data.Artefact){
//            this.setState({"isLoaded":true});
//            return;
//        }

//    };




    view(json){

        if (json){

            let modelArtefact = null;
            if ("type" in json){
                if (json.type in this.dsd.model.artefacts){
                    modelArtefact = this.dsd.model.artefacts[json.type];
                }
            }


            return(

                <div>
                    <div style={{marginBottom:"50px"}}>
                        <PD.Table className="panel">
                            <PD.Tbody>                                

                                {hasProperty(json,'name') &&
                                    <PD.Tr>                                
                                        <PD.Th>Name</PD.Th><PD.Td><PD.PdNavLink to={"/artefact/" + json.id} > {json.name}</PD.PdNavLink></PD.Td>
                                    </PD.Tr>
                                }
                                
                                {hasProperty(json,'description') &&
                                    <PD.Tr>
                                        <PD.Th>Description</PD.Th><PD.Td><SanitizeHTML html={json.description}/></PD.Td>
                                    </PD.Tr>
                                }

                                {hasProperty(json,'proposals') &&
                                    <PD.Tr>
                                        <PD.Th>Proposals</PD.Th><PD.Td><SanitizeHTML html={json.proposals}/></PD.Td>
                                    </PD.Tr>
                                }


                                {modelArtefact &&
                                    <PD.Tr>
                                        <PD.Th>Type</PD.Th><PD.Td>{modelArtefact.label}</PD.Td>
                                    </PD.Tr>
                                }

                                {hasProperty(json,'status') &&
                                    <PD.Tr>                                
                                        <PD.Th>Status</PD.Th><PD.Td>{json.status}</PD.Td>
                                    </PD.Tr>
                                }

                            </PD.Tbody>
                        </PD.Table>
                    </div>

                    {this.viewResources(json)}

                </div>
            )
        };
        return null;
    };


    viewResources(json){

        if ("Resources" in json){
            if (isArray(json.Resources)){
                if (json.Resources.length > 0){
                    return(
                        <div style={{marginBottom:"50px"}}>
                            <PD.Heading level="3">Resources</PD.Heading>
                            <PD.Table type="list">
                                <PD.Tr>
                                    <PD.Th>Resource</PD.Th>
                                    <PD.Th>Type</PD.Th>
                                    <PD.Th>Relevance</PD.Th>
                                </PD.Tr>

                                {
                                    json.Resources.map(
                                        (itemResource, posItemResource) => 
                                            {
                                                return(
                                                    this.rowResource(itemResource, posItemResource)
                                                )
                                            }
                                    )
                                }
                            </PD.Table>
                        </div>
                    )
                }
            }
        }
        return null;
    }

    rowResource(itemResource, key){

        let Resource = null;
        if (itemResource.Resource){
            Resource = itemResource.Resource;

            return(
                <PD.Tr key={key}>
                    <PD.Td>
                        {Resource &&
                            <PD.PdNavLink to={"/resource/" + Resource.id} > {Resource.name}</PD.PdNavLink>}
                    </PD.Td>
                    <PD.Td>
                        {Resource &&
                            Resource.type}
                    </PD.Td>
                    <PD.Td>
                        {itemResource &&
                            itemResource.relevance}
                    </PD.Td>

                </PD.Tr>
            )
        }
        return null;

    }


    render() {

        if (this.state.isLoaded) {

            return (
                <div>
                    {this.view(this.state.Artefact)}
                </div>
            )
        }
        else {
            return <div />;
        }

    };    
};
        
export default ViewArtefact;