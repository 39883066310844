import React from "react"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { isNull, isEqual, isArray } from "lodash";

import sanitizeHtml from 'sanitize-html';

import 'react-tabs/style/react-tabs.css';


import * as PD from '../classes/pdStyle';
import {HtmlToText, getParam} from '../utils/utils';
import {formatText, truncate} from '../utils/utils';


import dsd from "../classes/clsDSD";

import { Graphviz } from "graphviz-react";
import ViewNarrative from "../components/ViewNarrative";
import ViewMetadata from "../components/ViewMetadata";

import RowConcept from "../components/RowConcept";
import RowLink from "../components/RowLink";

import RowKey from "../components/RowKey";

import SelectConceptModel from "../components/SelectConceptModel";


import { DesignerLayoutContext } from '../contexts/DesignerLayoutContext';

import Buffering from "../components/Buffering";

import { json2csv } from 'json-2-csv';

import { Check, CheckSquare } from 'react-bootstrap-icons';

import JSZip from "jszip";


class Narrative extends React.Component {

    static contextType = DesignerLayoutContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: 'view',
            action: null,
            isLoaded: false,
            loadData:false
        };


        this.data = {};
        this.viz = null;
        this.vizEngine = "dot";

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleMode = this.handleMode.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleVizClick = this.handleVizClick.bind(this);

        this.handleExport = this.handleExport.bind(this);


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.FallBackNavigation = `/narratives`;


    }

    componentDidMount(){

       this.setup();

    }


    componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

        let StateChange = {};

        if (!isEqual(this.state, prevState)){

            if (this.state.vizNodeId){
                let partsVizNodeId = this.state.vizNodeId.split('_');
                switch(partsVizNodeId[0]){
                    case 'concept':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/concept/${partsVizNodeId[1]}`);
                        }
                        break;
                    case 'entity':
                        if (partsVizNodeId.length > 3){
                            switch(partsVizNodeId[2]){
                                case 'structure':
                                    this.props.navigation(`/structure/${partsVizNodeId[3]}`);
                                    break;
                            }
                        }else{
                            if (partsVizNodeId.length > 1){
                                this.props.navigation(`/entity/${partsVizNodeId[1]}`);
                            }
                        }
                        break;
                    case 'structure':
                        if (partsVizNodeId.length > 1){
                            this.props.navigation(`/structure/${partsVizNodeId[1]}`);
                        }
                        break;
        
                }

            }


            if (this.state.mode === 'new'){
                StateChange.isLoaded = true;
            }

            if (this.state.loadData){
                this.loadData(this.state.idNarrative);
                StateChange.updateToggle = Number(!this.state.updateToggle)
                StateChange.loadData = false;
            }

            if (this.state.isUpdated){
                StateChange.isUpdated = false;
                StateChange.mode = 'view';
                StateChange.action = null;
                StateChange.loadData = true;
            }
            else{
                if (this.state.idNarrative != prevState.idNarrative){
                    if (this.state.idNarrative){
                        this.onUpdateNavigation = `/narrative/${this.state.idNarrative}`;
                        StateChange.loadData = true;
                    }
                }
            }

            switch (this.state.action){
                case "GenerateLinks":
                    if (this.state.ConceptModelLinks){
                        this.GenerateLinks();
                    }
                    break;
            }


            if (this.state.idNarrative){
                for (var i in window.Breadcrumb){
                    if (window.Breadcrumb[i][0] === 'narrative'){
                        window.Breadcrumb = window.Breadcrumb.slice(0,i);
                        break;
                    }
                }
                this.Breadcrumb = JSON.parse(JSON.stringify(window.Breadcrumb));
    
                window.Breadcrumb.push(['narrative',`/narrative/${this.state.idNarrative}`]);

                this.FallBackNavigation = `../narratives`;
                this.onUpdateNavigation = this.FallBackNavigation;
    
            };

            this.setState(StateChange);

        }

    }
    
    loadData(id){

        if (id !== undefined){
            this.dsd.getNarrative(id);
        }
        
        return;
        
    };

    loadConceptModelLinks(idConceptModel){

        if (idConceptModel !== undefined){
            this.setState({"ConceptModelLinks":null})
            this.dsd.listLinks({idConceptModel:idConceptModel},"ConceptModelLinks","isLoadedConceptModelLinks")
        }
        
        return;
        
    };


    GenerateLinks(){

        let FormData = this.state.FormData;
        if (!('Links' in FormData)){
            FormData['Links'] = [];
        }
        let arrNew = Array.from(FormData.Links);


        for (let posL=0; posL < this.state.ConceptModelLinks.length; posL++){
            let Link = this.state.ConceptModelLinks[posL];

            let boolFound = false;
            for (let pos=0;pos<arrNew.length;pos++){
                let item = arrNew[pos];
                if (item.idLink == Link._id){
                    boolFound = true;
                }
            }
            if (!boolFound){
// check that both from and to concepts are in the Narrative

                let boolFoundFrom = false;
                let boolFoundTo = false;

                for (let posC=0;posC<FormData.Concepts.length;posC++){
                    if (FormData.Concepts[posC].idConcept == Link.idConceptFrom){
                        boolFoundFrom = true;
                    }
                    if (FormData.Concepts[posC].idConcept == Link.idConceptTo){
                        boolFoundTo = true;
                    }
                }
                if (boolFoundFrom && boolFoundTo){
                    arrNew.push({idLink:Link._id});
                }
            }
        }

        FormData.Links = arrNew;
        FormData.idLinkConceptModel = null;
        this.setState(
            {
                FormData: FormData,
                action: null,
            }
        );

    }

    setup(){


        let StateChange = {
            id: null,
            isLoaded: false,
            isUpdated: false,
            isFailed: false,
            loadData: false,

            FormData: {},
            Controls: {
                vizFullSize :true,
            },

            countConcepts : null,

            Narrative: null,

            updateToggle: 0,

            vizNodeId: null,

            csv: null

        }

        StateChange.mode = getParam('mode', this.props);
        StateChange.idNarrative = getParam('id', this.props);
        if (StateChange.idNarrative){
            StateChange.loadData = true;
        }

        if (StateChange.mode == null){
            StateChange.mode = 'view';
        }

        this.setState(StateChange);
        
    }

    handleChange(event) {

        const FormFieldName = event.target.name;
        let FormFieldValue = event.target.value;

        if (event.target.type == "checkbox"){
            FormFieldValue = event.target.checked;
        }

        let Controls = this.state.Controls;
        switch (FormFieldName){
            case 'vizFullSize':
                Controls[FormFieldName] = FormFieldValue;        
                this.setState({"Controls": Controls});
                break;
            default:
                let FormData = this.state.FormData;
                FormData[FormFieldName] = FormFieldValue;        
                this.setState({"FormData": FormData});
                break;                
        }
        return;

    };
    
    handleSubmit(event) {

        switch (event.target.name){
            case 'btnCancel':
                switch (this.state.mode){
                    case 'new':
                        this.props.navigation(this.FallBackNavigation);
                        break;
                    case 'edit':
                        this.loadData(this.state.idNarrative);
                        this.setState({mode: 'view'});
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };

                break;
            case 'btnSave':
            default:

                this.setState({
                    isUpdated: false,
                    isFailed: false
                })
        

                if ('description' in this.state.FormData){
                    this.state.FormData.description = sanitizeHtml(this.state.FormData.description);
                }
                if ('proposals' in this.state.FormData){
                    this.state.FormData.proposals = sanitizeHtml(this.state.FormData.proposals);
                }

                switch (this.state.mode){
                    case 'new':
                        this.dsd.addNarrative(this.state.FormData);
                        break;
                    case 'edit':
                        this.dsd.editNarrative(this.state.idNarrative,this.state.FormData);
                        break;
        //            case 'remove':
        //                this.savviconfig.removeFactor(this.data.idProject,this.type,this.data.idFactor);
        //                break;  
                    default:
                        break; 
                };
    
        };

        event.preventDefault();
    }

    handleMode(event) {
        this.setState({mode: event.target.value});
        event.preventDefault();
    }


    handleButton(event) {

        let StateChange = {};

        switch (event.target.name){
            case 'btnGenerateLinks':
                this.loadConceptModelLinks(this.state.FormData.idLinkConceptModel);
                StateChange.action = "GenerateLinks";
                break;
        }

        this.setState(StateChange);

        event.preventDefault();
    };


    async handleExport(event){


        const zip = new JSZip();

        const Narrative = JSON.stringify(this.state.Narrative, null, 4);
        zip.file(`narrative_${this.state.idNarrative}.json`, Narrative);

        zip.file(`narrative_${this.state.idNarrative}.dot`,this.viz.dot);

        const svg = document.getElementsByName("divViz")[0].innerHTML;
        zip.file(`narrative_${this.state.idNarrative}.svg`,svg);

        const zipData = await zip.generateAsync({
            type: "blob",
            streamFiles: true,
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(zipData);
        link.download = `narrative_${this.state.idNarrative}.zip`;
        link.click();


    }


    addConcept(){
        let FormData = this.state.FormData;

        if (!('Concepts' in FormData)){
            FormData['Concepts'] = [];
        }
        if (!FormData.Concepts){
            FormData['Concepts'] = [];
        }

        FormData.Concepts.push({});
        this.setState({"FormData": FormData});
    }

    removeConcept(seq){

        let FormData = this.state.FormData;

        if (!('Concepts' in FormData)){
            FormData['Concepts'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Concepts.length;pos++){
            let item = FormData.Concepts[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Concepts = arrNew;
        this.setState({"FormData": FormData});

    }


    updateConcept(seq, Concept){

        let FormData = this.state.FormData;

        if (!('Concepts' in FormData)){
            FormData['Concepts'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Concepts.length;pos++){
            let item = FormData.Concepts[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Concept;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Concept);
        }

        FormData.Concepts = arrNew;
        this.setState({"FormData": FormData});

    }


    addLink(){
        let FormData = this.state.FormData;

        if (!('Links' in FormData)){
            FormData['Links'] = [];
        }
        if (!FormData.Links){
            FormData['Links'] = [];
        }

        FormData.Links.push({});
        this.setState({"FormData": FormData});
    }

    removeLink(seq){

        let FormData = this.state.FormData;

        if (!('Links' in FormData)){
            FormData['Links'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Links.length;pos++){
            let item = FormData.Links[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Links = arrNew;
        this.setState({"FormData": FormData});

    }


    updateLink(seq, Link){

        let FormData = this.state.FormData;

        if (!('Links' in FormData)){
            FormData['Links'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Links.length;pos++){
            let item = FormData.Links[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Link;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Link);
        }

        FormData.Links = arrNew;
        this.setState({"FormData": FormData});

    }


    addKey(){
        let FormData = this.state.FormData;

        if (!('Keys' in FormData)){
            FormData['Keys'] = [];
        }
        if (!FormData.Keys){
            FormData['Keys'] = [];
        }

        FormData.Keys.push({});
        this.setState({"FormData": FormData});
    }

    removeKey(seq){

        let FormData = this.state.FormData;

        if (!('Keys' in FormData)){
            FormData['Keys'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Keys.length;pos++){
            let item = FormData.Keys[pos];
            if (pos == (seq-1)){
                boolFound = true;
            } else{
                arrNew.push(item); 
            }
        }

        FormData.Keys = arrNew;
        this.setState({"FormData": FormData});

    }


    updateKey(seq, Key){

        let FormData = this.state.FormData;

        if (!('Keys' in FormData)){
            FormData['Keys'] = [];
        }
        let arrNew = []
        let boolFound = false;
        for (let pos=0;pos<FormData.Keys.length;pos++){
            let item = FormData.Keys[pos];
            if (pos == (seq-1)){
                boolFound = true;
                item = Key;
            }
            arrNew.push(item); 
        }
        if (!boolFound){
            arrNew.push(Key);
        }

        FormData.Keys = arrNew;
        this.setState({"FormData": FormData});

    }



    handleVizClick(event) {

        let StateChange = {};

        let idNode = null;
        if ("href" in event.target){
            idNode = event.target.href.baseVal;
        }
        else{
            if ("parentElement" in event.target){
                if ("href" in event.target.parentElement){
                    idNode = event.target.parentElement.href.baseVal;
                }        
            }
        }
        

        StateChange.vizNodeId = idNode;

        this.setState(StateChange);

        event.preventDefault();

    };



    visualise(){
    
        if (!this.state.Narrative){
            return <Buffering/>;
        }


        const styles = {
            scrollableDiv: {
                    backgroundColor: "#f1f1f1",
                    overflowX: "scroll",
                    whiteSpace: "nowrap",
                    height: "800px",
                    width: "800px",
                    overflow: "auto",
                    margin: "20px",
                    textAlign: "justify",
                    padding: "20px"
            }
          };


        let vizoptions = {}
        vizoptions.engine = this.vizEngine;
        vizoptions.fit = false;
        vizoptions.width = null;
        vizoptions.height = null;
        vizoptions.zoom = false;
        vizoptions.scale = 1;

        let styleDiv = styles.scrollableDiv;
        if (this.state.Controls.hasOwnProperty('vizFullSize')){
            if (this.state.Controls.vizFullSize){
                styleDiv = null;
            }
        }

        try {

            return(
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <PD.Checkbox label="Full Screen" name="vizFullSize" value={this.state.Controls.vizFullSize} onChange={this.handleChange}/>                    
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div name="divViz" style={styleDiv} onClick={this.handleVizClick}>
                        <Graphviz dot={this.viz.dot} options={vizoptions}/>
                    </div>

                </div>
            );
          } catch (error) {
            return <div/>
          }
    };


    setLayoutContent(){

        if (this.context.hasLayout){
            
            let Content = {Index:{}};

            if (this.state.Narrative){
                Content.Index.title = this.state.Narrative.name;
                Content.Index.Sections = [];
            }

            this.context.Layout.setLayoutContent(Content)

            localStorage.setItem("dsdContent",JSON.stringify(Content));
            

        }

        return;

    }


    render() {


        if (this.state.isUpdated){
            switch (this.state.mode){
                case 'remove':
                    this.props.navigation(this.FallBackNavigation);
                    return null;
                default:
                    break;
            };
        };

        if (this.state.isLoaded) {

            this.setLayoutContent();

            let boolOfferEdit = false;

            if (this.state.Narrative){
                this.state.FormData = this.state.Narrative;
                if (this.dsd.loggedon){
                    if (this.dsd.user.id == this.state.Narrative.idUser){
                        boolOfferEdit = true;
                    }
                }        
            }

            switch (this.state.mode){
                case 'new':
                case 'edit':

                    return (
                    <div>

                        <PD.Heading>{this.state.mode} Narrative</PD.Heading>
    
                        {this.state.isFailed &&
                            <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                        }
    
                        <form> 
                            <PD.Input label="Name" width="60" name="name" value={this.state.FormData.name} onChange={this.handleChange}/>

                            <PD.Editor label='Description' name="description" value={this.state.FormData.description} onChange={this.handleChange}/>

                            <PD.Editor label='Proposals' name="proposals" value={this.state.FormData.proposals} onChange={this.handleChange}/>

                            {this.FormKeys()}
                            {this.FormConcepts()}
                            {this.FormLinks()}

                            <PD.Select label="Status" name='status'  onChange={this.handleChange} value={this.state.FormData.status}>
                                {
                                    this.dsd.config.ComponentStatuses.map(
                                        (status, posStatus) => 
                                            {
                                                return(
                                                    <option key={posStatus} value={status}>{status}</option>
                                                )
                                            }
                                    )
                                }
                            </PD.Select>

                            <PD.Button type="submit" onClick={this.handleSubmit} name="btnSave"value="Save" />    
                            <PD.Button type="warning" onClick={this.handleSubmit} name="btnCancel" value="Cancel" />    
                        </form>
    
                    </div>    
                    );
                case 'remove':
                    return (
                        <div>
                            <PD.Heading>{this.state.mode} Narrative</PD.Heading>

                            {this.state.isFailed &&
                                <PD.ErrorBox>ERROR - Failed to Update</PD.ErrorBox>
                            }

                            <PD.Button onClick={this.handleSubmit} type="warning" value="confirm remove?" />
                            <ViewNarrative json={this.state.Narrative}/>
                        </div>
                    )
                case 'view':
                default:

                    if (!this.state.Narrative){
                        return <div/>
                    }
                    
//                    const countItems = this.state.countItems == null ? "Loading..." : this.state.countItems;

                    this.viz = this.dsd.vizNarrative({Narrative:this.state.Narrative}, this.state.Controls, this.vizEngine );

                    return (
                        <div>
                            <div>
                                <PD.Breadcrumb crumbs={this.Breadcrumb}/>
                                <PD.Heading>Narrative:{this.state.Narrative.name}</PD.Heading>
                            </div>

                            {boolOfferEdit &&
                                <span style={{paddingRight:10}}>
                                        <PD.Button onClick={this.handleMode} type="submit" value="edit" />                        
                                </span>
                            }

                            <PD.Button onClick={this.handleExport} name="btnExport" value="export" />                        


                            <ViewNarrative Narrative={this.state.Narrative}/>

                            <br/>

                            <PD.Tabs>

                                <div label='Visualise'>
                                    <table>
                                        <tr>
                                            <td style={{verticalAlign: 'top', width: 'auto'}}>
                                                {this.visualise()}
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <div label='Keys'>

                                    {isArray(this.state.Narrative.Keys) &&
                                        <PD.Table className="list">
                                            <PD.Thead>
                                                <PD.Tr>
                                                    <PD.Th>Legend</PD.Th>
                                                    <PD.Th>Color</PD.Th>
                                                </PD.Tr>
                                            </PD.Thead>

                                            <PD.Tbody>
                                                {
                                                    this.state.Narrative.Keys.map(
                                                        (Key, pos) => 
                                                        {
                                                            return (
                                                                <PD.Tr key={pos}>
                                                                    <PD.Td>
                                                                        {Key.legend}
                                                                    </PD.Td>
                                                                    <PD.Td>
                                                                        {Key.color}
                                                                    </PD.Td>
                                                               </PD.Tr>

                                                            )
                                                        }
                                                    )
                                                }
                                            </PD.Tbody>
                                        </PD.Table>
                                    }
                                </div>

                                <div label='Concepts'>

                                    {isArray(this.state.Narrative.Concepts) &&
                                        <PD.Table className="list">
                                            <PD.Thead>
                                                <PD.Tr>
                                                    <PD.Th>Name</PD.Th>
                                                    <PD.Th>Concept Model</PD.Th>
                                                    <PD.Th>Description</PD.Th>
                                                    <PD.Th>Key</PD.Th>
                                                </PD.Tr>
                                            </PD.Thead>

                                            <PD.Tbody>
                                                {
                                                    this.state.Narrative.Concepts.map(
                                                        (NarrativeConcept, pos) => 
                                                        {

                                                            if (NarrativeConcept.Concept){

                                                                let Key = null;
                                                                if (this.state.Narrative.Keys){
                                                                    if (NarrativeConcept.seqKey){
                                                                        if (NarrativeConcept.seqKey <= this.state.Narrative.Keys.length){
                                                                            Key = this.state.Narrative.Keys[NarrativeConcept.seqKey-1]
                                                                        }
                                                                    }
                                                                }                                                                                                        

                                                                return (
                                                                    <PD.Tr key={pos}>
                                                                        <PD.Td>
                                                                            <PD.PdNavLink to={"/concept/" + NarrativeConcept.Concept._id} > {NarrativeConcept.Concept.name}</PD.PdNavLink>
                                                                        </PD.Td>

                                                                        <PD.Td>
                                                                            {(() => {
                                                                                if (NarrativeConcept.Concept.ConceptModel){
                                                                                    return NarrativeConcept.Concept.ConceptModel.name;
                                                                                }
                                                                                return null;
                                                                            })()}
                                                                    </PD.Td>
                                                                        
                                                                        <PD.Td>
                                                                            {formatText(truncate(HtmlToText(NarrativeConcept.Concept.description), 400))}
                                                                        </PD.Td>

                                                                        <PD.Td>
                                                                            {Key != null &&
                                                                                Key.legend
                                                                                }
                                                                        </PD.Td>


                                                                    </PD.Tr>

                                                                )
                                                            }
                                                            return null;
                                                        }
                                                    )
                                                }
                                            </PD.Tbody>
                                        </PD.Table>
                                    }

                                </div>


                                <div label='Links'>

                                    {isArray(this.state.Narrative.Links) &&
                                        <PD.Table className="list">
                                            <PD.Thead>
                                                <PD.Tr>
                                                    <PD.Th>Name</PD.Th>
                                                    <PD.Th>Concept Model</PD.Th>
                                                    <PD.Th>Description</PD.Th>
                                                    <PD.Th>Inverse?</PD.Th>
                                                </PD.Tr>
                                            </PD.Thead>

                                            <PD.Tbody>
                                                {
                                                    this.state.Narrative.Links.map(
                                                        (NarrativeLink, pos) => 
                                                        {

                                                            if (NarrativeLink.Link){

                                                                return (
                                                                    <PD.Tr key={pos}>
                                                                        <PD.Td>
                                                                            <PD.PdNavLink to={"/link/" + NarrativeLink.Link._id} > {NarrativeLink.Link.name}</PD.PdNavLink>
                                                                        </PD.Td>

                                                                        <PD.Td>
                                                                            {(() => {
                                                                                if (NarrativeLink.Link.ConceptModel){
                                                                                    return NarrativeLink.Link.ConceptModel.name;
                                                                                }
                                                                                return null;
                                                                            })()}
                                                                        </PD.Td>
                                                                        
                                                                        <PD.Td>
                                                                            {formatText(truncate(HtmlToText(NarrativeLink.Link.description), 400))}
                                                                        </PD.Td>

                                                                        <PD.Td>
                                                                            {NarrativeLink.inverse &&
                                                                                <CheckSquare/>
                                                                            }
                                                                        </PD.Td>

                                                                    </PD.Tr>

                                                                )
                                                            }
                                                            return null;
                                                        }
                                                    )
                                                }
                                            </PD.Tbody>
                                        </PD.Table>
                                    }

                                </div>

                                <div label='Developer'>
                                    <table>
                                        {this.viz &&
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>dot</th>
                                                <td><pre>{this.viz.dot}</pre></td>
                                            </tr>
                                        }
                                            <tr>
                                                <th style={{verticalAlign:"top"}}>csv</th>
                                                <td><pre>{this.state.csv}</pre></td>
                                            </tr>

                                    </table>

                                </div>


                                <div label='Metadata'>
                                    <ViewMetadata Data={this.state.Narrative} />
                                </div>



                                <div label="used in ...">
                                </div>


                            </PD.Tabs>


                        </div>
                    )
            };
        };

        return <div />;
    };


    FormConcepts(){

        let seqNext = 1;
        if (this.state.FormData.Concepts){
            seqNext = this.state.FormData.Concepts.length + 1;
        }
        return (
            <div>
                <PD.Heading level="2">Concepts</PD.Heading>
                <PD.Table type="list">
                    {this.state.FormData.Concepts &&
                        this.state.FormData.Concepts.map(
                            (Concept, posConcept) => 
                            {
                                let seq = posConcept+1;
                                return(
                                    this.FormConcept('edit', seq, Concept)
                                )
                            }
                        )
                    }
                    {this.FormConcept('new', seqNext)}
                </PD.Table>
            </div>
        );

    }

    FormConcept(mode, seq, Concept = {}){
        //!! does it need to send Concept ?? //
        return(
            <RowConcept key={seq.toString()} mode={mode} parent={this} Concept={Concept} Keys={this.state.FormData.Keys} seq={seq} Narrative={this.state.Narrative}/>
        )
    }



    FormLinks(){

        
        let seqNext = 1;
        if (this.state.FormData.Links){
            seqNext = this.state.FormData.Links.length + 1;
        }
        return (
            <div>
                <PD.Heading level="2">Links</PD.Heading>

{// offer - generate from concept model
}
                <form>
                    <SelectConceptModel label='Generate links from Concept Model' name="idLinkConceptModel" value={this.state.FormData.idLinkConceptModel} onChange={this.handleChange}/>
                    {this.state.FormData.idLinkConceptModel &&
                        <PD.Button type="submit" onClick={this.handleButton} name="btnGenerateLinks"value="Generate" />    
                    }
                </form>

                <PD.Table type="list">
                    {this.state.FormData.Links &&
                        this.state.FormData.Links.map(
                            (Link, posLink) => 
                            {
                                let seq = posLink+1;
                                return(
                                    this.FormLink('edit', seq, Link)
                                )
                            }
                        )
                    }
                    {this.FormLink('new', seqNext)}
                </PD.Table>

            </div>
        );

    }

    FormLink(mode, seq, Link = {}){
        return(
            <RowLink key={seq.toString()} mode={mode} parent={this} Link={Link} seq={seq} Narrative={this.state.Narrative}/>
        )
    }


    FormKeys(){

        let seqNext = 1;
        if (this.state.FormData.Keys){
            seqNext = this.state.FormData.Keys.length + 1;
        }
        return (
            <div>
                <PD.Heading level="2">Keys</PD.Heading>

                <PD.Table type="list">
                    {this.state.FormData.Keys &&
                        this.state.FormData.Keys.map(
                            (Key, posKey) => 
                            {
                                let seq = posKey+1;
                                return(
                                    this.FormKey('edit', seq, Key)
                                )
                            }
                        )
                    }
                    {this.FormKey('new', seqNext)}
                </PD.Table>

            </div>
        );

    }

    FormKey(mode, seq, Key = {}){
        return(
            <RowKey mode={mode} parent={this} Key={Key} seq={seq} Narrative={this.state.Narrative}/>
        )
    }

};



// Wrap and export
export default function(props) {
    
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    return <Narrative {...props} navigation={navigation} location={location} params={params} />;
  }
