import React from "react"
import { isNull, isEqual } from "lodash";

import {hasProperty} from '../utils/utils';


import dsd from '../classes/clsDSD';

import * as PD from '../classes/pdStyle';

class ViewMetadata extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };


        this.dsd = new dsd();
        this.dsd.StateObject = this;

        this.data = {};

    }


    setup(){


        let StateChange = {

            isLoaded: false,

            Data: null

        }

        if (this.props.Data){
            StateChange.Data = this.props.Data;
            StateChange.isLoaded = true;
        }


        this.setState(StateChange);
        
    }

    componentDidMount(){

        this.setup();
 
     }


     componentDidUpdate(prevProps, prevState){

        if (!isEqual(this.props, prevProps)){
            this.setup();
        }

     }

     viewMetadata(Data){
    
        if (!Data){
            return <div/>
        }

        return(
            <PD.Table className="panel">
                <PD.Tbody>          
                    {hasProperty(Data,'uri') &&
                        <PD.Tr>
                            <PD.Th>URI</PD.Th>
                            <PD.Td>
                                {Data.uri &&
                                    <a href={Data.uri} target='_blank' >{Data.uri}</a>                                            
                                }
                            </PD.Td>
                        </PD.Tr>
                    }
                    {hasProperty(Data,'Owner') &&
                        <PD.Tr>
                            <PD.Th>Owner</PD.Th><PD.Td>{Data.Owner.organisation}</PD.Td>
                        </PD.Tr>
                    }
                    {hasProperty(Data,'createdAt') &&
                        <PD.Tr>
                            <PD.Th>Created</PD.Th><PD.Td>{Data.createdAt}</PD.Td>
                        </PD.Tr>
                    }
                    {hasProperty(Data,'createdAt') &&
                        <PD.Tr>
                            <PD.Th>Last Updated</PD.Th><PD.Td>{Data.updatedAt}</PD.Td>
                        </PD.Tr>
                    }

                </PD.Tbody>
            </PD.Table>
        );
    };


    render() {

        if (this.state.isLoaded) {

            return (
                <div>
                    {this.viewMetadata(this.state.Data)}
                </div>
            )
        }
        else {
            return <div />;
        }

    };    
};
        
export default ViewMetadata;